import { Navigate, RouteObject } from 'react-router-dom'
import { TermsGeneral, TermsHealthData } from './components/GenericTerms'
import { VPS_AUTH_CALLBACK_PATH } from './constants'
import { Layout } from './containers'
import {
  Home,
  NewsList,
  NewsEntry,
  LoginVPS,
  ForEntrepreneurs,
  AboutCard,
  Contacts,
  Terms,
  GovSupportList,
  GovSupportEntry,
  CompanyOffersEntry,
  CompanyOffersList,
  UsefulTipsEntry,
  UsefulTipsList,
  InquiryAgreement,
  InquiryContactInfo,
  GenericTerms,
  TermsOfUse,
  Notice,
  NoticeDetails,
  Profile,
  Cards,
  ContactInformation,
  MyCard,
  Inquiries,
  Logout,
  Promotions,
  PromotionEntry,
  CertificateVerification,
  VPSCallback,
} from './pages'
import Activation from './pages/Activation'
import { DiscountCatalog } from './pages/DiscountCatalog'
import { PartnerInfo } from './pages/DiscountCatalog/PartnerInfo'
import { InquiryChildInfo } from './pages/Inquiry/InquiryChildInfo'
import { InquiryChildrenCard } from './pages/Inquiry/InquiryChildrenCard'
import { InquiryChildrenOnCard } from './pages/Inquiry/InquiryChildrenOnCard'
import { InquiryDebtorInfo } from './pages/Inquiry/InquiryDebtorInfo'
import { InquiryPlasticCards } from './pages/Inquiry/InquiryPlasticCards'
import { InquiryProcessingError } from './pages/Inquiry/InquiryProcessingError'
import { InquiryResult } from './pages/Inquiry/InquiryResult'
import { Settings } from './pages/Profile/Settings'

export const routesConfig: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/login/vps',
        element: <LoginVPS />,
      },
      {
        path: '/logout',
        element: <Logout />,
      },
      {
        path: '/news/:id',
        element: <NewsEntry />,
      },
      {
        path: '/news',
        element: <NewsList />,
      },
      {
        path: 'for-entrepreneurs',
        element: <ForEntrepreneurs />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'cards',
        element: <Cards />,
      },
      {
        path: 'my-card',
        element: <MyCard />,
      },
      {
        path: 'contact-information',
        element: <ContactInformation />,
      },
      {
        path: 'settings',
        element: <Settings />,
      },
      {
        path: 'inquiries',
        element: <Inquiries />,
      },
      {
        path: 'about-card',
        element: <AboutCard />,
      },
      {
        path: '/health-data-processing-terms',
        element: <GenericTerms terms={<TermsHealthData />} />,
      },
      {
        path: '/general-data-processing-terms',
        element: <GenericTerms terms={<TermsGeneral />} />,
      },
      {
        path: '/inquiry-agreement',
        element: <InquiryAgreement />,
      },
      {
        path: '/inquiry-contact-info',
        element: <InquiryContactInfo />,
      },
      {
        path: '/inquiry-child-info',
        element: <InquiryChildInfo />,
      },
      {
        path: '/inquiry-debtor-info',
        element: <InquiryDebtorInfo />,
      },
      {
        path: '/inquiry-children-cards/:id',
        element: <InquiryChildrenCard />,
      },
      {
        path: '/inquiry-children-on-card/:id',
        element: <InquiryChildrenOnCard />,
      },
      {
        path: 'inquiry-processing-error',
        element: <InquiryProcessingError />,
      },
      {
        path: '/inquiry-plastic-cards/:id',
        element: <InquiryPlasticCards />,
      },
      {
        path: '/inquiry-result',
        element: <InquiryResult />,
      },
      {
        path: 'contacts',
        element: <Contacts />,
      },
      {
        path: 'terms',
        element: <Terms />,
      },
      {
        path: 'terms-of-use',
        element: <TermsOfUse />,
      },
      {
        path: 'notices',
        element: <Notice />,
      },
      {
        path: 'notices/:id',
        element: <NoticeDetails />,
      },
      {
        path: 'gov-support/:id',
        element: <GovSupportEntry />,
      },
      {
        path: 'gov-support',
        element: <GovSupportList />,
      },
      {
        path: 'company-offers/:id',
        element: <CompanyOffersEntry />,
      },
      {
        path: 'company-offers',
        element: <CompanyOffersList />,
      },
      {
        path: 'useful-tips/:id',
        element: <UsefulTipsEntry />,
      },
      {
        path: 'useful-tips',
        element: <UsefulTipsList />,
      },
      {
        path: '/discounts',
        element: <DiscountCatalog />,
      },
      {
        path: '/discounts/:id',
        element: <PartnerInfo />,
      },
      {
        path: '/aktivizet/:otp',
        element: <Activation />,
      },
      {
        path: '/promotions/:id',
        element: <PromotionEntry />,
      },
      {
        path: '/promotions',
        element: <Promotions />,
      },
      {
        path: '/certificate-verification',
        element: <CertificateVerification />,
      },
      {
        path: VPS_AUTH_CALLBACK_PATH,
        element: <VPSCallback />
      },
      {
        path: '*',
        element: <Navigate to="/" />,
      },
    ],
  },
]
