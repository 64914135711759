import { Button, SxProps } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useUserDataStore } from '../../store'

interface LoginButtonProps {
  inverted?: boolean
  sx?: SxProps
  toInquiry?: boolean
}

export const LoginButton = ({ inverted, sx, toInquiry = false }: LoginButtonProps) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { clearUserDataStorage, currentUser } = useUserDataStore()

  const login = () => {
    clearUserDataStorage()
    navigate(toInquiry ? '/inquiry-agreement' : '/login/vps')
  }

  return (
    <>
      {inverted ? (
        <Button
          onClick={!currentUser ? login : () => navigate('/profile')}
          className="inverted"
          sx={sx}
          variant="outlined"
        >
          {!currentUser ? t('login') : t('my-profile')}
        </Button>
      ) : (
        <Button
          onClick={!currentUser ? login : () => navigate('/profile')}
          variant="contained"
          sx={sx}
        >
          {!currentUser ? t('login') : t('my-profile')}
        </Button>
      )}
    </>
  )
}
