import { InquiryEntry } from '../interfaces/inquiry/InquiryEntry'
import { InquiryRegistryCheck } from '../interfaces/inquiry/InquiryRegistryCheck'

export default function inquiryDocumentEligibilityCheck(
  inquiry: InquiryEntry | null,
  check: InquiryRegistryCheck[] = []
) {
  const isViisDocumentEligible = check.some((test) => test.tips_kods === 'VIIS' && !test.atbilst)
  const isVdeavkDocumentEligible =
    check.some((test) => test.tips_kods === 'VDEĀVK' && !test.atbilst) &&
    inquiry?.piekrīt_veselības_datu_apstrādei

  return isViisDocumentEligible || isVdeavkDocumentEligible
}
