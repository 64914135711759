import React from 'react'
import { createTheme, ThemeProvider as Provider } from '@mui/material'

export interface ThemeProviderProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null
}

export const theme = createTheme({
  typography: {
    fontFamily: 'Rubik, sans-serif',
    h1: {
      fontSize: '2.2rem',
      fontWeight: 'bold',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h3: {
      fontSize: '1rem',
      fontWeight: 'bold',
    },
    subtitle2: {
      color: '#999999',
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
    },
    h5: {
      fontWeight: 'bold',
    },
    h6: {
      fontWeight: 'bold',
    },
  },
  palette: {
    primary: {
      main: '#A30A32',
    },
    secondary: {
      main: '#DB4646',
      light: '#FBECEC',
    },
  },
})

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
  return <Provider theme={theme}>{children}</Provider>
}
