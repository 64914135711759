import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import { useUserDataStore } from '../store'

const UNAUTHORIZED_CODE = 401

export const useInitInterceptors = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { setCurrentUser, clearUserDataStorage } = useUserDataStore()

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response.status === UNAUTHORIZED_CODE) {
        setCurrentUser(undefined)
        clearUserDataStorage()
        navigate('/login/vps', { replace: true, state: { pathFrom: location.pathname } })
      }

      return Promise.reject(error)
    }
  )
}
