export const BASE_PATH_CMS = '/cms'
export const BASE_PATH_PORTAL = '/portal-api'
export const BASE_PATH_DISTRO = '/distro'

export const DEFAULT_DATE_FORMAT = 'dd.MM.yyyy'

export const LANGUAGES = ['lv', 'en']

export const ENTRIES_PER_PAGE = 15

export const MIN_DISPLAYABLE_CHILDREN_COUNT = 1
export const MAX_DISPLAYABLE_CHILDREN_COUNT = 8
export const PHONE_MIN_LENGTH = 8
export const PHONE_MAX_LENGTH = 14
export const PHONE_PLACEHOLDER = '37100000000'

export const FILE_SIZE_LIMIT_MB_MESSAGE =
  'Faila izmērs nedrīkst pārsniegt 10Mb, attēlam jābūt "portrait" vai "landscape" novietojumā.'

export const REGEX_PERSON_CODE = /^([0-3]\d{5})-\d{5}$/

export const REGEX_HOME_PAGE_FORMAT =
  /^(http(s)?:\/\/)[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=.]+$/

export const DISPLAYABLE_CHILDREN_COUNT = 8

export const REQUIRED_ERROR_MESSAGE = 'Šis lauks ir obligāts'
export const INVALID_PHONE_FORMAT = `Tālrunim jābūt vismaz ${PHONE_MIN_LENGTH} ciparus garam`
export const INVALID_EMAIL_FORMAT = 'Nekorekts e-pasta formāts'
export const INVALID_MAX_DISPLAYABLE_CHILDREN_COUNT = `Uz kartes nedrīkst norādīt vairāk par ${MAX_DISPLAYABLE_CHILDREN_COUNT} bērniem`
export const INVALID_MIN_DISPLAYABLE_CHILDREN_COUNT = `Lūdzu, norādīt vismaz ${MIN_DISPLAYABLE_CHILDREN_COUNT} bērnu, kura datus attēlot uz vecāka apliecības`
export const REQUIRED_CHILDREN_ERROR_MESSAGE =
  'Lūdzu, norādīt vismaz vienu bērnu, kura datus attēlot uz vecāka apliecības'
export const REQUIRED_FILE_ERROR_MESSAGE = 'Nepieciešams pievienot vismaz vienu failu'
export const INVALID_PERSONAL_CODE_SECOND_PART =
  'Personas koda otrajai daļai jāsastāv no 5 cipariem'
export const INVALID_DISPLAYABLE_CHILDREN_COUNT = `Uz kartes nedrīkst norādīt vairāk par ${DISPLAYABLE_CHILDREN_COUNT} bērniem`

export const EMAIL_LENGTH = 254

export const REGEX_EMAIL_FORMAT =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export const DEFAULT_API_DATE_FORMAT = 'yyyy-MM-dd'

export const DEFAULT_DOCUMENT_STATUS = 'JAUNS'

export const VIIS_TYPE = 'VIIS'
export const VDEĀVK_TYPE = 'VDEĀVK'
export const UGFA_TYPE = 'UGFA'
export const UGFA_DOCUMENT_TYPE = 'UGFA_DOK'
export const VIIS_DOCUMENT_TYPE = 'VIIS_DOK'
export const VDEĀVK_DOCUMENT_TYPE = 'VDEĀVK_DOK'

export const ACCEPTED_FILE_TYPES: { [key: string]: string | string[] } = {
  pdf: 'application/pdf',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  png: 'image/png',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  tiff: 'image/tiff',
  edoc: ['', 'application/vnd.etsi.asic-e+zip'],
}

export const privateRoutes = [
  {
    title: 'menu.profile',
    link: '/profile',
    needAuth: true,
  },
  {
    title: 'menu.notices',
    link: '/notices',
    needAuth: true,
  },
  {
    title: 'menu.terms-of-use',
    link: '/terms-of-use',
    needAuth: false,
  },
]

export const routes = [
  {
    title: 'menu.home',
    link: '/',
  },
  {
    title: 'menu.news',
    link: '/news',
  },
  {
    title: 'menu.about',
    link: '/about-card',
  },
  {
    title: 'menu.discounts',
    link: '/discounts',
  },
  {
    title: 'menu.promo',
    link: '/promotions',
  },
  {
    title: 'menu.for-entrepreneurs',
    link: '/for-entrepreneurs',
  },
  {
    title: 'menu.contacts',
    link: '/contacts',
  },
]

export const breakPoints = {
  mobile: 480,
  tablet: 870,
  laptop: 1200,
  desktop: 1536,
}

export const LONGTITUDE_TUPLE_NUMBER = 0
export const LATITUDE_TUPLE_NUMBER = 1

export const VPS_AUTH_CALLBACK_PATH = '/auth-callback'
