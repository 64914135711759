import React from 'react'
import {
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { breakPoints, routes } from '../../constants'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useUserDataStore } from '../../store'

export const RouteBar = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))
  const { t } = useTranslation()
  const { currentUser } = useUserDataStore()

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      {isTablet ? (
        <Box
          sx={{
            ml: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            textAlign: 'center',
          }}
        >
          <IconButton onClick={handleClick} color="primary" aria-label="menu" component="span">
            <MenuIcon style={{ color: '#FFF' }} fontSize="large" />
            <Typography style={{ color: '#FFF', marginLeft: '8px' }}>{t('menu.title')}</Typography>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={Boolean(anchorEl)}
            onClose={handleClose}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            sx={{
              '.MuiMenu-list': {
                minWidth: '200px',
              },
            }}
          >
            <Box display="flex" alignItems="center" justifyContent="center" marginTop="8px">
              <Button
                variant="outlined"
                onClick={() => {
                  navigate(!currentUser ? '/inquiry-agreement' : '/profile')
                  handleClose()
                }}
              >
                {!currentUser ? t('login') : t('my-profile')}
              </Button>
            </Box>
            <Divider
              sx={{ borderColor: 'primary.main', borderWidth: '1px', margin: '14px 0 6px' }}
            />
            {routes.map((route, index) => (
              <Box
                key={index}
                display="flex"
                alignItems="center"
                justifyContent="center"
                margin="16px 0"
              >
                <Button
                  sx={{
                    textTransform: 'unset',
                  }}
                  onClick={() => {
                    navigate(route.link)
                    handleClose()
                  }}
                >
                  <Typography variant="body1" textAlign="center" sx={{ color: 'primary.main' }}>
                    {t(route.title)}
                  </Typography>
                </Button>
              </Box>
            ))}
          </Menu>
        </Box>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Button
            sx={{ marginLeft: '20px' }}
            variant="outlined"
            className="inverted"
            onClick={() => navigate(!currentUser ? '/inquiry-agreement' : '/profile')}
          >
            {!currentUser ? t('login') : t('my-profile')}
          </Button>
          <Box display="flex" alignItems="center">
            {routes.map((route, index) => (
              <Box key={index} ml={2} mr={2}>
                <Link
                  to={route.link}
                  style={{ fontWeight: 500, maxWidth: '105px', minWidth: 'unset' }}
                  className="Header-Footer-Nav-Link"
                >
                  {t(route.title)}
                </Link>
              </Box>
            ))}
          </Box>
        </Box>
      )}
    </>
  )
}
