import { InquiryStatusCode } from '../../enums/InquiryStatusCode'

export const getStatusDescription = (statusName?: InquiryStatusCode) => {
  switch (statusName) {
    case InquiryStatusCode.Apstiprināts: {
      return 'Goda ģimenes apliecības ir piešķirtas.'
    }
    case InquiryStatusCode.Pārvērtēšanā: {
      return 'Lai iegūtu Goda ģimenes apliecību nepieciešams iesniegt papildus informāciju.'
    }
    case InquiryStatusCode.Izskatīšanā: {
      return 'Jums jau ir reģistrēts iesniegums un ir saņemta papildus informācija un notiek tās izvērtēšana.'
    }
    case InquiryStatusCode.Noraidīts: {
      return 'Atteikt Goda ģimenes apliecības/u piešķiršanu. Ja nepieciešama papildus informācija griezieties Sabiedrības integrācijas fondā.'
    }
    case InquiryStatusCode.Slēgts: {
      return 'Jums jau ir reģistrēts iesniegums, bet tas vairs nav aktīvs.'
    }
    case InquiryStatusCode.Nepieejams: {
      return 'Tehnisku iemeslu dēļ nebija iespējams pārliecināties vai persona nav Uzturlīdzekļu garantijas fonda iesniedzēju un parādnieku reģistrā kā parādnieks.'
    }
    case InquiryStatusCode.Gaida_informāciju:
    case InquiryStatusCode.Jauns:
    default: {
      return 'Goda ģimenes apliecība nav piešķirta. Jāiesniedz papildus informācija. Informācija par datiem, kuri jāiesniedz, nosūtīta uz Jūsu e-pastu.'
    }
  }
}

export const getStatusIconColor = (statusName?: InquiryStatusCode) => {
  switch (statusName) {
    case InquiryStatusCode.Apstiprināts:
    case InquiryStatusCode.Pārvērtēšanā: {
      return 'success'
    }
    case InquiryStatusCode.Gaida_informāciju:
    case InquiryStatusCode.Izskatīšanā:
    case InquiryStatusCode.Jauns: {
      return 'warning'
    }
    case InquiryStatusCode.Noraidīts:
    case InquiryStatusCode.Nepieejams:
    case InquiryStatusCode.Slēgts: {
      return 'primary'
    }
  }
}
