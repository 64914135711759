import { Box, Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

export const InquiryProcessingError = () => {
  const navigate = useNavigate()

  return (
    <Box className="body-container">
      <Box sx={{ textAlign: 'center', minHeight: 'calc(100vh - 250px - 70px)' }}>
        <h3>
          Jūsu apliecības "Goda ģimene" iesnieguma pārbaudes procesā pagaidām nav izdevies pārbaudīt
          visus iesniegumā norādītos datus
        </h3>

        <h3>
          Informāciju par iesnieguma izskatīšanas rezultātu saņemsiet uz norādīto kontaktinformāciju
        </h3>
      </Box>
      <Box sx={{ textAlign: 'center' }}>
        <Button variant="outlined" onClick={() => navigate('/contacts')}>
          Norādīt kontaktinformāciju
        </Button>

        <Button
          variant="text"
          sx={{
            textDecoration: 'underline',
            fontSize: '0.7rem',
            marginLeft: 10,
          }}
          onClick={() => navigate('/profile')}
        >
          Lietotāja profils
        </Button>
      </Box>
    </Box>
  )
}
