import { Divider, Typography } from '@mui/material'
import React from 'react'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction'
import IconButton from '@mui/material/IconButton'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { SubMenu } from '../../components'
import { useTranslation } from 'react-i18next'

export const Profile = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const text = {
    fontWeight: 600,
  }

  const menuItems = [
    {
      text: t('profile.applications'),
      icon: <CreditCardIcon />,
      path: '/cards',
    },
    {
      text: t('profile.contact-information'),
      icon: <PhoneOutlinedIcon />,
      path: '/contact-information',
    },
    {
      text: t('profile.application'),
      icon: <InsertDriveFileOutlinedIcon />,
      path: '/inquiries',
    },
    {
      text: t('profile.settings'),
      icon: <SettingsIcon />,
      path: '/settings',
    },
  ]

  function generateList() {
    return (
      <List>
        {menuItems.map((item, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton onClick={() => navigate(item.path)}>
              <ListItemIcon sx={{ color: theme.palette.primary.main }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} primaryTypographyProps={{ style: text }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" disabled>
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <div className="body-container">
      <SubMenu />
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('my-profile')}
      </Typography>
      <Divider />
      {generateList()}
    </div>
  )
}
