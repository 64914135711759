import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

interface RaitingRequest {
  sadarbības_partneris_id: number
  vērtējums: number
}

export const setPartnerRaiting = async (data: RaitingRequest) => {
  const response = await axios.post(`${BASE_PATH_PORTAL}/data/sadarbības_partnera_vērtējums`, data)
  return response.data
}
