import { BASE_PATH_CMS } from '../../constants'
import { NewsItem } from '../../interfaces'
import { FullPageLoader } from '../FullPageLoader'
import parse from 'html-react-parser'
import { Typography, Box } from '@mui/material'
import { getLocalizedDate, htmlParseOptions } from '../../utils'
import { useDocumentTitle } from '../../hooks'
import { SocialShare } from '../SocialShare'

interface GenericItemEntryProps {
  isLoading: boolean
  data?: NewsItem
}

export const GenericItemEntry = ({ isLoading, data }: GenericItemEntryProps) => {
  useDocumentTitle(data?.title)

  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <>
          {data && (
            <>
              <Typography variant="h1">{data.title}</Typography>
              <Typography gutterBottom variant="subtitle2">
                {getLocalizedDate(data.last_published_at)}
              </Typography>
              <Box
                component="img"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '320px',
                  mt: 2,
                  boxShadow: 'inset 10px 10px 50px #eee',
                }}
                src={BASE_PATH_CMS + data.field_titulbilde}
              />
              <Box sx={{ mt: 2 }}>{parse(data.body, htmlParseOptions)}</Box>
              <SocialShare />
            </>
          )}
        </>
      )}
    </>
  )
}
