import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

interface Data {
  id: number
  bērni: {
    persona_id: number
  }[]
}

export const updateChildrenOnCard = async ({ id, bērni }: Data) => {
  const response = await axios.put(`${BASE_PATH_PORTAL}/data/labot_apliecības_bērnus/${id}`, {
    id,
    bērni,
  })
  return response.data
}
