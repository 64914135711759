import { useEffect } from 'react'
import { Typography } from '@mui/material'
import { DelayedLoader } from '../../components'
import useActivation from './useActivation'

export default function Activation() {
  const { activate, isLoading, error, response } = useActivation()

  useEffect(() => {
    activate()
  }, [activate])

  if (isLoading) return <DelayedLoader />

  if (error)
    return (
      <Typography color="error">
        Aktivizējot jūsu e-pastu, radās problēma. E-pasts jau var būt aktivizēts.
      </Typography>
    )

  if (response?.ir_verificēts)
    return <>Jūsu e-pasts ir verificēts un Jūs turpmāk varēsiet uz šo e-pasta adresi saņemt ziņas</>

  return <>Notika kaut kas negaidīts</>
}
