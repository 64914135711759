import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import FormControlLabel from '@mui/material/FormControlLabel'
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormHelperText,
  Typography,
  useTheme,
} from '@mui/material'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { useNavigate } from 'react-router-dom'
import {
  INVALID_PHONE_FORMAT,
  PHONE_MAX_LENGTH,
  PHONE_MIN_LENGTH,
  INVALID_EMAIL_FORMAT,
  REGEX_EMAIL_FORMAT,
  PHONE_PLACEHOLDER,
} from '../../constants/constants'
import { useMutation, useQuery } from 'react-query'
import { getUser } from '../../api/user/getUser'
import { usePhoneVerificationDialogState, useUserDataStore } from '../../store'
import { FullPageLoader, SubMenu } from '../../components'
import { User } from '../../interfaces'
import { updateUser } from '../../api/user/updateUser'
import {
  sendActivationCodeToMyEmail,
  sendActivationCodeToMyPhone,
} from '../../api/activation/activation'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { useTranslation } from 'react-i18next'

const sx = {
  infoBar: {
    padding: '30px',
    backgroundColor: '#D2DDCB',
    color: '#6A9F45',
    borderRadius: 5,
  },
  editIcon: {
    cursor: 'pointer',
  },
}

interface ContactData {
  phone?: string
  email?: string
  eaddress?: boolean
  isEmailVerified?: boolean
  isPhoneVerified?: boolean
  isEaddressVerified?: boolean
}

export const ContactInformation = () => {
  const { showSnackBar } = useSnackBar()
  const theme = useTheme()
  const navigate = useNavigate()
  const { currentUser } = useUserDataStore()
  const {
    setIsOpen: setIsPhoneVerificationDialogOpen,
    setPhoneId,
    setUserRefetchCallback,
  } = usePhoneVerificationDialogState()

  const [initialValues, setInitialValues] = React.useState<ContactData>({
    phone: '',
    email: '',
    eaddress: false,
    isEmailVerified: false,
    isPhoneVerified: false,
    isEaddressVerified: false,
  })

  const [user, setUser] = React.useState<User | null>(null)
  const [phone, setPhone] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [isEmailVerified, setIsEmailVerified] = React.useState(false)
  const [isPhoneVerified, setIsPhoneVerified] = React.useState(false)
  const [eaddress, setEaddress] = React.useState(false)
  const [isEaddressVerified, setIsEaddressVerified] = React.useState(false)
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>({
    phone: '',
    email: '',
  })
  const { t } = useTranslation()

  const { isLoading: isLoadingUser, refetch: refetchUserData } = useQuery(
    'get-user',
    () => getUser({ id: currentUser?.id }),
    {
      onSuccess: (response) => {
        setUser(response)
        const { telefoni, epasti, eadrese } = response
        const userEaddress = eadrese === 'izmantot'
        const isUserEaddressVerified = eadrese === 'verificēts'

        if (telefoni.length > 0 && epasti.length > 0) {
          const userPhone = telefoni[0].telefons
          const userEmail = epasti[0].epasts
          const isUserPhoneVerified = telefoni[0].ir_verificēts
          const isUserEmailVerified = epasti[0].ir_verificēts

          setInitialValues({
            phone: userPhone,
            email: userEmail,
            eaddress: userEaddress,
            isPhoneVerified: isUserPhoneVerified,
            isEmailVerified: isUserEmailVerified,
            isEaddressVerified: isUserEaddressVerified,
          })

          setPhone(userPhone)
          setEmail(userEmail)
          setEaddress(userEaddress)
          setIsPhoneVerified(isUserPhoneVerified)
          setIsEmailVerified(isUserEmailVerified)
          setIsEaddressVerified(isUserEaddressVerified)
        } else {
          setInitialValues({
            eaddress: userEaddress,
            isEaddressVerified: isUserEaddressVerified,
          })

          setEaddress(userEaddress)
          setIsEaddressVerified(isUserEaddressVerified)

          setIsEditMode(true)
        }
      },
    }
  )

  const updateUserMutation = useMutation((data: any) => updateUser(data), {
    onSuccess: async (userResponse: User) => {
      showSnackBar()
      setIsEditMode(false)

      if (initialValues.email !== email) {
        handleSendActivationCodeToEmail(userResponse)
      }

      if (initialValues.phone !== phone) {
        handleSendActivationCodeToPhone(userResponse)
      }
    },
    onError: (error) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const sendActivationCodeToMyEmailMutation = useMutation(
    (emailId: number) => sendActivationCodeToMyEmail(emailId),
    {
      onSuccess: (response) => {
        if (response) {
          refetchUserData()
          showSnackBar({
            text: 'Jums uz e-pastu ir nosūtīts verifikācijas kods',
          })
        }
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const sendActivationCodeToMyPhoneMutation = useMutation(
    (phoneId: number) => sendActivationCodeToMyPhone(phoneId),
    {
      onSuccess: (response) => {
        if (response) {
          showSnackBar({
            text: 'Uz jūsu tālruni ir nosūtīts verifikācijas kods',
          })
          setIsPhoneVerificationDialogOpen(true)
          setUserRefetchCallback(refetchUserData)
        }
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const isFormValid = () => {
    const _errors: { [key: string]: string } = {}

    if (email && !email.match(REGEX_EMAIL_FORMAT)) _errors['email'] = INVALID_EMAIL_FORMAT

    if (phone && phone.length < PHONE_MIN_LENGTH) _errors['phone'] = INVALID_PHONE_FORMAT

    if (phone && phone.length > PHONE_MAX_LENGTH) {
      _errors['phone'] = `Tālruņa numurs pārsniedz maksimālo garumu - ${PHONE_MAX_LENGTH}`
    }

    if (!phone && !email && !eaddress) {
      _errors['contactInformation'] = 'Lūdzu, norādiet vismaz vienu no komunikācijas kanāliem'
    }

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEaddress(event.target.checked)
  }

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value.replace(/[^0-9]/gi, ''))
    setFormErrors({
      ...formErrors,
      phone: '',
    })
  }

  const handleSendActivationCodeToEmail = async (user: User) => {
    const unverifiedEmail = user?.epasti?.find((email) => email.epasts && !email.ir_verificēts)
    if (unverifiedEmail) {
      sendActivationCodeToMyEmailMutation.mutate(unverifiedEmail.id)
    }
  }

  const handleSendActivationCodeToPhone = async (user: User) => {
    const unverifiedPhone = user.telefoni?.find((tel) => tel.telefons && !tel.ir_verificēts)
    if (unverifiedPhone) {
      setPhoneId(unverifiedPhone.id)
      sendActivationCodeToMyPhoneMutation.mutate(unverifiedPhone.id)
    }
  }

  const onSubmit = async () => {
    const eaddressData = eaddress ? 'izmantot' : 'neizmantot'

    // Note: after updating person info: phone or email, they both get unverified (ir_verificēts: false)
    const phoneData = [
      {
        telefons: phone,
        piezīmes: '',
      },
    ]

    const emailData = [
      {
        epasts: email,
        piezīmes: '',
      },
    ]

    if (!isFormValid()) return

    if (user) {
      updateUserMutation.mutate({
        user: user,
        phone: phoneData,
        email: emailData,
        eaddress: eaddressData,
      })
    } else {
      navigate('/login/vps')
    }
  }

  return isLoadingUser ? (
    <FullPageLoader />
  ) : (
    <Box>
      <SubMenu />
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('profile.contact-information')}
      </Typography>
      <Divider />
      <Box>
        <h2>
          {user?.vārds} {user?.uzvārds}
        </h2>
        <Box sx={sx.infoBar}>{t('contact-info.use-of-contact-info')}</Box>

        <Box style={{ padding: '20px 0px 5px', fontWeight: 600 }}>{t('phone-number')}</Box>

        {isEditMode ? (
          <TextField
            type="tel"
            fullWidth
            value={phone}
            placeholder={PHONE_PLACEHOLDER}
            onChange={handlePhoneChange}
            sx={{
              marginLeft: '-1px',
              backgroundColor: '#f7f7f7',
            }}
            InputProps={{
              startAdornment: <InputAdornment position="start">+</InputAdornment>,
            }}
            helperText={formErrors.phone}
            error={Boolean(formErrors.phone)}
          />
        ) : (
          <Box
            style={{
              backgroundColor: isPhoneVerified ? 'rgb(243, 247, 240)' : 'rgba(246, 171, 10, 0.1)',
              borderLeft: isPhoneVerified ? '8px solid #689e46' : '8px solid #dab15f',
              borderRadius: 5,
              padding: 15,
              color: '#6c4006',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontWeight: 600,
              }}
            >
              <span>+{phone}</span>
              <span style={{ display: 'flex' }}>
                {!isPhoneVerified ? (
                  <Button
                    variant="outlined"
                    disabled={sendActivationCodeToMyPhoneMutation.isLoading}
                    onClick={() => handleSendActivationCodeToPhone(user!)}
                  >
                    {t('contact-info.verify')}
                  </Button>
                ) : (
                  <></>
                )}
                <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
                <EditOutlinedIcon sx={sx.editIcon} onClick={() => setIsEditMode(true)} />
              </span>
            </Box>
            <Divider sx={{ margin: '10px 0px' }} />
            <Box>
              {t(isPhoneVerified ? 'contact-info.active-phone' : 'contact-info.phone-info')}
            </Box>
          </Box>
        )}

        <Box style={{ padding: '20px 0px 5px', fontWeight: 600 }}>{t('e-mail')}</Box>

        {isEditMode ? (
          <TextField
            type="email"
            fullWidth
            placeholder="e-pasts@e-pasts.lv"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            sx={{
              backgroundColor: '#f7f7f7',
            }}
            helperText={formErrors.email}
            error={Boolean(formErrors.email)}
          />
        ) : (
          <Box
            style={{
              backgroundColor: isEmailVerified ? 'rgb(243, 247, 240)' : 'rgba(246, 171, 10, 0.1)',
              borderLeft: isEmailVerified ? '8px solid #689e46' : '8px solid #dab15f',
              borderRadius: 5,
              padding: 15,
              color: '#6c4006',
            }}
          >
            <Box
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                fontWeight: 600,
              }}
            >
              <span>{email}</span>
              <span style={{ display: 'flex' }}>
                <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
                <EditOutlinedIcon sx={sx.editIcon} onClick={() => setIsEditMode(true)} />
              </span>
            </Box>
            <Divider sx={{ margin: '10px 0px' }} />
            <Box>
              {t(isEmailVerified ? 'contact-info.active-e-mail' : 'contact-info.e-mail-info')}
            </Box>
          </Box>
        )}

        <Box style={{ padding: '20px 0px 5px', fontWeight: 600 }}>{t('e-address')}</Box>

        {isEditMode ? (
          <FormControlLabel
            control={<Checkbox checked={eaddress} onChange={handleChange} />}
            label={t('contact-info.use-of-e-address')}
          />
        ) : (
          <Box
            style={{
              backgroundColor: isEaddressVerified
                ? 'rgb(243, 247, 240)'
                : 'rgba(246, 171, 10, 0.1)',
              borderRadius: 5,
              borderLeft: isEaddressVerified ? '8px solid #689e46' : '8px solid #dab15f',
              padding: 15,
              color: '#6c4006',
            }}
          >
            <Box sx={{ fontWeight: 600, display: 'flex', justifyContent: 'space-between' }}>
              {isEaddressVerified
                ? t('contact-info.active-e-address')
                : t('contact-info.inactive-e-address')}
              <Box sx={{ display: 'flex' }}>
                <Divider orientation="vertical" flexItem sx={{ marginRight: 1 }} />
                <EditOutlinedIcon sx={sx.editIcon} onClick={() => setIsEditMode(true)} />
              </Box>
            </Box>
          </Box>
        )}

        {formErrors.contactInformation && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {formErrors.contactInformation}
          </FormHelperText>
        )}

        {isEditMode && (
          <Box mt={2}>
            <Button variant="outlined" onClick={onSubmit} disabled={updateUserMutation.isLoading}>
              {updateUserMutation.isLoading ? (
                <CircularProgress color="inherit" size="1.5rem" />
              ) : (
                t('save')
              )}
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                setIsEditMode(false)
                refetchUserData()
              }}
              sx={{ marginLeft: 2 }}
              disabled={updateUserMutation.isLoading}
            >
              {t('cancel')}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}
