import React from 'react'
import { getNoticeList } from '../../api/notice/getNoticeListList'
import { Notice } from '../../interfaces/notice'
import { useNotificationStore, useUserDataStore } from '../../store'

export const NotificationsPoll = () => {
  const intervalTime = 30000

  const { currentUser } = useUserDataStore()

  const { notifications: existingNotifications, setNotifications } = useNotificationStore()

  const [newNotifications, setNewNotifications] = React.useState<Notice[] | null>([])

  const getNotifications = () => {
    if (newNotifications) {
      const result = newNotifications.map((notification) => {
        if (
          existingNotifications &&
          existingNotifications.some((e) => e.id === notification.id && e.read)
        ) {
          return {
            ...notification,
            read: true,
          }
        } else {
          return notification
        }
      })

      setNotifications(
        result.sort((a: Notice, b: Notice) => {
          const dateA = new Date(a.laiks).getTime()
          const dateB = new Date(b.laiks).getTime()
          return a.read === b.read ? (dateB > dateA ? 1 : -1) : b.read ? -1 : 1
        })
      )
    }
  }

  const fetchNotices = () => {
    if (currentUser) {
      getNoticeList().then(async (fetchedNotifications: Notice[]) => {
        setNewNotifications(fetchedNotifications)
      })
    }
  }

  React.useEffect(() => {
    // Fetch notifications on first login
    fetchNotices()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  React.useEffect(() => {
    // Fetch notifications periodically
    const interval = setInterval(() => {
      fetchNotices()
    }, intervalTime)

    return () => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  React.useEffect(() => {
    getNotifications()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newNotifications])

  return <></>
}
