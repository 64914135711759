import { SVGProps } from 'react'

export const DraugiemIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 36 56.8"
    style={{
      // @ts-ignore
      enableBackground: 'new 0 0 36 56.8',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M29 16.2c.4 0 .7-.1 1.1-.1 2.4-.6 3.5-3.1 3.6-6.1.1-3.2-1-5.8-3.6-6.2h-.7c-1.9 0-3.6 1.2-4.2 3.1-.4 1-.6 2.1-.6 3.2-.1 1.3.1 2.5.6 3.7.6 1.5 2.1 2.5 3.8 2.4zm-.1 1c-.9 0-1.8.1-2.7.3l.2.3.4.7.3.8.3.8.2.8.1.8.1.8V25l-.1.8-.1.8-.1.8-.2.8-.2.8-.1 1-.2.8-.2.8-.2.8-.2.7-.2.7v.7l-.2.7-.1.4-.1.4-.1.5-.1.5-.1.5-.1.5v.1l-.1.6-.1.5-.1.6-.1.7-.1.6-.1.7-.1.7-.1.7-.1.7-.1.7-.1.7c.2 1.9.3 3.6.4 4.5 0 .8.2 1.6.6 2.4.6.8 1.5 1.2 2.5 1.2.8 0 1.7-.3 2.3-.8.6-.8 1-1.7 1.1-2.7.4-2.8 1.7-11.9 2.8-15.8 1.5-5.6 5.2-15.9-3.8-16.6-.3-.3-.7-.3-1.1-.3zm-11-2.9c3.5 0 5.1-3.2 5.1-7.2S21.5 0 18 0c-3.6 0-5.2 3.2-5.2 7.2s1.6 7.2 5.1 7.1zm-7.2 16.6c.3 1.4.6 2.6.9 3.8 1.1 4.5 2.2 14.9 2.6 18.1s1.5 3.8 3.8 4.1c2.2-.2 3.4-.9 3.7-4.1s1.5-13.6 2.6-18.1c.3-1.1.6-2.4.9-3.8 1-4.1 1.8-9 0-12.1-1.2-2-3.4-3.3-7.2-3.3s-6.1 1.3-7.3 3.3c-1.8 3.2-1 8 0 12.1zM5.9 16c.4.1.7.1 1.1.1 1.7 0 3.2-1 3.8-2.5.4-1.2.6-2.5.6-3.7 0-1.1-.2-2.1-.6-3.2-.6-1.8-2.2-3.1-4.2-3.1h-.7c-2.6.4-3.7 3-3.6 6.2 0 3.1 1.2 5.6 3.6 6.2zM4.8 49.7c0 1 .4 1.9 1.1 2.7.7.5 1.5.8 2.3.8 1 0 1.9-.4 2.5-1.2.4-.7.6-1.5.6-2.4.1-.9.2-2.6.4-4.5V45l-.1-.7-.1-.7-.1-.7-.1-.7-.1-.7-.1-.7-.1-.6v-.3l-.1-.7-.1-.6-.1-.6v-.1l-.1-.5-.1-.5-.1-.5-.1-.5-.1-.5-.1-.4-.1-.6-.2-.7-.2-.7-.2-.7-.2-.8-.2-.8-.1-.7-.2-.8-.2-.8-.1-.8-.1-.8-.1-.8-.1-1v-2.5l.1-.8.1-.8.2-.8.2-.8.3-.8.4-.7.2-.3c-.9-.2-1.8-.3-2.7-.3H5.7c-9 .8-5.3 11-3.8 16.6 1.2 4 2.5 13.1 2.9 15.9z"
      style={{
        fillRule: 'evenodd',
        clipRule: 'evenodd',
        fill: '#a20c33',
      }}
    />
  </svg>
)
