export enum InquiryStatusCode {
  Apstiprināts = 'APSTIPRINĀTS',
  Gaida_informāciju = 'GAIDA_INFORMĀCIJU',
  Izskatīšanā = 'IZSKATĪŠANĀ',
  Jauns = 'JAUNS',
  Noraidīts = 'NORAIDĪTS',
  Pārvērtēšanā = 'PĀRVĒRTĒŠANĀ',
  Slēgts = 'SLĒGTS',
  Nepieejams = 'NEPIEEJAMS',
}
