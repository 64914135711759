import { GenericPageItem } from '../../interfaces'
import { FullPageLoader } from '../FullPageLoader'
import parse from 'html-react-parser'
import { useDocumentTitle } from '../../hooks'
import { htmlParseOptions } from '../../utils'

interface GenericPageProps {
  isLoading: boolean
  page?: GenericPageItem
}

export const GenericPage = ({ isLoading, page }: GenericPageProps) => {
  useDocumentTitle(page?.info)

  return (
    <>{isLoading ? <FullPageLoader /> : <>{page ? parse(page.body, htmlParseOptions) : <></>}</>}</>
  )
}
