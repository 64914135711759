import React from 'react'
import { Box, Button, CircularProgress } from '@mui/material'
import { useNavigate, useLocation } from 'react-router-dom'
import { updateHealthDataAgreement } from '../../api/inquiry/updateHealthDataAgreement'
import { useTranslation } from 'react-i18next'

interface DataProcessingTerms {
  showButtons: boolean
  showBackButton: boolean
  inquiryId?: number
}

export const GenericTerms = ({ terms }: { terms: JSX.Element | JSX.Element[] }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const state = location.state as DataProcessingTerms
  const [isLoading, setIsLoading] = React.useState(false)
  const { t } = useTranslation()

  const handleTerms = (value: string) => {
    setIsLoading(true)
    if (location.pathname.includes('health-data-processing-terms')) {
      localStorage.setItem('healthDataProcessingTerms', value)
    }
    if (location.pathname.includes('general-data-processing-terms')) {
      localStorage.setItem('generalDataProcessingTerms', value)
    }

    if (state.inquiryId) {
      updateHealthDataAgreement({
        id: state.inquiryId,
        piekrīt_veselības_datu_apstrādei: value === 'true',
      })
        .then(() => {
          setIsLoading(false)
          navigate(-1)
        })
        .catch(() => {
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
      navigate(-1)
    }
  }

  return (
    <Box>
      <Box>{terms}</Box>
      {state?.showBackButton && (
        <Button
          variant="outlined"
          onClick={() => navigate('/terms-of-use')}
          sx={{ marginTop: '20px' }}
        >
          {t('back')}
        </Button>
      )}
      {state?.showButtons && (
        <Box
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingTop: '40px',
          }}
        >
          <Button variant="outlined" onClick={() => handleTerms('false')} disabled={isLoading}>
            {isLoading ? <CircularProgress color="inherit" /> : t('disagree')}
          </Button>

          <Button variant="outlined" onClick={() => handleTerms('true')} disabled={isLoading}>
            {isLoading ? <CircularProgress color="inherit" /> : t('agree')}
          </Button>
        </Box>
      )}
    </Box>
  )
}
