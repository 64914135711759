import { Grid, useTheme } from '@mui/material'
import { DiscountImageSlider } from './DiscountImageSlider'
import { DiscountCatalogue } from './DiscountCatalogue'
import { breakPoints } from '../../../constants'

export const Discounts = () => {
  const theme = useTheme()

  return (
    <Grid container sx={{ mt: 4 }}>
      <Grid item xs={12} md={7} display="flex" justifyContent="center" alignItems="center">
        <DiscountCatalogue />
      </Grid>

      <Grid
        sx={{ [theme.breakpoints.down(breakPoints.tablet)]: { marginTop: '20px' } }}
        item
        flexGrow={1}
        md={5}
        xs={12}
      >
        <DiscountImageSlider />
      </Grid>
    </Grid>
  )
}
