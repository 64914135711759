import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'
import { CurrentUser, UserEmail, UserPhone } from '../../interfaces/user'

interface Data {
  user: CurrentUser
  phone: Array<UserPhone>
  email: Array<UserEmail>
  eaddress: string
  piekrīt_aptaujām: boolean
  piekrīt_mārketingam: boolean
}

export const updateUser = async ({
  user,
  phone,
  email,
  eaddress,
  piekrīt_aptaujām,
  piekrīt_mārketingam,
}: Data) => {
  const response = await axios.put(`${BASE_PATH_PORTAL}/data/persona/${user.id}`, {
    id: user.id,
    telefoni: phone,
    epasti: email,
    eadrese: eaddress,
    piekrīt_aptaujām,
    piekrīt_mārketingam,
  })
  return response.data
}
