import React from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { InquiryResponse } from '../../interfaces/inquiry/InquiryResponse'
import { getInquiryList } from '../../api/inquiry/getInquiryList'
import { useQuery } from 'react-query'
import { FullPageLoader, SubMenu } from '../../components'
import { format, parseISO } from 'date-fns'
import { breakPoints, DEFAULT_DATE_FORMAT, UGFA_TYPE } from '../../constants'
import { getStatusDescription, getStatusIconColor } from './utils'
import { useTranslation } from 'react-i18next'
import { Children } from '../../interfaces'
import CheckIcon from '@mui/icons-material/Check'
import inquiryDocumentEligibilityCheck from '../../utils/inquiryDocumentEligibilityCheck'
import NewReleasesIcon from '@mui/icons-material/NewReleases'
import { InquiryStatusCode } from '../../enums/InquiryStatusCode'
import useDocumentProcessing from '../../hooks/useDocumentProcessing'

const sx = {
  inquiryRow: {
    padding: '10px 0px',
    display: 'flex',
  },
  yellowContainer: {
    flex: 1,
    backgroundColor: 'rgba(246, 171, 10, 0.1)',
    borderRadius: '5px',
    borderLeft: '8px solid #DAB15F',
    padding: '30px',
    color: '#6C4006',
  },
  greenContainer: {
    flex: 1,
    borderRadius: '5px',
    backgroundColor: '#D2DDCB',
    color: 'white',
  },
  grayContainer: {
    flex: 1,
    borderRadius: '5px',
    backgroundColor: '#F5F5F5',
    color: 'white',
  },
  greenHeader: {
    fontWeight: 600,
    backgroundColor: '#6A9F45',
    padding: '15px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  grayHeader: {
    fontWeight: 600,
    backgroundColor: '#8C8C8C',
    padding: '15px',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
  },
  documentButtonWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px 0px',
  },
  documentButton: {
    color: '#4d4d4d',
    border: '2px solid #4c4c4c',
  },
  body: {
    padding: '30px',
    color: 'black',
    fontWeight: '600',
    lineHeight: '20px',
  },
}

// Note: Shouldn't this component show a list of inquiries instead of only one inquiry?
export const Inquiries = () => {
  const navigate = useNavigate()
  const theme = useTheme()

  const [inquiryList, setInquiryList] = React.useState<InquiryResponse[] | null>(null)

  const { data: inquiry, isLoading: isLoadingInquiry } = useDocumentProcessing(
    inquiryList ? inquiryList[0]?.id?.toString() : '0'
  )

  const newestEntry = inquiry
    ? inquiry.pārbaudes.sort((p1, p2) => {
        if (!p1.aktualizācijas_laiks || !p2.aktualizācijas_laiks) return 0
        return (
          parseISO(p2.aktualizācijas_laiks).getTime() - parseISO(p1.aktualizācijas_laiks).getTime()
        )
      })?.[0]
    : null
  const isUgfaAvailable = newestEntry ? newestEntry.komentārs_kods !== '24' : true

  const {
    id,
    iesniegšanas_datums,
    statuss_kods,
    piekrīt_veselības_datu_apstrādei,
    aizbilstamie,
    pārbaudes,
  } = inquiry || {}

  const statuss_kods_modified =
    statuss_kods !== InquiryStatusCode.Noraidīts && !isUgfaAvailable
      ? InquiryStatusCode.Nepieejams
      : statuss_kods

  const { t } = useTranslation()

  const eligibleChildren = aizbilstamie?.filter((e) => e.pārbaudes.some((r) => r.atbilst)) || []
  const ineligibleChildren = aizbilstamie?.filter((e) => !e.pārbaudes.some((r) => r.atbilst)) || []
  const debtors = pārbaudes?.filter((test) => test.tips_kods === UGFA_TYPE && !test.atbilst)

  const healthDataProcessingTerms = JSON.parse(
    localStorage.getItem('healthDataProcessingTerms') ||
      piekrīt_veselības_datu_apstrādei?.toString() ||
      'false'
  )

  const { isLoading: isLoadingInquiryList } = useQuery(
    'get-inquiry-list',
    async () => {
      return await getInquiryList()
    },
    {
      onSuccess: (response) => {
        setInquiryList(response)
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const nextStep = () => {
    navigate(`/inquiry-children-on-card/${id}`, {
      state: {
        eligibleChildren: eligibleChildren,
        allChildren: aizbilstamie,
      },
    })
  }

  const finishStep = () => {
    navigate(`/profile`)
  }

  if (isLoadingInquiryList || isLoadingInquiry) return <FullPageLoader />

  return (
    <>
      {!inquiry ? (
        <Box>
          {t('inquiries.application-statuss')}
          <Box mt={3}>
            <Button onClick={() => navigate('/inquiry-contact-info')}>
              {t('inquiries.create-inquiry')}
            </Button>
          </Box>
        </Box>
      ) : (
        <Box>
          <SubMenu />
          <Typography variant="h1" sx={{ mb: 4 }}>
            {t('profile.application')}
          </Typography>

          <Divider />

          <Box lineHeight="50px">
            <Box
              sx={[
                sx.inquiryRow,
                {
                  [theme.breakpoints.down(breakPoints.tablet)]: {
                    flexDirection: 'column',
                  },
                },
              ]}
            >
              <Typography sx={{ fontWeight: 600, width: 200, minWidth: 200 }}>
                {t('inquiries.application-date')}:
              </Typography>
              <Typography>
                {iesniegšanas_datums
                  ? format(new Date(iesniegšanas_datums), DEFAULT_DATE_FORMAT)
                  : ''}
              </Typography>
            </Box>
            <Box
              sx={[
                sx.inquiryRow,
                {
                  [theme.breakpoints.down(breakPoints.tablet)]: {
                    flexDirection: 'column',
                  },
                },
              ]}
            >
              <Typography sx={{ fontWeight: 600, width: 200, minWidth: 200 }}>
                {t('inquiries.test-result')}:
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <NewReleasesIcon
                  color={getStatusIconColor(statuss_kods_modified)}
                  style={{ marginRight: 4 }}
                />
                <Typography>{getStatusDescription(statuss_kods_modified)}</Typography>
              </Box>
            </Box>
          </Box>

          <Divider />

          <Grid container spacing={4} mt={1}>
            {eligibleChildren && eligibleChildren.length > 0 && (
              <Grid item sm={6} xs={12}>
                <Box sx={sx.greenContainer}>
                  <Box sx={sx.greenHeader}>{t('meet-criteria')}</Box>
                  <Box sx={sx.body}>
                    <Box>{t('inquiries.meet-criteria')}:</Box>
                    <br />
                    {eligibleChildren.map(
                      (person: Children, index: number, array: Array<Children>) => {
                        const childHasFiles = person.pārbaudes.some(
                          (pārbaude) => pārbaude.dokumenti && pārbaude.dokumenti.length > 0
                        )

                        return (
                          <Box key={index}>
                            <Box
                              sx={[
                                sx.documentButtonWrapper,
                                {
                                  [theme.breakpoints.down(breakPoints.tablet)]: {
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  },
                                },
                              ]}
                            >
                              <Box>
                                {person.vārds} {person.uzvārds}
                                {childHasFiles &&
                                inquiryDocumentEligibilityCheck(inquiry, person.pārbaudes) ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginTop: '12px',
                                    }}
                                  >
                                    <CheckIcon fontSize="large" sx={{ fill: '#6a9f45', mr: 1 }} />
                                    <Typography>{t('inquiries.file-exists')}</Typography>
                                  </Box>
                                ) : null}
                              </Box>
                              {inquiryDocumentEligibilityCheck(inquiry, person.pārbaudes) ? (
                                <Button
                                  variant="outlined"
                                  sx={[
                                    sx.documentButton,
                                    {
                                      [theme.breakpoints.down(breakPoints.tablet)]: {
                                        marginTop: '12px',
                                      },
                                    },
                                  ]}
                                  onClick={() =>
                                    navigate('/inquiry-child-info', {
                                      state: {
                                        iesniegums_id: inquiry?.id,
                                        iesniegums: inquiry,
                                        person,
                                      },
                                    })
                                  }
                                >
                                  {t('open')}
                                </Button>
                              ) : (
                                <></>
                              )}
                            </Box>
                            {index !== array.length - 1 && <Divider />}
                          </Box>
                        )
                      }
                    )}
                  </Box>
                </Box>
              </Grid>
            )}

            {ineligibleChildren && ineligibleChildren.length > 0 && (
              <Grid item sm={6} xs={12} sx={{ display: 'flex', alignItems: 'stretch' }}>
                <Box sx={sx.grayContainer}>
                  <Box sx={sx.grayHeader}>{t('inquiries.additional-info')}</Box>
                  <Box sx={sx.body}>
                    <Box>{t('inquiries.missing-criteria')}:</Box>
                    <br />
                    {ineligibleChildren.map(
                      (person: Children, index: number, array: Array<Children>) => {
                        const childHasFiles = person.pārbaudes.some(
                          (pārbaude) => pārbaude.dokumenti && pārbaude.dokumenti.length > 0
                        )

                        return (
                          <Box key={index}>
                            <Box
                              sx={[
                                sx.documentButtonWrapper,
                                {
                                  [theme.breakpoints.down(breakPoints.tablet)]: {
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                  },
                                },
                              ]}
                            >
                              <Box>
                                {person.vārds} {person.uzvārds}
                                {childHasFiles &&
                                inquiryDocumentEligibilityCheck(inquiry, person.pārbaudes) ? (
                                  <Box
                                    sx={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      marginTop: '12px',
                                    }}
                                  >
                                    <CheckIcon fontSize="large" sx={{ fill: '#6a9f45', mr: 1 }} />
                                    <Typography>{t('inquiries.file-exists')}</Typography>
                                  </Box>
                                ) : null}
                              </Box>
                              {inquiryDocumentEligibilityCheck(inquiry, person.pārbaudes) ? (
                                <Button
                                  variant="outlined"
                                  sx={[
                                    sx.documentButton,
                                    {
                                      [theme.breakpoints.down(breakPoints.tablet)]: {
                                        marginTop: '12px',
                                      },
                                    },
                                  ]}
                                  onClick={() =>
                                    navigate('/inquiry-child-info', {
                                      state: {
                                        iesniegums_id: inquiry?.id,
                                        iesniegums: inquiry,
                                        person,
                                      },
                                    })
                                  }
                                >
                                  {t('open')}
                                </Button>
                              ) : (
                                <></>
                              )}
                            </Box>
                            {index !== array.length - 1 && <Divider />}
                          </Box>
                        )
                      }
                    )}
                  </Box>
                </Box>
              </Grid>
            )}

            {ineligibleChildren &&
              ineligibleChildren.length > 0 &&
              debtors &&
              debtors.length > 0 && <Grid item sm={6} xs={12} />}

            {debtors && debtors.length > 0 && (
              <Grid item sm={6} xs={12} sx={{ display: 'flex', alignItems: 'stretch' }}>
                <Box sx={sx.grayContainer}>
                  <Box sx={sx.grayHeader}>{t('inquiries.additional-info')}</Box>
                  <Box sx={sx.body}>
                    <Box
                      sx={[
                        sx.documentButtonWrapper,
                        {
                          [theme.breakpoints.down(breakPoints.tablet)]: {
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                          },
                        },
                      ]}
                    >
                      <Box>
                        {!isUgfaAvailable
                          ? getStatusDescription(InquiryStatusCode.Nepieejams)
                          : t('inquiries.guarantee-debtor')}
                        {debtors[0].dokumenti && debtors[0].dokumenti.length > 0 ? (
                          <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}>
                            <CheckIcon fontSize="large" sx={{ fill: '#6a9f45', mr: 1 }} />
                            <Typography>{t('inquiries.file-exists')}</Typography>
                          </Box>
                        ) : null}
                      </Box>

                      <Box
                        sx={{
                          justifyContent: 'flex-end',
                        }}
                      >
                        <Button
                          variant="outlined"
                          sx={[
                            sx.documentButton,
                            {
                              [theme.breakpoints.down(breakPoints.tablet)]: {
                                marginTop: '12px',
                              },
                            },
                          ]}
                          onClick={() =>
                            navigate('/inquiry-debtor-info', {
                              state: {
                                iesniegums: inquiry,
                                debtor: debtors[0],
                              },
                            })
                          }
                        >
                          {t('open')}
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>

          {statuss_kods_modified !== InquiryStatusCode.Noraidīts && (
            <Box>
              <Typography variant="h3" mt={4} sx={{ fontWeight: 600 }}>
                {t('inquiries.health-data')}
              </Typography>
              <Box mt={2} mb={2} sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControlLabel
                  sx={{
                    paddingTop: 2,
                    cursor: 'default',
                  }}
                  control={<Checkbox checked={healthDataProcessingTerms} />}
                  label={
                    <Box sx={{ display: 'flex' }}>
                      <Box>{t('inquiries.health-data-approval')}</Box>
                    </Box>
                  }
                />
              </Box>
              <Button
                variant="text"
                sx={{
                  textTransform: 'unset',
                  padding: 0,
                }}
                onClick={() =>
                  navigate('/health-data-processing-terms', {
                    state: { showButtons: true, inquiryId: id },
                  })
                }
              >
                {t('open-confirm')}
              </Button>
            </Box>
          )}

          {statuss_kods_modified === InquiryStatusCode.Apstiprināts && (
            <Box
              mt={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Button variant="outlined" sx={{ minWidth: '200px' }} onClick={nextStep}>
                {t('continue')}
              </Button>
            </Box>
          )}

          {(statuss_kods_modified === InquiryStatusCode.Gaida_informāciju ||
            statuss_kods_modified === InquiryStatusCode.Izskatīšanā ||
            statuss_kods_modified === InquiryStatusCode.Jauns) && (
            <Box
              mt={4}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <Button variant="outlined" sx={{ minWidth: '200px' }} onClick={finishStep}>
                {t('save-submit')}
              </Button>
              <Typography variant="subtitle2" mt={2}>
                Jūsu iesniegtie dati tiks izskatīti un izskatīšanas rezultāts būs apskatāms šajā
                sadaļā.
              </Typography>
            </Box>
          )}
        </Box>
      )}
    </>
  )
}
