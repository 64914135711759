import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

interface Data {
  id: string
}

export const getInquiry = async ({ id }: Data) => {
  return axios.get(`${BASE_PATH_PORTAL}/data/iesniegums/${id}`).then((response) => response.data)
}
