import React from 'react'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box, Button, Divider, FormHelperText, Typography, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import {
  EMAIL_LENGTH,
  INVALID_PHONE_FORMAT,
  PHONE_MAX_LENGTH,
  PHONE_MIN_LENGTH,
  PHONE_PLACEHOLDER,
  REGEX_EMAIL_FORMAT,
} from '../../constants'
import styles from './InquiryContactInfo.module.css'
import { useMutation, useQuery } from 'react-query'
import { updateUser } from '../../api/user/updateUser'
import { usePhoneVerificationDialogState, useUserDataStore } from '../../store'
import { getInquiryList } from '../../api/inquiry/getInquiryList'
import { addInquiry } from '../../api/inquiry/addInquiry'
import { InquiryEntry } from '../../interfaces/inquiry/InquiryEntry'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { FullPageLoader } from '../../components'
import { ErrorDialog } from '../../components/ErrorDialog/ErrorDialog'
import { InquiryStatusCode } from '../../enums/InquiryStatusCode'
import { User } from '../../interfaces'
import { getUser } from '../../api/user/getUser'
import {
  sendActivationCodeToMyEmail,
  sendActivationCodeToMyPhone,
} from '../../api/activation/activation'
import { useTranslation } from 'react-i18next'

// const Wrapper = styled(Box)(({ theme }) => ({
//   display: 'grid',
//   gap: '10px',
//   gridAutoFlow: 'column',
//   margin: '36px 0 56px',
// })) as unknown as typeof Box

export const InquiryContactInfo = () => {
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const { currentUser } = useUserDataStore()
  const theme = useTheme()
  const { setIsOpen: setIsPhoneVerificationDialogOpen, setPhoneId } =
    usePhoneVerificationDialogState()
  const { data: inquiryList, isLoading: isLoadingInquiryList } = useQuery(
    'inquiry-list',
    getInquiryList,
    {
      enabled: Boolean(currentUser),
    }
  )
  const hasRegisteredInquiries = inquiryList?.length > 0
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false)
  const [errorDialogMessage, setErrorDialogMessage] = React.useState('')
  const [phone, setPhone] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [eaddress, setEaddress] = React.useState(false)
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>({
    phone: '',
    contactInformation: '',
    email: '',
  })

  const { t } = useTranslation()

  const { isLoading: isLoadingUserInfo } = useQuery(
    ['user-info', currentUser?.id],
    () => getUser({ id: currentUser?.id }),
    {
      onSuccess: (userResponse: User) => {
        if (userResponse.id) {
          setEmail(userResponse?.epasti?.[0]?.epasts || '')
          setPhone(userResponse?.telefoni?.[0]?.telefons || '')
        }
      },
    }
  )

  const healthDataProcessingTerms = JSON.parse(
    localStorage.getItem('healthDataProcessingTerms') || 'false'
  )

  const handleClose = () => {
    setOpenErrorDialog(false)
    setErrorDialogMessage('')
    navigate('/logout')
  }

  const isFormInvalid = () => {
    const _errors: { [key: string]: string } = {}

    if (!phone && !email && !eaddress) {
      _errors['contactInformation'] = 'Lūdzu, norādiet vismaz vienu no komunikācijas kanāliem'
    }

    if (email && email.length > EMAIL_LENGTH) {
      _errors['email'] = `E-pasts pārsniedz maksimālo pieļaujamo garumu ${EMAIL_LENGTH}`
    }

    if (email && !email.match(REGEX_EMAIL_FORMAT)) {
      _errors['email'] = 'Nekorekts e-pasta formāts'
    }

    if (phone && phone.length > PHONE_MAX_LENGTH) {
      _errors[
        'phone'
      ] = `Tālruņa numurs pārsniedz maksimālo pieļaujamo garumu - ${PHONE_MAX_LENGTH}`
    }

    if (phone && phone.length < PHONE_MIN_LENGTH) _errors['phone'] = INVALID_PHONE_FORMAT

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onEAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEaddress(event.target.checked)
    setFormErrors({
      ...formErrors,
      contactInformation: '',
    })
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
    setFormErrors({
      ...formErrors,
      email: '',
      contactInformation: '',
    })
  }

  const onPhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value.replace(/[^0-9]/gi, ''))
    setFormErrors({
      ...formErrors,
      contactInformation: '',
    })
  }

  const updateUserMutation = useMutation((data: any) => updateUser(data), {
    onSuccess: async (userResponse: User) => {
      if (userResponse) {
        await handleSendActivationCodeToEmail(userResponse)
        await handleSendActivationCodeToPhone(userResponse)
        checkInquiry()
      }
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const addInquiryMutation = useMutation((data: any) => addInquiry(data), {
    onError: (error) => {
      setErrorDialogMessage(handleErrorMessages(error, true))
      setOpenErrorDialog(true)
    },
    onSuccess: (resp: any) => {
      if (resp) {
        handleInquiry(resp)
      }
    },
  })

  const sendActivationCodeToMyEmailMutation = useMutation(
    (emailId: number) => sendActivationCodeToMyEmail(emailId),
    {
      onSuccess: (response) => {
        if (response)
          showSnackBar({
            text: 'Jums uz e-pastu ir nosūtīts verifikācijas kods',
          })
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )
  const sendActivationCodeToMyPhoneMutation = useMutation(
    (phoneId: number) => sendActivationCodeToMyPhone(phoneId),
    {
      onSuccess: (response) => {
        if (response) {
          showSnackBar({
            text: 'Uz jūsu tālruni ir nosūtīts verifikācijas kods',
          })
          setIsPhoneVerificationDialogOpen(true)
        }
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const isLoading =
    sendActivationCodeToMyEmailMutation.isLoading ||
    addInquiryMutation.isLoading ||
    updateUserMutation.isLoading ||
    sendActivationCodeToMyPhoneMutation.isLoading ||
    isLoadingUserInfo

  const handleSendActivationCodeToEmail = async (user: User) => {
    const unverifiedEmail = user?.epasti?.find((email) => email.epasts && !email.ir_verificēts)
    if (unverifiedEmail) {
      sendActivationCodeToMyEmailMutation.mutate(unverifiedEmail.id)
    }
  }

  const handleSendActivationCodeToPhone = async (user: User) => {
    const unverifiedPhone = user.telefoni?.find((tel) => tel.telefons && !tel.ir_verificēts)
    if (unverifiedPhone) {
      setPhoneId(unverifiedPhone.id)
      sendActivationCodeToMyPhoneMutation.mutate(unverifiedPhone.id)
    }
  }

  const checkInquiry = async () => {
    return getInquiryList()
      .then((response) => {
        if (response && response.length > 0) {
          // There is an existing inquiry
          showSnackBar({
            severity: 'warning',
            text: 'Personai iesniegums jau ir reģistrēts',
          })

          navigate('/inquiries')
        } else {
          // There is no existing inquiry
          addInquiryMutation.mutate({
            piekrīt_veselības_datu_apstrādei: healthDataProcessingTerms,
          })
        }
      })
      .catch((error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      })
  }

  const handleInquiry = async (inquiry: InquiryEntry) => {
    const { statuss_kods } = inquiry

    switch (statuss_kods) {
      case InquiryStatusCode.Izskatīšanā:
        navigate('/inquiry-processing-error')
        break

      case InquiryStatusCode.Jauns:
      case InquiryStatusCode.Gaida_informāciju:
      case InquiryStatusCode.Apstiprināts:
      case InquiryStatusCode.Noraidīts:
      case InquiryStatusCode.Pārvērtēšanā:
      case InquiryStatusCode.Slēgts:
        navigate(`/inquiries`)
        break

      default:
        navigate('/')
    }
  }

  const onSubmit = async () => {
    const eaddressData = eaddress ? 'izmantot' : 'neizmantot'
    const phoneData = [
      {
        telefons: phone,
        piezīmes: '',
      },
    ]

    const emailData = [
      {
        epasts: email,
        piezīmes: '',
      },
    ]

    if (!isFormInvalid()) return

    updateUserMutation.mutate({
      user: currentUser,
      phone: phoneData,
      email: emailData,
      eaddress: eaddressData,
    })
  }

  if (isLoadingInquiryList) return <FullPageLoader />

  if (hasRegisteredInquiries) navigate('/profile')

  return (
    <Box>
      <ErrorDialog open={openErrorDialog} handleClose={handleClose} text={errorDialogMessage} />
      <Typography variant="h1" sx={{ mb: 4, mt: 2 }}>
        {t('inquiry-contact-info.application-inquiry')}
      </Typography>
      <Divider />
      <Box>
        <Box className={styles.mainWrapper}>
          <Box marginTop="12px">
            <Box sx={{ fontWeight: 600 }}>{t('phone-number')}</Box>
            <TextField
              type="tel"
              disabled={isLoading}
              value={phone}
              placeholder={PHONE_PLACEHOLDER}
              onChange={onPhoneChange}
              sx={{
                width: '350px',
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">+</InputAdornment>,
              }}
              helperText={
                formErrors.phone || (
                  <Typography component="span" fontSize={12} lineHeight={1}>
                    {t('contact-info.phone-confirmation')}
                  </Typography>
                )
              }
              error={Boolean(formErrors.phone)}
            />
          </Box>

          <Box marginTop="12px">
            <Box sx={{ fontWeight: 600 }}>{t('e-mail')}</Box>
            <TextField
              type="email"
              disabled={isLoadingUserInfo}
              placeholder="e-pasts@e-pasts.lv"
              value={email}
              sx={{
                width: '350px',
              }}
              onChange={onEmailChange}
              helperText={
                formErrors.email || (
                  <Typography component="span" fontSize={12} lineHeight={1}>
                    {t('contact-info.e-mail-info')}
                  </Typography>
                )
              }
              error={Boolean(formErrors.email)}
            />
          </Box>

          <Box marginTop="12px">
            <Box style={{ fontWeight: 600, marginBottom: '8px' }}>{t('e-address')}</Box>
            <FormControlLabel
              sx={{ marginRight: 0 }}
              control={<Checkbox checked={eaddress} onChange={onEAddressChange} />}
              label={t('contact-info.use-of-e-address')}
            />
          </Box>
        </Box>

        {formErrors.contactInformation && (
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {formErrors.contactInformation}
          </FormHelperText>
        )}

        <Box mt={4} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button disabled={isLoading} variant="outlined" onClick={onSubmit}>
            {t('save-continue')}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
