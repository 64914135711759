import create from 'zustand'

interface EmailVerificationAlertState {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}
export const useEmailVerificationAlertState = create<EmailVerificationAlertState>((set) => ({
  isOpen: false,
  setIsOpen: (nextIsOpen: boolean) => {
    set(() => ({ isOpen: nextIsOpen }))
  },
}))
