import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Checkbox, Divider, FormControlLabel, Typography } from '@mui/material'
import { SubMenu } from '../../components'
import { useTranslation, Trans } from 'react-i18next'

export const InquiryAgreement = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  const [generalDataProcessingTerms, setGeneralDataProcessingTerms] = React.useState<boolean>(
    Boolean(JSON.parse(localStorage.getItem('generalDataProcessingTerms') || 'false'))
  )
  const [healthDataProcessingTerms, setHealthDataProcessingTerms] = React.useState<boolean>(
    Boolean(JSON.parse(localStorage.getItem('healthDataProcessingTerms') || 'false'))
  )

  const onGeneralDataProcessingTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('generalDataProcessingTerms', JSON.stringify(event.target.checked))
    setGeneralDataProcessingTerms(event.target.checked)
  }

  const onHealthDataProcessingTermsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    localStorage.setItem('healthDataProcessingTerms', JSON.stringify(event.target.checked))
    setHealthDataProcessingTerms(event.target.checked)
  }

  /**
   *  Set initial value for "healthDataProcessingTerms" on
   *  first render in localStorage for later use
   */
  useEffect(() => {
    localStorage.setItem('healthDataProcessingTerms', JSON.stringify(healthDataProcessingTerms))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Box>
      <SubMenu />
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('inquiry-agreement.title')}
      </Typography>

      <Divider />

      <Typography sx={{ margin: '20px 0 12px' }} variant="h4">
        {t('inquiry-agreement.login-approvals')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          sx={{
            paddingTop: 2,
            cursor: 'default',
          }}
          control={
            <Checkbox
              checked={generalDataProcessingTerms}
              onChange={onGeneralDataProcessingTermsChange}
            />
          }
          label={
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Trans i18nKey="inquiry-agreement.terms-of-use" components={{ bold: <strong /> }} />
              </Box>
            </Box>
          }
        />
      </Box>

      <Button
        variant="text"
        sx={{
          textTransform: 'unset',
          padding: 0,
        }}
        onClick={() =>
          navigate('/general-data-processing-terms', {
            state: { showButtons: true },
          })
        }
      >
        <u>{t('inquiry-agreement.open-and-confirm')}</u>
      </Button>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <FormControlLabel
          sx={{
            paddingTop: 3,
            cursor: 'default',
          }}
          control={
            <Checkbox
              checked={healthDataProcessingTerms}
              onChange={onHealthDataProcessingTermsChange}
            />
          }
          label={
            <Box sx={{ display: 'flex' }}>
              <Box>
                <Trans
                  i18nKey={'inquiry-agreement.health-rules'}
                  components={{ bold: <strong /> }}
                />
              </Box>
            </Box>
          }
        />
      </Box>

      <Button
        variant="text"
        sx={{
          textTransform: 'unset',
          padding: 0,
        }}
        onClick={() =>
          navigate('/health-data-processing-terms', {
            state: { showButtons: true },
          })
        }
      >
        <u>{t('inquiry-agreement.open-and-confirm')}</u>
      </Button>

      <Box mt={4} mb={2}>
        <Button
          sx={{ minWidth: '200px' }}
          variant="outlined"
          disabled={!generalDataProcessingTerms}
          onClick={() =>
            navigate('/login/vps', {
              state: {
                healthDataProcessingTerms: healthDataProcessingTerms,
              },
            })
          }
        >
          {t('login')}
        </Button>
      </Box>
    </Box>
  )
}
