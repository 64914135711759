import { Link, Box } from '@mui/material'
import { BASE_PATH_CMS } from '../../constants'
import { useAppContextStore } from '../../store'

export const SocialMedia = () => {
  const socialMedia = useAppContextStore((state) => state.socialMedia)

  return (
    <>
      {socialMedia?.map((media, index) => (
        <Link key={index} href={media.url} target="_blank" sx={{ mr: 1, ml: 1, color: '#FFF' }}>
          <Box component="img" src={BASE_PATH_CMS + media.ikona} />
        </Link>
      ))}
    </>
  )
}
