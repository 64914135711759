import { Box, Button, Divider, FormHelperText, useTheme } from '@mui/material'
import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { format } from 'date-fns'
import { FileListItem } from '../../File/FileListItem'
import { InquiryRegistryCheck } from '../../interfaces/inquiry/InquiryRegistryCheck'
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DOCUMENT_STATUS,
  FILE_SIZE_LIMIT_MB_MESSAGE,
  REQUIRED_FILE_ERROR_MESSAGE,
  UGFA_DOCUMENT_TYPE,
} from '../../constants'
import { FileUploadButton } from '../../components/File/FileUploadButton'
import { FullPageLoader } from '../../components/FullPageLoader'
import { uploadFiles } from '../../utils/uploadFiles'
import { changeRegisterCheckDocument } from '../../api/file/changeRegisterCheckDocument'
import { RegisterCheckDocument } from '../../interfaces/file/RegisterCheckDocument'
import { useMutation } from 'react-query'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { SubMenu } from '../../components'
import { InquiryEntry } from '../../interfaces/inquiry/InquiryEntry'
import { useTranslation } from 'react-i18next'
import { FileEntry } from '../../interfaces/file/FileEntry'

interface Props {
  iesniegums: InquiryEntry
  debtor: InquiryRegistryCheck
}

export const InquiryDebtorInfo = () => {
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const theme = useTheme()
  const { t } = useTranslation()

  const { state } = useLocation()
  const { iesniegums, debtor } = state as Props
  const existingFiles = debtor.dokumenti?.flatMap(({ faili, statuss_kods }) =>
    faili.map((file) => ({ file, state: 'keep', status: statuss_kods } as FileListItem))
  )
  const [uploadedFilesUgfa, setUploadedFilesUgfa] = React.useState<FileEntry[]>([])
  const [filesUgfa, setFilesUgfa] = React.useState<FileListItem[]>(existingFiles || [])
  const [isLoading, setIsLoading] = React.useState(false)
  const [inquiryErrors, setInquiryErrors] = React.useState<{
    [key: string]: string
  }>({})

  const code: { [key: string]: string } = {
    UGFA: 'Dokumenta, kas apliecina tiesības saņemt apliecību, pievienošana',
  }

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    if (debtor && filesUgfa.length === 0) {
      errors['filesUgfa'] = REQUIRED_FILE_ERROR_MESSAGE
    }

    setInquiryErrors({
      ...inquiryErrors,
      ...errors,
    })

    return Object.keys(errors).length === 0
  }

  const uploadDocument = () => {
    const { id } = debtor

    if (debtor) {
      const isValid = validateForm()

      if (!isValid) return

      setIsLoading(true)

      if (uploadedFilesUgfa.length > 0) {
        changeDocument.mutate({
          faili: uploadedFilesUgfa,
          personas_pārbaude_id: id,
          iesniegums_id: iesniegums.id,
          tips_kods: UGFA_DOCUMENT_TYPE,
          iesniegšanas_datums: format(new Date() as unknown as Date, DEFAULT_API_DATE_FORMAT),
          derīguma_termiņš: null,
          statuss_kods: DEFAULT_DOCUMENT_STATUS,
          komentārs: '',
        })
      } else {
        setIsLoading(false)
        showSnackBar()
        navigate(-1)
      }
    }
  }

  const changeDocument = useMutation(
    (document: RegisterCheckDocument) => changeRegisterCheckDocument(document),
    {
      onError: (error: any) => {
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      onSuccess: () => {
        setIsLoading(false)
        showSnackBar()
        navigate(-1)
      },
    }
  )

  const onUgfaChange = (files: FileListItem[]) => {
    setFilesUgfa(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFilesUgfa(() => [
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of files) {
          if (fileEntry.state === 'keep') {
            setUploadedFilesUgfa((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).faila_id,
              },
            ])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setFilesUgfa([])
        setUploadedFilesUgfa([])
      })
      .finally(() => {
        setIsLoading(false)
        setInquiryErrors({
          ...inquiryErrors,
          filesUgfa: '',
        })
      })
  }

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box className="body-container">
      <SubMenu />
      {debtor && (
        <>
          <h4>{code.UGFA}</h4>
          <Divider />
          <h4>{t('files.added-files')}:</h4>
          <FileUploadButton useLink onFileChange={onUgfaChange} files={filesUgfa} />
          {inquiryErrors.filesUgfa && (
            <Box sx={{ margin: '20px 0px' }}>
              <FormHelperText sx={{ color: theme.palette.error.main }}>
                {inquiryErrors.filesUgfa}
              </FormHelperText>
            </Box>
          )}
        </>
      )}
      <Box sx={{ margin: '20px 0px' }}>
        <Button
          variant="text"
          sx={{
            textTransform: 'unset',
            padding: 0,
          }}
          onClick={() => navigate(-1)}
        >
          <u>{t('back')}</u>
        </Button>
        <Button variant="outlined" sx={{ marginLeft: '12px' }} onClick={uploadDocument}>
          {t('save-continue')}
        </Button>
      </Box>
    </Box>
  )
}
