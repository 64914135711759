import { getPage } from '../../api'
import { Page } from '../../enums'
import { useQuery } from 'react-query'
import { GenericPage } from '../../components'
import { GenericPageItem } from '../../interfaces'
import { useTranslation } from 'react-i18next'

export const ForEntrepreneurs = () => {
  const { i18n } = useTranslation()

  const { data, isLoading } = useQuery<GenericPageItem>(
    'for-entrepreneurs',
    () => getPage(Page.ForEntrepreneurs, i18n.language),
    {}
  )

  return <GenericPage page={data} isLoading={isLoading} />
}
