export const getChildAge = (birthDay: string | Date) => {
  const validBirthDate = birthDay ? new Date(birthDay).getTime() : new Date(0).getTime()

  const todayDate = Date.now()

  const ageDifference = todayDate - validBirthDate

  const ageDate = new Date(ageDifference)

  return Math.abs(ageDate.getUTCFullYear() - 1970)
}
