import { Dialog, DialogActions, DialogContent, Typography, Box, Button } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { setPartnerRaiting } from '../../api/partners/setPartnerRaiting'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { StyledRating } from './Rating'

interface Props {
  onClose: () => void
  onSuccess: () => void
  partnerName: string
  partnerId: number
}

const RatingModal: React.FC<Props> = ({ onClose, onSuccess, partnerName, partnerId }) => {
  const { t } = useTranslation()
  const { showSnackBar } = useSnackBar()
  const [rating, setRating] = useState<number>(0)

  const setPartnerRaitingMutation = useMutation(
    () => setPartnerRaiting({ sadarbības_partneris_id: partnerId, vērtējums: rating }),
    {
      onSuccess: () => {
        onSuccess()
        showSnackBar()
        onClose()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="xs">
      <DialogContent sx={{ paddingBottom: '8px' }}>
        <Box display="flex" flexDirection="column">
          <Typography display="inline" textAlign="center" variant="h2">
            {partnerName}
          </Typography>
          <Box textAlign="center" marginTop="14px">
            <StyledRating
              value={rating}
              onChange={(_, value) => setRating(value || 0)}
              size="large"
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant="contained" onClick={() => setPartnerRaitingMutation.mutate()}>
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RatingModal
