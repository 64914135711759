import { useQuery } from "react-query"
import { useNavigate } from "react-router-dom"
import { getCurrentSession } from "../../api/user/getUser"
import { useSnackBar } from "../../providers"
import { useEmailVerificationAlertState, useUserDataStore } from "../../store"
import { handleErrorMessages, hasNotConfirmedEmail } from "../../utils"

export const VPSCallback = () => {

  const navigate = useNavigate()

  const { setIsOpen: setIsOpenEmailVerificationDialog } = useEmailVerificationAlertState()
  const { showSnackBar } = useSnackBar()
  const { setCurrentUser } = useUserDataStore()

  useQuery({
    queryKey: ['users'],
    queryFn: getCurrentSession,
    onSuccess: async (response) => {
      setIsOpenEmailVerificationDialog(await hasNotConfirmedEmail(response.id))
      setCurrentUser(response)
      navigate('/inquiry-contact-info')
    },
    onError: (error) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
      navigate('/')
    },
  })

  return (
    <></>
  )
}