import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

interface Data {
  id: number
  piekrīt_veselības_datu_apstrādei: boolean
}

export const updateHealthDataAgreement = async ({ id, piekrīt_veselības_datu_apstrādei }: Data) => {
  const response = await axios.put(
    `${BASE_PATH_PORTAL}/data/iesnieguma_piekrišanas_veselības_datu_apstrādei_maiņa/${id}`,
    {
      id: id,
      piekrīt_veselības_datu_apstrādei: piekrīt_veselības_datu_apstrādei,
    }
  )
  return response.data
}
