import { CSSProperties, useState } from 'react'
import {
  Box,
  SxProps,
  Typography,
  TextField,
  Button,
  FormHelperText,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import LatvijaLogo from './latvija-lv-logo.png'
import { LogoIconWithText } from '../../icons'
import { CertificationVerificationForm } from './types'
import {
  REQUIRED_ERROR_MESSAGE,
  INVALID_PERSONAL_CODE_SECOND_PART,
  REGEX_HOME_PAGE_FORMAT,
  BASE_PATH_PORTAL,
} from '../../constants'
import { internalCertificateVertification } from '../../api/embedded-control/internalCertificateVertification'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

const sx: { [key: string]: CSSProperties | SxProps } = {
  mainBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  imageBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    width: '200px',
  },
  formBox: {
    marginTop: '20px',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  externalVerificationBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  externalVerificationButton: {
    marginTop: '12px',
  },
  internalVerificationBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  inputBox: {
    marginTop: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  inputLabel: {
    marginRight: '8px',
  },
  personalCodeWrapperBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  personalCodeBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  personalCodeHyphen: {
    margin: '0 10px',
  },
  personalCodeInputField: {
    width: '40%',
  },
  internalVerificationButtonBox: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  internalVerificationButton: {
    marginTop: '12px',
  },
}

export const CertificateVerification: React.FC = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const [searchParams] = useSearchParams()
  const returnUrl = searchParams.get('return_url') || ''
  const { showSnackBar } = useSnackBar()
  const [formErrors, setFormErrors] = useState<{ [key: string]: string }>({})
  const [formState, setFormState] = useState<CertificationVerificationForm>({
    name: '',
    surname: '',
    certificateNumber: '',
    personalCodeSecondPart: '',
  })

  const { refetch: fetchInternalCertificateVertification } = useQuery(
    ['is-certificate-valid', formState],
    () => internalCertificateVertification(formState),
    {
      onSuccess: (data: { ir_derīga: boolean }) => {
        if (returnUrl && returnUrl.match(REGEX_HOME_PAGE_FORMAT)) {
          const redirectToReturnUrl = `${returnUrl}?ir_derīga=${data.ir_derīga}`
          window.location.replace(redirectToReturnUrl)
        } else {
          showSnackBar({
            severity: 'error',
            text: t('embedded-verification.incorrect-return-url'),
          })
        }
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      enabled: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  )

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    })

    setFormErrors({
      ...formErrors,
      [event.target.name]: '',
    })
  }

  const isFormInvalid = () => {
    const _errors: { [key: string]: string } = {}
    const { name, surname, certificateNumber, personalCodeSecondPart } = formState

    if (!name) _errors['name'] = REQUIRED_ERROR_MESSAGE
    if (!surname) _errors['surname'] = REQUIRED_ERROR_MESSAGE
    if (!certificateNumber) _errors['certificateNumber'] = REQUIRED_ERROR_MESSAGE
    if (!personalCodeSecondPart) _errors['personalCodeSecondPart'] = REQUIRED_ERROR_MESSAGE
    if (personalCodeSecondPart && personalCodeSecondPart.length !== 5)
      _errors['personalCodeSecondPart'] = INVALID_PERSONAL_CODE_SECOND_PART

    setFormErrors(_errors)

    return Object.keys(_errors).length === 0
  }

  const onInternalCertificateVerification = () => {
    if (!isFormInvalid()) return

    fetchInternalCertificateVertification()
  }

  const onExternalCertificateVerification = () => {
    window.location.replace(
      `${window.location.origin}${BASE_PATH_PORTAL}/api/v1/login_start?iksReturnUrl=${returnUrl}`
    )
  }

  return (
    <Box sx={sx.mainBox}>
      <Box sx={sx.imageBox}>
        <LogoIconWithText />
      </Box>
      <Typography marginTop="8px">{t('embedded-verification.title')}</Typography>
      <Typography marginTop="8px" maxWidth="600px" textAlign="center">
        {t('embedded-verification.subtitle')}
      </Typography>
      <Box sx={sx.formBox}>
        <Box sx={sx.externalVerificationBox}>
          <Box component="img" sx={sx.logo} src={LatvijaLogo} alt={'latvija-lv-logo'} />
          <Button
            variant="contained"
            sx={sx.externalVerificationButton}
            onClick={onExternalCertificateVerification}
          >
            {t('verify')}
          </Button>
        </Box>
        <Box sx={sx.internalVerificationBox}>
          <Box sx={sx.inputBox}>
            <Typography sx={sx.inputLabel}>{t('name')}</Typography>
            <TextField
              variant="outlined"
              name="name"
              value={formState.name}
              onChange={handleTextChange}
              error={Boolean(formErrors.name)}
              helperText={formErrors.name}
            />
          </Box>
          <Box sx={sx.inputBox}>
            <Typography sx={sx.inputLabel}>{t('surname')}</Typography>
            <TextField
              variant="outlined"
              name="surname"
              value={formState.surname}
              onChange={handleTextChange}
              error={Boolean(formErrors.surname)}
              helperText={formErrors.surname}
            />
          </Box>
          <Box sx={sx.inputBox}>
            <Typography sx={sx.inputLabel}>{t('certificate-number')}</Typography>
            <TextField
              variant="outlined"
              name="certificateNumber"
              value={formState.certificateNumber}
              onChange={handleTextChange}
              error={Boolean(formErrors.certificateNumber)}
              helperText={formErrors.certificateNumber}
            />
          </Box>
          <Box sx={sx.inputBox}>
            <Typography sx={sx.inputLabel}>{t('personal-number-second-part')}</Typography>
            <Box sx={sx.personalCodeWrapperBox}>
              <Box sx={sx.personalCodeBox}>
                <Typography>XXXXXX</Typography>
                <Typography sx={sx.personalCodeHyphen}>-</Typography>
                <TextField
                  sx={sx.personalCodeInputField}
                  variant="outlined"
                  name="personalCodeSecondPart"
                  value={formState.personalCodeSecondPart}
                  onChange={handleTextChange}
                  error={Boolean(formErrors.personalCodeSecondPart)}
                />
              </Box>
              {formErrors.personalCodeSecondPart ? (
                <FormHelperText style={{ color: theme.palette.error.main }}>
                  {formErrors.personalCodeSecondPart}
                </FormHelperText>
              ) : null}
            </Box>
          </Box>
          <Box sx={sx.internalVerificationButtonBox}>
            <Button
              variant="contained"
              sx={sx.internalVerificationButton}
              onClick={onInternalCertificateVerification}
            >
              {t('verify')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
