import axios from 'axios'
import { BASE_PATH_DISTRO } from '../../constants'

export const getPartnerLocations = (partnerId: number) => {
  return axios
    .get(`${BASE_PATH_DISTRO}/data/sadarbības_partnera_vietu_saraksts`, {
      params: {
        sadarbības_partneris_id: partnerId,
      },
    })
    .then((response) => response.data)
}
