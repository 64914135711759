import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getUsefulTipsItem } from '../../api/useful-tips'
import { GenericItemEntry } from '../../components'
import { NewsItem } from '../../interfaces'

export const UsefulTipsEntry = () => {
  const { id } = useParams()
  const { i18n } = useTranslation()

  const { data, isLoading } = useQuery<NewsItem>(
    ['useful-tips-entry', id],
    () => getUsefulTipsItem(id as string, i18n.language),
    {
      enabled: !!id,
    }
  )

  return <GenericItemEntry isLoading={isLoading} data={data} />
}
