import DeleteIcon from '@mui/icons-material/Delete'
import UploadIcon from '@mui/icons-material/Upload'
import {
  Box,
  Button,
  Chip,
  FormHelperText,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material'
import React, { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { FileListItem } from '../../File/FileListItem'
import { ACCEPTED_FILE_TYPES } from '../../constants'
import { DocumentStatus } from '../../enums/DocumentStatus'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { FileLink } from '../FileLink/FileLink'

const sx = {
  fileItem: {
    mr: 2,
    width: '320px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}
interface Props {
  files: FileListItem[]
  multiple?: boolean
  useLink?: boolean
  onFileChange: (files: FileListItem[]) => void
  acceptedUploadFileTypes?: { [key: string]: string | string[] }
  error?: string
}

export function FileUploadButton({
  multiple = true,
  useLink,
  onFileChange,
  files,
  acceptedUploadFileTypes = ACCEPTED_FILE_TYPES,
  error,
}: Props) {
  const sortedFiles = files.sort((a, b) => {
    if (a.upload_time && b.upload_time) {
      const dateA = new Date(a.upload_time).getTime()
      const dateB = new Date(b.upload_time).getTime()

      return dateB - dateA
    } else {
      return 0
    }
  })
  const defaultFileTypes = acceptedUploadFileTypes
  const acceptedFileTypes = Object.keys(defaultFileTypes)
  const acceptedTypes = acceptedFileTypes.map((type) => `.${type}`).join(',')
  const lastType = acceptedFileTypes.pop()
  const acceptedTypesText = acceptedFileTypes.join(', ') + ` vai ${lastType}`

  const { t } = useTranslation()

  const fileUploadRef = useRef<HTMLInputElement>(null)
  const { showSnackBar } = useSnackBar()
  const theme = useTheme()

  const selectFiles = () => {
    fileUploadRef && fileUploadRef.current?.click()
  }

  const isFileValid = (extension: string, type: string) => {
    if (defaultFileTypes[extension] == null) {
      return false
    }

    if (Array.isArray(defaultFileTypes[extension])) {
      return defaultFileTypes[extension].includes(type)
    }

    return defaultFileTypes[extension] === type
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const newFiles: FileListItem[] = []

      for (const file of Array.from(event.target.files)) {
        /**
         * Using "toLowerCase()" because file upload should
         * also allow files with all caps extension
         */
        const extension = file.name.split('.').pop()?.toLowerCase()

        if (!extension) {
          continue
        }

        if (isFileValid(extension, file.type)) {
          newFiles.push({
            file,
            state: 'new',
          })
        } else {
          showSnackBar({
            severity: 'warning',
            text: `${file.name} netika pievienots. Nederīgs faila formāts!`,
          })
        }
      }

      if (multiple) {
        onFileChange([...sortedFiles, ...newFiles])
      } else {
        onFileChange([...newFiles])
      }
    }
  }

  const removeFile = (index: number) => {
    const filesCopy = [...sortedFiles]

    if (filesCopy[index].file instanceof File) {
      filesCopy.splice(index, 1)
    } else {
      filesCopy[index] = {
        ...filesCopy[index],
        state: 'delete',
      }
    }

    onFileChange(filesCopy)
  }

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <input
          ref={fileUploadRef}
          type="file"
          multiple={multiple}
          accept={acceptedTypes}
          hidden
          onChange={onChange}
        ></input>
        <Button variant="outlined" color="primary" onClick={selectFiles}>
          {t('files.add')} {multiple ? t('files.files') : t('files.file')} <UploadIcon />
        </Button>
        <Typography sx={{ color: '#999', ml: 2 }}>{acceptedTypesText}</Typography>
      </Box>
      {error && (
        <FormHelperText sx={{ ml: '14px', color: theme.palette.error.main }}>
          {error}
        </FormHelperText>
      )}
      <Box>
        <List>
          {sortedFiles.map((entry, index) => (
            <Box key={index}>
              {entry.state === 'delete' ? (
                <></>
              ) : (
                <ListItem key={index} disableGutters>
                  <Box component="span" sx={sx.fileItem}>
                    {useLink ? (
                      entry.file instanceof File ? (
                        entry.file.name
                      ) : (
                        <FileLink file={entry.file} />
                      )
                    ) : entry.file instanceof File ? (
                      entry.file.name
                    ) : (
                      entry.file.nosaukums
                    )}
                  </Box>
                  <Box fontSize={15} whiteSpace="unset">
                    {entry.status && (
                      <Box display="flex" alignItems="center">
                        <Box>Statuss:</Box>
                        <Box ml={1}>
                          <DocumentStatusRenderer documentStatus={entry.status} />
                        </Box>
                      </Box>
                    )}
                    {entry.comment && (
                      <Box display="flex">
                        <Box>Komentārs:</Box>
                        <Box ml={1}>{entry.comment}</Box>
                      </Box>
                    )}
                  </Box>
                  {!entry.status && (
                    <Button onClick={() => removeFile(index)}>
                      <DeleteIcon />
                    </Button>
                  )}
                </ListItem>
              )}
            </Box>
          ))}
        </List>
      </Box>
    </Box>
  )
}

function DocumentStatusRenderer({ documentStatus }: { documentStatus: DocumentStatus }) {
  const statusText = React.useMemo(() => {
    switch (documentStatus) {
      case DocumentStatus.NORAIDĪTS:
        return 'Noraidīts '
      case DocumentStatus.APSTIPRINĀTS:
        return 'Apstiprināts'
      default:
        return 'Jauns'
    }
  }, [documentStatus])

  const statusColor = React.useMemo(() => {
    switch (documentStatus) {
      case DocumentStatus.NORAIDĪTS:
        return 'error'
      case DocumentStatus.APSTIPRINĀTS:
        return 'success'
      default:
        return 'default'
    }
  }, [documentStatus])

  return <Chip sx={{ fontWeight: 'bold' }} size="small" label={statusText} color={statusColor} />
}
