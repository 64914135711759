import React from 'react'
import { useTranslation } from 'react-i18next'
import { TransitionProps } from '@mui/material/transitions'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Slide,
  TextField,
  Tooltip,
} from '@mui/material'
import { useMutation } from 'react-query'
import { activatePhoneWithCode, sendActivationCodeToMyPhone } from '../../api/activation/activation'
import { UserPhone } from '../../interfaces'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { usePhoneVerificationDialogState } from '../../store'
import RefreshIcon from '@mui/icons-material/Refresh'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

export function PhoneActivationDialog() {
  const { t } = useTranslation()
  const { isOpen, phoneId, setIsOpen, userRefetchCallback } = usePhoneVerificationDialogState()
  const { showSnackBar } = useSnackBar()
  const [verificationCode, setVerificationCode] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)

  const sendActivationCodeToMyPhoneMutation = useMutation(
    (phoneId: number) => sendActivationCodeToMyPhone(phoneId),
    {
      onSuccess: (response) => {
        if (response) {
          showSnackBar({
            text: t('phone-activation.verification-sent'),
          })
        }
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
      onSettled: () => setVerificationCode(''),
    }
  )

  const activatePhoneWithCodeMutation = useMutation(
    (otp: string) => {
      setIsLoading(true)
      return activatePhoneWithCode(otp)
    },
    {
      onSuccess: (response: UserPhone) => {
        if (response?.ir_verificēts) {
          showSnackBar({
            text: t('phone-activation.verification-successful'),
          })
          setIsOpen(false)
          userRefetchCallback()
        }
        setIsLoading(false)
      },
      onError: (error) => {
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(t('phone-activation.verification-code-error')),
        })
      },
    }
  )

  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
            borderRadius: 2,
          },
        },
      }}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          padding: '0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: '#F4F6F0',
            width: '100%',
            padding: '20px 0px',
            textAlign: 'center',
            fontWeight: 500,
          }}
        >
          {t('phone-activation.verification-code')}
        </Box>
        <Box mt={4} display="flex">
          <TextField
            autoFocus
            placeholder="1234"
            value={verificationCode}
            onChange={({ target: { value } }) => setVerificationCode(value)}
            sx={{
              width: '160px',
            }}
            inputProps={{ style: { textAlign: 'center' } }}
          />
          <Tooltip title={t('phone-activation.verification-retry')}>
            <IconButton
              onClick={() => {
                sendActivationCodeToMyPhoneMutation.mutate(phoneId)
                setVerificationCode('')
              }}
              sx={{ ml: 1 }}
            >
              <RefreshIcon color="primary" />
            </IconButton>
          </Tooltip>
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2, mt: 2 }}>
        <Button
          disabled={!Boolean(verificationCode) || isLoading}
          fullWidth
          variant="outlined"
          onClick={() => activatePhoneWithCodeMutation.mutate(verificationCode)}
        >
          {isLoading ? <CircularProgress size={16} /> : t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
