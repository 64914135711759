import { useTheme } from '@mui/material'
import { Outlet, useLocation } from 'react-router-dom'
import { Footer, Header, HeaderHome, Wrap } from '../../components'
import { breakPoints } from '../../constants'

export const Layout = () => {
  const location = useLocation()
  const theme = useTheme()

  return (
    <>
      <div className="ornament-pattern-gradient-background"></div>
      {location.pathname === '/' ? <HeaderHome /> : <Header />}
      <Wrap
        sx={{
          pt: 6,
          pb: 6,
          minHeight: 'max(calc(-521px + 100vh), 504px)',
          [theme.breakpoints.down(breakPoints.tablet)]: { pt: 3, pb: 3 },
        }}
      >
        <main>
          <Outlet />
        </main>
      </Wrap>
      <Footer />
    </>
  )
}
