import { useState } from 'react'
import { FormControl, MenuItem, Select, SelectChangeEvent, SxProps } from '@mui/material'
import { LANGUAGES } from '../../constants'
import { StorageKey } from '../../enums'
import { useTranslation } from 'react-i18next'

interface Props {
  sx?: SxProps
}

export function LanguageSwitch({
  sx = {
    fontWeight: 600,
    color: '#fff',
    minWidth: '3rem',
    '& .MuiSelect-icon': {
      fill: '#fff',
    },
  },
}: Props) {
  const { i18n } = useTranslation()

  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)

  const onChange = (event: SelectChangeEvent<string>) => {
    localStorage.setItem(StorageKey.Language, event.target.value)
    setSelectedLanguage(event.target.value)
    window.location.reload()
  }

  return (
    <FormControl>
      <Select
        variant="standard"
        disableUnderline
        value={selectedLanguage}
        onChange={onChange}
        sx={sx}
      >
        {LANGUAGES.map((lng) => (
          <MenuItem
            key={lng}
            value={lng}
            style={{
              fontSize: '1rem',
              minHeight: '48px',
            }}
          >
            {lng.toUpperCase()}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
