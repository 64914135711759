import axios from 'axios'
import { BASE_PATH_DISTRO } from '../../constants'

export const getPartner = async (id: number) => {
  return (
    await axios
      .get(`${BASE_PATH_DISTRO}/data/sadarbības_partneru_saraksts`, {
        params: {
          id,
        },
      })
      .then((response) => response.data)
  )?.[0]
}
