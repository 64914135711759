import { useMemo } from 'react'
import { Box, Button, Chip, Link, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { getPartner } from '../../api/partners/getPartner'
import { getPartnerLocations } from '../../api/partners/getPartnerLocations'
import { getPromotionsByPartnerIds } from '../../api/promotions/getPromotionsByPartnerIds'
import { DelayedLoader } from '../../components'
import { BASE_PATH_DISTRO, breakPoints } from '../../constants'
import { LocationResponse } from '../../interfaces/partner/Location'
import { Card as CardType } from '../../interfaces/certificate/Card'
import { useUserDataStore } from '../../store'
import CollapsibleLocation from './CollapsibleLocation'
import ComplaintModal from './ComplaintModal'
import ComplaintResponseModal from './ComplaintResponseModal'
import { addHttpToUrl } from './helpers'
import noLogo from './no-logo.jpeg'
import { StyledRating } from './Rating'
import RatingModal from './RatingModal'
import { usePartnerData } from './usePartnerData'
import { getCertificateList } from '../../api/certificate/getCertificateList'
import { sanitize as sanitizeHtml } from '../../utils'

export const PartnerInfo = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))
  const { id } = useParams()
  const [isRatingModalOpen, setIsRatingModalOpen] = useState<boolean>(false)
  const [isComplaintModalOpen, setIsComplaintModalOpen] = useState<boolean>(false)
  const [isViewComplaintsModalOpen, setIsViewComplaintsModalOpen] = useState<boolean>(false)
  const [certificate, setCertificate] = useState<CardType | null>(null)
  const { partnersWithPromotions } = usePartnerData()
  const cachedPartner = partnersWithPromotions.find((partner) => partner.id === Number(id))
  const { currentUser } = useUserDataStore()

  const {
    data: partnerData,
    isFetching: isLoadingPartner,
    refetch: refetchPartnerData,
  } = useQuery(['partner-by-id-data', id], () => getPartner(Number(id)), {
    initialData: cachedPartner,
  })

  const { data: promotionsData, isFetching: isLoadingPromotions } = useQuery(
    ['promotions-by-partner-id-data', id],
    () => getPromotionsByPartnerIds([Number(id)]),
    {
      initialData: [],
    }
  )

  const { data: locationsData, isFetching: isLoadingLocations } = useQuery(
    ['locations-by-partner-id-data', id],
    () => getPartnerLocations(Number(id)),
    {
      initialData: [],
    }
  )

  const { isFetching: isLoadingCertificateList } = useQuery(
    'get-certificate-list',
    async () => await getCertificateList(),
    {
      onSuccess: (response) => {
        if (response && response.length > 0) {
          setCertificate(response[0])
        }
      },
      enabled: Boolean(currentUser),
      refetchOnWindowFocus: false,
    }
  )

  const allPartnerData = {
    ...partnerData,
    promotions: promotionsData,
    locations: locationsData,
  }

  const sanitizedHTMLDescription = useMemo(
    () => sanitizeHtml(allPartnerData.apraksts || ''),
    [allPartnerData.apraksts]
  )
  return (
    <Box sx={{ overflow: 'hidden' }}>
      {allPartnerData && (
        <>
          <Box
            component="img"
            sx={{ maxHeight: '100px', display: 'block' }}
            src={
              allPartnerData.logotips?.id !== (null || undefined)
                ? `${BASE_PATH_DISTRO}/api/v1/download/${allPartnerData.logotips.id}/${allPartnerData.logotips.sha256}`
                : noLogo
            }
            alt="company-logo"
          />
          <Box mt={2}>
            <Typography display="inline" variant="h1">
              {allPartnerData.nosaukums}
            </Typography>
            {(isLoadingPartner || isLoadingPromotions || isLoadingLocations) && (
              <DelayedLoader sx={{ ml: 1 }} loaderSize={26} />
            )}
          </Box>
          <Box
            display="flex"
            alignItems="center"
            mt={1}
            {...(isTablet && { flexDirection: 'column', alignItems: 'flex-start' })}
          >
            <Box display="flex" alignItems="center">
              <StyledRating value={allPartnerData.vērtējums || 0} readOnly />
              <Typography
                fontSize={18}
                fontWeight="700"
                sx={{ marginLeft: '6px', marginTop: '2px' }}
                color="primary"
              >
                {allPartnerData.vērtējumu_skaits}
              </Typography>
            </Box>
            {isLoadingCertificateList ? (
              <DelayedLoader sx={{ ml: 1 }} loaderSize={26} />
            ) : currentUser && certificate && certificate.tips_kods !== 'BN' ? (
              <Box display="flex" alignItems="center">
                <Button
                  sx={{ marginTop: '6px', marginLeft: '12px' }}
                  onClick={() => setIsRatingModalOpen(true)}
                >
                  {t('rate')}
                </Button>
                <Button
                  sx={{ marginTop: '6px', marginLeft: '12px' }}
                  onClick={() => setIsComplaintModalOpen(true)}
                >
                  {t('file-complaint')}
                </Button>
                <Button
                  sx={{ marginTop: '6px', marginLeft: '12px' }}
                  onClick={() => setIsViewComplaintsModalOpen(true)}
                >
                  {t('view-complaints')}
                </Button>
              </Box>
            ) : null}
          </Box>
          {allPartnerData.pakalpojumu_birkas?.length > 0 && (
            <Box mt={1}>
              {allPartnerData.pakalpojumu_birkas.map(
                ({
                  birka_kods,
                  birka_nosaukums,
                }: {
                  birka_kods: string
                  birka_nosaukums: string
                }) => (
                  <Chip
                    sx={{ borderRadius: 1, mr: 1 }}
                    label={birka_nosaukums}
                    key={birka_kods}
                    color="primary"
                  />
                )
              )}
            </Box>
          )}
          {allPartnerData.promotions.length > 0 && (
            <>
              <Typography mt={2} variant="h6">
                {t('discounts')}
              </Typography>
              {
                // TODO: typing
                allPartnerData.promotions.map((e: any) => (
                  <Typography mt={0.5} key={e.id}>
                    <b>{e.skaits + ' ' + e.mērvienība_nosaukums}</b> {e.apraksts}
                  </Typography>
                ))
              }
            </>
          )}

          {sanitizedHTMLDescription.__html.length > 0 && (
            <>
              <Typography mt={5} variant="h6">
                {t('important-information')}
              </Typography>
              <Box
                sx={{
                  whiteSpace: 'pre-line',
                }}
              >
                <Typography component="span" dangerouslySetInnerHTML={sanitizedHTMLDescription} />
              </Box>
            </>
          )}

          {allPartnerData.mājas_lapa && (
            <>
              <Typography mt={5} variant="h6">
                {t('website')}
              </Typography>
              <Link target="_blank" href={addHttpToUrl(allPartnerData.mājas_lapa)} rel="noreferrer">
                {allPartnerData.mājas_lapa}
              </Link>
            </>
          )}
          {allPartnerData.locations.length > 0 && (
            <Box mt={5}>
              <>
                <Typography mt={5} variant="h6">
                  {t('addresses')}
                </Typography>
                <Box>
                  {allPartnerData.locations.map((location: LocationResponse) => (
                    <CollapsibleLocation key={location.id} {...location} />
                  ))}
                </Box>
              </>
            </Box>
          )}
        </>
      )}
      {isRatingModalOpen && (
        <RatingModal
          onClose={() => setIsRatingModalOpen(false)}
          onSuccess={refetchPartnerData}
          partnerName={allPartnerData.nosaukums}
          partnerId={allPartnerData.id}
        />
      )}
      {isComplaintModalOpen && (
        <ComplaintModal
          onClose={() => setIsComplaintModalOpen(false)}
          partnerName={allPartnerData.nosaukums}
          partnerId={allPartnerData.id}
        />
      )}
      {isViewComplaintsModalOpen && (
        <ComplaintResponseModal
          onClose={() => setIsViewComplaintsModalOpen(false)}
          partnerName={allPartnerData.nosaukums}
          partnerId={allPartnerData.id}
        />
      )}
    </Box>
  )
}
