import axios from 'axios'
import queryString from 'query-string'

export async function fetchAddresses(parameters: { [key: string]: string }) {
  const ADDRESS_ENDPOINT = 'https://amk.viss.gov.lv/rest/address'
  const restParameters = queryString.stringify(parameters)

  const response = await axios.get(`${ADDRESS_ENDPOINT}?${restParameters}`)

  return response.data
}
