import React from 'react'
import { Box, Button, InputAdornment, TextField, Typography, useTheme } from '@mui/material'
import { differenceInYears, format } from 'date-fns'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import SubtitlesIcon from '@mui/icons-material/Subtitles'
import { Trans, useTranslation } from 'react-i18next'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'

import {
  DEFAULT_DATE_FORMAT,
  PHONE_MIN_LENGTH,
  PHONE_MAX_LENGTH,
  INVALID_PHONE_FORMAT,
  breakPoints,
} from '../../constants/constants'
import { FullPageLoader, SubMenu } from '../../components'
import { ChildrenCertificateProps } from '../../interfaces/certificate/ChildrenCertificateProps'
import { getChildCertificateList } from '../../api/certificate/getChildCertificateList'
import { getCertificateList } from '../../api/certificate/getCertificateList'
import { Card } from '../../interfaces/certificate/Card'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { sendActivationCodeToPhone } from '../../api/activation/activation'
import { CardStatus } from '../../enums/CardStatus'
import CardOrderModal from './CardOrderModal'
import { regenerateCertificate } from '../../api/certificate/regenerateCertificate'
import { parseStringToHtml } from '../../utils/parseStringToHtml'

const sx = {
  bold: {
    fontWeight: 700,
  },
  padded: {
    margin: '15px 0px',
  },
  nameBox: {
    display: 'flex',
    marginLeft: '6px',
    flexWrap: 'wrap',
  },
  personName: {
    marginRight: '14px',
  },
  qrText: {
    maxWidth: '400px',
    margin: '10px 6px',
  },
  mainCardsBox: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    margin: '32px 0',
  },
  phoneInputBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  phoneInputSendButton: {
    width: '100%',
  },
  cardRequestButton: {
    marginTop: '8px',
  },
  infoBox: {
    marginLeft: '6px',
  },
  applicationRedirectButton: {
    padding: 0,
    textTransform: 'none',
    marginLeft: '4px',
    fontSize: '1rem',

    ':hover': {
      backgroundColor: 'transparent',
    },
  },
}

export const Cards = () => {
  const { showSnackBar } = useSnackBar()
  const theme = useTheme()
  const [childData, setChildData] = React.useState<ChildrenCertificateProps[]>([])
  const [parentData, setParentData] = React.useState<Card[]>([])
  const [phones, setPhones] = React.useState<Array<string>>([])
  const [phoneErrors, setPhoneErrors] = React.useState<Array<string>>([])
  const [isOrderCardModalOpen, setIsOrderCardModalOpen] = React.useState<boolean>(false)
  const [cardId, setCardId] = React.useState<number | null>(null)
  const { t } = useTranslation()

  const regenerateCertificationMutation = useMutation((id: number) => regenerateCertificate(id), {
    onSuccess: () => {
      showSnackBar({
        text: 'Apliecība atjaunota veiksmīgi. Ja nepieciešamas arī plastikāta apliecība, tad spiediet pogu "Pieprasīt plastikāta apliecību"',
      })
      refetchChildData()
      refetchParentData()
    },
    onError: (error) => {
      showSnackBar({
        text: handleErrorMessages(error),
        severity: 'error',
      })
    },
  })

  const sendActivationCodeToPhoneMutation = useMutation(
    ({ id, phone }: { id: number; phone: string }) => sendActivationCodeToPhone({ id, phone }),
    {
      onSuccess: () =>
        showSnackBar({
          text: 'Sms ir veiksmīgi nosūtīta!',
        }),
      onError: (error) =>
        showSnackBar({
          text: handleErrorMessages(error),
          severity: 'error',
        }),
    }
  )
  const { isLoading: isLoadingParentData, refetch: refetchParentData } = useQuery(
    'get-parent-certificate-list-new',
    async () => {
      return await getCertificateList()
    },
    {
      onSuccess: (data) => {
        setParentData(data)
      },
    }
  )
  const { isLoading: isLoadingChildData, refetch: refetchChildData } = useQuery(
    'get-child-certificate-list',
    async () => {
      return await getChildCertificateList()
    },
    {
      onSuccess: (data) => {
        setChildData(data)
        // init phones & errors
        setPhones(data.map(() => ''))
        setPhoneErrors(data.map(() => ''))
      },
    }
  )

  const handleOrderCard = (card: number) => {
    setIsOrderCardModalOpen(true)
    setCardId(card)
  }

  const handleRegenerateCertificate = (id: number) => {
    regenerateCertificationMutation.mutate(id)
  }

  const handlePhoneChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number
  ) => {
    // reset phone errors
    setPhoneErrors(childData.map(() => ''))

    const phonesClone = [...phones]
    let phoneString = phonesClone[index]
    phoneString = event.target.value.replace(/[^0-9]/gi, '')
    phonesClone[index] = phoneString
    setPhones([...phonesClone])
  }

  const onSendActivationCodeToPhone = (phoneIndex: number) => {
    const phone = phones[phoneIndex]
    if (!phone || phone?.length < PHONE_MIN_LENGTH) {
      setPhoneErrors(
        phoneErrors.map((_, index) => (index === phoneIndex ? INVALID_PHONE_FORMAT : ''))
      )
      return
    }
    if (phone.length > PHONE_MAX_LENGTH) {
      setPhoneErrors(
        phoneErrors.map((_, index) =>
          index === phoneIndex
            ? `Tālruņa numurs pārsniedz maksimālo garumu - ${PHONE_MAX_LENGTH}`
            : ''
        )
      )
      return
    }
    sendActivationCodeToPhoneMutation.mutate({ id: childData[phoneIndex].id, phone })
  }

  return (
    <Box>
      <SubMenu />
      <Box sx={[sx.padded, { textAlign: 'center' }]}>
        <Typography variant="h2" gutterBottom sx={[sx.bold, { marginBottom: '25px' }]}>
          {t('profile.applications')}
        </Typography>
      </Box>
      {isLoadingParentData || isLoadingChildData ? (
        <FullPageLoader />
      ) : parentData.length || childData.length ? (
        <>
          <Box sx={{ margin: '0 18px' }}>
            {parentData.map((parent) => {
              return (
                <Box
                  key={parent.id}
                  sx={[
                    sx.mainCardsBox,
                    { [theme.breakpoints.down(breakPoints.tablet)]: { margin: '50px 0' } },
                  ]}
                >
                  <Box>
                    <Box sx={sx.nameBox}>
                      <Typography gutterBottom variant="h6" sx={[sx.bold, sx.personName]}>
                        {parent.vārds} {parent.uzvārds}
                      </Typography>
                      <Typography variant="h6" sx={sx.bold}>
                        {parent.personas_kods}
                      </Typography>
                    </Box>
                    <Box ml="6px">
                      <Typography variant="body2" gutterBottom>
                        {t('inquiry-contact-info.card-application-number')}: {parent.numurs}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {t('inquiry-contact-info.card-application-date')}:{' '}
                        {format(new Date(parent.derīguma_termiņš), DEFAULT_DATE_FORMAT)}
                      </Typography>
                      {parent.aktivizācijas_kods ? (
                        <Typography variant="body2" gutterBottom>
                          {t('inquiry-contact-info.card-application-code')}:{' '}
                          <b>{parent.aktivizācijas_kods}</b>
                        </Typography>
                      ) : null}
                    </Box>

                    <Box display="flex" flexDirection="column">
                      <Box display="flex" alignItems="center">
                        <QrCode2Icon sx={{ fontSize: 40 }} />
                        <Box sx={sx.qrText}>
                          <Typography variant="body2">
                            {t('inquiry-contact-info.parent-application-created')}
                          </Typography>
                        </Box>
                      </Box>
                      {parent.statuss_kods === CardStatus.PL_IZS ? (
                        <Box>
                          <Button
                            sx={sx.cardRequestButton}
                            variant="outlined"
                            onClick={() => handleRegenerateCertificate(parent.id)}
                          >
                            {t('inquiry-contact-info.card-application-renew')}
                          </Button>
                          <Typography variant="subtitle2" sx={{ padding: '10px 0px' }}>
                            {t('application.renew-application-warning')}
                          </Typography>
                        </Box>
                      ) : null}
                      <Box display="flex" alignItems="flex-end">
                        <SubtitlesIcon sx={{ fontSize: 40 }} />
                        <Box sx={[sx.qrText, { marginTop: '20px' }]}>
                          <Typography variant="body2">
                            {t('inquiry-contact-info.card-application-statuss')}:{' '}
                            <b>{parent.statuss_nosaukums}</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={sx.infoBox}>
                      {parent.statuss_kods === CardStatus.PL_NEP ? (
                        <Button
                          sx={sx.cardRequestButton}
                          variant="outlined"
                          onClick={() => {
                            handleOrderCard(parent.id)
                          }}
                        >
                          {t('inquiry-contact-info.card-application-request')}
                        </Button>
                      ) : null}
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Box>
          <Box sx={{ margin: '0 18px' }}>
            {childData.map((child, index) => {
              const childAge = differenceInYears(new Date(), new Date(child.dzimšanas_datums))
              const isChildValidForCard = childAge >= 6
              return (
                <Box
                  key={child.id}
                  sx={[
                    sx.mainCardsBox,
                    { [theme.breakpoints.down(breakPoints.tablet)]: { margin: '50px 0' } },
                  ]}
                >
                  <Box>
                    <Box sx={sx.nameBox}>
                      <Typography gutterBottom variant="h6" sx={[sx.bold, sx.personName]}>
                        {child.vārds} {child.uzvārds}
                      </Typography>
                      <Typography variant="h6" sx={sx.bold}>
                        {child.personas_kods}
                      </Typography>
                    </Box>
                    <Box ml="6px">
                      <Typography variant="body2" gutterBottom>
                        {t('inquiry-contact-info.card-application-number')}: {child.numurs}
                      </Typography>
                      <Typography variant="body2" gutterBottom>
                        {t('inquiry-contact-info.card-application-date')}:{' '}
                        {format(new Date(child.derīguma_termiņš), DEFAULT_DATE_FORMAT)}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Box display="flex">
                        <QrCode2Icon
                          sx={{
                            fontSize: 40,
                          }}
                        />
                        <Box>
                          <Typography ml={1} variant="body2">
                            {t('inquiry-contact-info.child-application-created')}
                          </Typography>
                          <ul style={{ margin: 0 }}>
                            <li>
                              {parseStringToHtml(t('inquiry-contact-info.app-must-be-installed'))}
                            </li>
                            <li>
                              {t('inquiry-contact-info.enter-card-application-code')}:{' '}
                              <b>{child.aktivizācijas_kods}</b>
                            </li>
                          </ul>
                        </Box>
                      </Box>
                      {child.statuss_kods === CardStatus.PL_IZS ? (
                        <Box>
                          <Button
                            sx={sx.cardRequestButton}
                            variant="outlined"
                            onClick={() => handleRegenerateCertificate(child.id)}
                          >
                            {t('inquiry-contact-info.card-application-renew')}
                          </Button>
                          <Typography variant="subtitle2" sx={{ padding: '10px 0px' }}>
                            {t('application.renew-application-warning')}
                          </Typography>
                        </Box>
                      ) : null}
                      <Box display="flex" alignItems="flex-end">
                        <SubtitlesIcon sx={{ fontSize: 40 }} />
                        <Box sx={[sx.qrText, { marginTop: '20px' }]}>
                          <Typography variant="body2">
                            {t('inquiry-contact-info.card-application-statuss')}:{' '}
                            <b>{child.statuss_nosaukums}</b>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={sx.infoBox}>
                      {child.statuss_kods === CardStatus.PL_NEP && isChildValidForCard ? (
                        <Button
                          sx={sx.cardRequestButton}
                          variant="outlined"
                          onClick={() => handleOrderCard(child.id)}
                        >
                          {t('inquiry-contact-info.card-application-request')}
                        </Button>
                      ) : null}
                    </Box>
                  </Box>

                  <Box
                    sx={[
                      sx.phoneInputBox,
                      { [theme.breakpoints.down(breakPoints.tablet)]: { marginTop: '20px' } },
                    ]}
                  >
                    <Typography sx={sx.bold}>{t('inquiry-contact-info.child-phone')}</Typography>
                    <Box>
                      <TextField
                        value={phones[index]}
                        onChange={(ev) => handlePhoneChange(ev, index)}
                        placeholder="37100000000"
                        sx={{
                          minWidth: '320px',
                          backgroundColor: '#f7f7f7',
                        }}
                        InputProps={{
                          startAdornment: <InputAdornment position="start">+</InputAdornment>,
                        }}
                        helperText={phoneErrors[index]}
                        error={Boolean(phoneErrors[index])}
                        disabled={sendActivationCodeToPhoneMutation.isLoading}
                      />
                    </Box>
                    <Typography
                      variant="caption"
                      sx={{ maxWidth: '320px', marginTop: '4px' }}
                      gutterBottom
                    >
                      {t('inquiry-contact-info.child-phone-verification')}
                    </Typography>
                    <Box>
                      <Button
                        disabled={sendActivationCodeToPhoneMutation.isLoading}
                        onClick={() => onSendActivationCodeToPhone(index)}
                        sx={sx.phoneInputSendButton}
                        variant="outlined"
                      >
                        {t('inquiry-contact-info.child-phone-code-sms')}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )
            })}
          </Box>
        </>
      ) : (
        <Trans
          i18nKey="inquiry-contact-info.no-applications"
          components={{
            inquiriesSection: (
              <Link to="/inquiries" style={{ color: theme.palette.primary.main }} />
            ),
          }}
        />
      )}
      <CardOrderModal
        isOpen={isOrderCardModalOpen}
        onClose={() => setIsOrderCardModalOpen(false)}
        cardNumber={cardId}
        refetchParentData={refetchParentData}
        refetchChildData={refetchChildData}
      />
    </Box>
  )
}
