import { CSSProperties, useState, SyntheticEvent } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  SxProps,
  Typography,
  useTheme,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

import AddressDropDownList from '../../components/AddressAutoComplete/AddressDropDownList'
import { DeliveryOption } from '../../interfaces/common/DeliveryOption'
import { LegalAddress } from '../../interfaces/common/LegalAddress'
import { updateCardDeliveryMethod } from '../../api/certificate/updateCardDeliveryMethod'
import { applyForPlasticCards } from '../../api/certificate/applyForPlasticCards'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { useQuery } from 'react-query'
import { getInquiryList } from '../../api/inquiry/getInquiryList'
import { getInquiry } from '../../api/inquiry/getInquiry'
import { InquiryResponse } from '../../interfaces/inquiry/InquiryResponse'

interface Props {
  isOpen: boolean
  onClose: () => void
  cardNumber: number | null
  refetchParentData: () => void
  refetchChildData: () => void
}

const sx: { [key: string]: CSSProperties | SxProps } = {
  closeIcon: {
    cursor: 'pointer',
  },
  closeIconBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  submitButton: {
    marginBottom: '10px',
    marginRight: '12px',
  },
}

const CardOrderModal: React.FC<Props> = ({
  isOpen,
  onClose,
  cardNumber,
  refetchParentData,
  refetchChildData,
}) => {
  const theme = useTheme()
  const { showSnackBar } = useSnackBar()
  const [deliveryAddress, setDeliveryAddress] = useState<LegalAddress>()
  const [deliveryOption, setDeliveryOption] = useState<DeliveryOption>(DeliveryOption.Delivery)
  const [addressError, setAddressError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [inquiryList, setInquiryList] = useState<InquiryResponse[] | null>(null)
  const [inquiry, setInquiry] = useState<InquiryResponse | null>(null)

  useQuery(
    'get-inquiry-list',
    async () => {
      return await getInquiryList()
    },
    {
      onSuccess: (response) => {
        setInquiryList(response)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  useQuery(
    ['get-inquiry-list', inquiryList],
    async () => {
      return await getInquiry({
        id: inquiryList ? inquiryList[0].id.toString() : '',
      })
    },
    {
      enabled: inquiryList ? inquiryList.length > 0 : false,
      onSuccess: (response) => {
        setInquiry(response)
      },
      onError: (error) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onDeliveryOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryOption(event.target.value as DeliveryOption)
    setAddressError('')
  }

  const onAddressChange = (event: SyntheticEvent<Element, Event>, newValue: LegalAddress) => {
    if (newValue) {
      setDeliveryAddress(newValue)
      setAddressError('')
      setDeliveryOption(DeliveryOption.Delivery)
    }
  }

  const onSubmit = () => {
    if (deliveryOption === DeliveryOption.Delivery && !deliveryAddress) {
      setAddressError('Piegādes adrese nav norādīta')
      return
    }

    if (deliveryOption === DeliveryOption.Delivery && !deliveryAddress?.pareiza_adrese) {
      setAddressError('Nepieciešams norādīt pilnu piegādes adresi')
      return
    }

    setIsLoading(true)

    updateCardDeliveryMethod({
      adrese: deliveryAddress || null,
      izsniegt_birojā: deliveryOption === DeliveryOption.Pickup,
      id: inquiry?.id!,
    })
      .then(() =>
        applyForPlasticCards({ id: cardNumber as number })
          .then(() => {
            showSnackBar({ text: 'Plastikāta apliecība veiksmīgi pieprasīta!' })
            refetchParentData()
            refetchChildData()
            onClose()
          })
          .catch((error) =>
            showSnackBar({
              severity: 'error',
              text: handleErrorMessages(error),
            })
          )
      )
      .catch((error) =>
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      )
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Box sx={sx.closeIconBox}>
          <CloseIcon sx={sx.closeIcon} onClick={onClose} />
        </Box>
        <Typography sx={{ mt: 1 }}>
          Lūdzu atzīmējiet, kur vēlaties saņemt plastikāta karti:
        </Typography>
        <RadioGroup onChange={onDeliveryOptionChange} value={deliveryOption} sx={{ mt: 2 }}>
          <FormControlLabel
            sx={{
              '.MuiFormControlLabel-label': {
                width: '100%',
              },
            }}
            value={DeliveryOption.Delivery}
            control={<Radio sx={{ marginTop: '22px' }} />}
            label={
              <>
                Uz norādīto adresi
                <AddressDropDownList
                  value={deliveryAddress}
                  error={Boolean(addressError)}
                  onValueChange={(event, newValue) => onAddressChange(event, newValue)}
                />
              </>
            }
          />
          {addressError && (
            <FormHelperText sx={{ color: theme.palette.error.main }}>{addressError}</FormHelperText>
          )}
          <FormControlLabel
            sx={{ mt: 2 }}
            value={DeliveryOption.Pickup}
            control={<Radio />}
            label="Sabiedrības integrācijas fonda birojā, kurš atrodas Raiņa bulvārī 15, Rīgā"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button sx={sx.submitButton} variant="outlined" onClick={onSubmit}>
          {isLoading ? <CircularProgress color="inherit" size={25} /> : 'Saglabāt'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CardOrderModal
