import axios from 'axios'
import { NewsItem } from '../../interfaces'
import { getBaseUrl } from '../../utils'

// TODO: Check if sorting by `publishing_longterm` can be done in the BE (Drupal)
export const getTop4NewsList = async (lng: string) => {
  const baseUrl = getBaseUrl(lng)

  const responseHomePage = await axios.get(
    `${baseUrl}/api/v1/news?publish_place=home_page&baner_parameter=top_news`
  )
  const responseHomePageApp = await axios.get(
    `${baseUrl}/api/v1/news?publish_place=home_page_mobile_app&baner_parameter=top_news`
  )

  const topNews: NewsItem[] =
    Array.isArray(responseHomePage.data) && Array.isArray(responseHomePageApp.data)
      ? [...responseHomePage.data, ...responseHomePageApp.data]
      : []

  const orderedTopNewsByLongTermThenPublishDate = topNews.sort((news1, news2) => {
    return (
      // a workaround to convert publishing_longterm type from string too boolean
      Number(news2.publishing_longterm === 'true') - Number(news1.publishing_longterm === 'true') ||
      new Date(news2.last_published_at).getTime() - new Date(news1.last_published_at).getTime()
    )
  })

  return orderedTopNewsByLongTermThenPublishDate.slice(0, 4)
}
