import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import {
  Box,
  Button,
  CircularProgress,
  CssBaseline,
  Grid,
  TextField,
  Theme,
  Typography,
  useTheme,
} from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { LogoIcon } from '../../icons'
import VpsButton from '../../components/VpsButton/VpsButton'
import { login } from '../../api/login/login'
import { useEmailVerificationAlertState, useUserDataStore } from '../../store'
import { CurrentUser } from '../../interfaces'
import { getConfig } from '../../api/common/getConfig'
import { hasNotConfirmedEmail } from '../../utils'

const sx = {
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    maxWidth: '150px',
    textAlign: 'center',
    lineHeight: 'initial',
  },
  signIn: {
    marginTop: 1,
    padding: '10px 0',
    minHeight: '45px',
    minWidth: '100px',
  },
}

interface ApplicationForCertificate {
  healthDataProcessingTerms: boolean
  pathFrom: string
}

export const LoginVPS = () => {
  const theme = useTheme() as Theme
  const location = useLocation()
  const { setIsOpen: setIsOpenEmailVerificationDialog } = useEmailVerificationAlertState()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { setCurrentUser } = useUserDataStore()
  const { data: configData } = useQuery('config-data', getConfig)

  const state = location.state as ApplicationForCertificate
  const [personCode, setPersonCode] = React.useState('')

  const loginMutation = useMutation((personCode: string) => login(personCode), {
    onSuccess: async (resp: CurrentUser) => {
      if (resp) {
        const returnUrl = searchParams.get('returnUrl')
        setCurrentUser(resp)
        setIsOpenEmailVerificationDialog(await hasNotConfirmedEmail(resp.id))
        if (returnUrl) {
          navigate(returnUrl)
        } else if (state?.hasOwnProperty('healthDataProcessingTerms')) {
          navigate('/inquiry-contact-info')
        } else if (state?.hasOwnProperty('pathFrom')) {
          navigate(state.pathFrom)
        } else {
          navigate('/')
        }
      }
    },
  })

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    loginMutation.mutate(personCode)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setPersonCode(value)
  }

  return (
    <Grid container spacing={0} direction="column">
      <CssBaseline />
      <Grid item>
        <Box sx={sx.paper}>
          <LogoIcon
            style={{
              width: 155,
              height: 155,
              fill: theme.palette.primary.main,
            }}
          />
          <Typography component="h1" variant="h6" color="primary" sx={sx.title}>
            Latvijas Goda ģimene
          </Typography>
          {configData?.test ? (
            <form onSubmit={onSubmit} noValidate>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Personas kods"
                autoFocus
                value={personCode}
                onChange={handleChange}
              />
              <Button fullWidth variant="contained" color="primary" type="submit" sx={sx.signIn}>
                {loginMutation.isLoading ? (
                  <CircularProgress color="inherit" size={18} />
                ) : (
                  'Pieslēgties'
                )}
              </Button>
            </form>
          ) : (
            <VpsButton />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}
