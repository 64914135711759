import { getUser } from "../api/user/getUser"
import { User } from "../interfaces"

export const hasNotConfirmedEmail = async (userId: number) => {
  const userInfo: User = await getUser({ id: userId })

  const email = userInfo.epasti.filter((email) => email.epasts)?.[0]
  const isNotEmpty = Boolean(email && email.epasts.length > 0)
  const notConfirmed = !Boolean(email?.ir_verificēts)

  return isNotEmpty && notConfirmed
}