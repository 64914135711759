import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'
import { LegalAddress } from '../../interfaces/common/LegalAddress'

interface Data {
  adrese: LegalAddress | null
  izsniegt_birojā: boolean
  id: number
}

export const updateCardDeliveryMethod = async ({ adrese, izsniegt_birojā, id }: Data) => {
  const response = await axios.put(`${BASE_PATH_PORTAL}/data/atjaunot_kartes_izsniegšanas_datus/${id}`, {
    id: id,
    adrese: adrese,
    izsniegt_birojā: izsniegt_birojā,
  })
  return response.data
}
