import React, { useEffect, useRef, useState } from 'react'
import { CircularProgress, SxProps } from '@mui/material'

interface DelayedLoaderProps {
  delay?: number
  loaderSize?: number
  sx?: SxProps
}

export const DelayedLoader = ({ delay = 300, loaderSize, sx }: DelayedLoaderProps) => {
  const timeoutId = useRef(0)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (timeoutId.current) {
      setIsVisible(false)
      clearTimeout(timeoutId.current)
    }

    timeoutId.current = window.setTimeout(() => {
      setIsVisible(true)
    }, delay)

    return () => {
      clearTimeout(timeoutId.current)
    }
  }, [delay])

  return (
    <CircularProgress
      sx={{ visibility: isVisible ? 'visible' : 'hidden', ...sx }}
      size={loaderSize}
    />
  )
}
