import { useSearchParams } from 'react-router-dom'

/**
 * `useFilterUrl` is a custom hook that enables to manage URL filters
 * and preserve their values while navigating between pages.
 **/
export const useFilterUrl = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const getFilter = (key: string) => {
    return searchParams.get(key) || ''
  }

  const setFilters = (filters: Record<string, string | null>) => {
    Object.entries(filters).forEach(([key, value]) => {
      if (value) {
        searchParams.set(key, value)
      } else {
        searchParams.delete(key)
      }
    })
    setSearchParams(searchParams, { replace: true })
  }

  return { getFilter, setFilters }
}
