import { format, parse } from 'date-fns'
import lv from 'date-fns/locale/lv'

const getLocalizedMonth = (date: Date) => {
  return format(date, 'LLLL', { locale: lv })
}

export const getLocalizedDate = (date: string) => {
  let parsedDate = undefined

  const match = date?.match(/\d{0,2}\/\d{0,2}\/\d{0,4}/)

  if (match) {
    if (match[0]) {
      parsedDate = parse(date, 'E, MM/dd/yyyy - HH:mm', new Date())
    } else {
      parsedDate = parse(date, 'dd.MM.yyyy.', new Date())
    }
    return `${format(parsedDate, 'dd')}. ${getLocalizedMonth(parsedDate)} ${format(
      parsedDate,
      'yyyy'
    )}`
  } else {
    return ''
  }
}
