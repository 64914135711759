import { useLocation, useNavigate } from 'react-router-dom'
import { format, parseISO } from 'date-fns'
import { Box, Button, Divider, List, ListItem } from '@mui/material'
import { DEFAULT_DATE_FORMAT } from '../../constants/constants'
import { Notice } from '../../interfaces/notice'
import { FileLink } from '../../components/FileLink/FileLink'
import { SubMenu } from '../../components'
import { useTranslation } from 'react-i18next'

interface NoticeProps {
  notice: Notice
}

export const NoticeDetails = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const state = location.state as NoticeProps
  const { t } = useTranslation()

  return (
    <>
      <SubMenu />
      {state?.notice && (
        <Box>
          <span style={{ fontSize: '22px', fontWeight: 700 }}>{state.notice?.nosaukums}</span>
          <Box sx={{ marginTop: '12px', whiteSpace: 'pre-line' }}>
            {state.notice?.saturs}
            <Box sx={{ textAlign: 'right' }}>
              {t('received')}: {format(parseISO(state.notice?.laiks || ''), DEFAULT_DATE_FORMAT)}
            </Box>
          </Box>
          <Divider sx={{ borderWidth: '1px', marginTop: '18px' }} />
          <Box sx={{ marginTop: '12px' }}>
            <span style={{ fontSize: '16px', fontWeight: 500 }}>{t('attachments')}: </span>
            {
              <List disablePadding>
                {state.notice?.pielikumi?.map((file) => (
                  <ListItem key={file.faila_id} disableGutters>
                    <FileLink file={file} />
                  </ListItem>
                ))}
              </List>
            }
          </Box>
          <Box sx={{ marginTop: 10 }}>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              Atpakaļ
            </Button>
          </Box>
        </Box>
      )}
    </>
  )
}
