import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'
import { RegisterCheckDocument } from '../../interfaces/file/RegisterCheckDocument'

export const changeRegisterCheckDocument = async (document: RegisterCheckDocument) => {
  const url = document.id
    ? `${BASE_PATH_PORTAL}/data/personas_pārbaude_dokuments/${document.id}`
    : `${BASE_PATH_PORTAL}/data/personas_pārbaude_dokuments`

  const response = document.id ? await axios.put(url, document) : await axios.post(url, document)

  return response.data
}
