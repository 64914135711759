import React from 'react'
import { useQuery } from 'react-query'
import { getPartnersList, getPartnersTotal } from '../../api/partners'
import { getPromotionsByPartnerIds } from '../../api/promotions/getPromotionsByPartnerIds'
import { useFilterUrl } from '../../hooks'
import useIsMount from '../../hooks/useIsMount'
import { ApiParams } from '../../interfaces'
import { Partner } from '../../interfaces/partner/Partner'
import { Promotion } from '../../interfaces/promotions/Promotion'
import { reshapePartnersWithDiscountsData } from './helpers'

const defaultParams = {
  limit: 9,
  offset: 0,
  selectedCategory: null,
  selectedSector: null,
  searchText: '',
  location: '',
}

export const usePartnerData = () => {
  const { setFilters, getFilter } = useFilterUrl()
  const isMount = useIsMount()
  const getParamsFromUrl = () => {
    const category = getFilter('selectedCategory')
    const sector = getFilter('selectedSector')
    const searchText = getFilter('searchText')
    const offset = getFilter('offset')
    return {
      ...defaultParams,
      selectedCategory: category || null,
      selectedSector: sector || null,
      searchText: searchText || '',
      offset: Number(offset),
    }
  }
  const [params, setParams] = React.useState<Required<ApiParams>>(() => getParamsFromUrl())
  const { data: totalData, isFetching: isFtechingPartnersTotal } = useQuery<number>(
    ['partners-count', params],
    () =>
      getPartnersTotal({
        limit: params.limit,
        offset: params.offset,
        pakalpojumu_birkas_kods: params.selectedSector,
        pakalpojumu_grupas_kods: params.selectedCategory,
        atslēgvārdi: params.searchText || null,
      }),
    {
      initialData: 0,
      keepPreviousData: true,
    }
  )
  const { data: partnersData, isFetching: isFetchingPartners } = useQuery<Partner[]>(
    ['partners-data', params],
    () =>
      getPartnersList({
        limit: params.limit,
        offset: params.offset,
        pakalpojumu_birkas_kods: params.selectedSector,
        pakalpojumu_grupas_kods: params.selectedCategory,
        atslēgvārdi: params.searchText || null,
      }),
    {
      initialData: [],
      keepPreviousData: true,
    }
  )
  const { data: promotionsData, isFetching: isFetchingPromotions } = useQuery<Promotion[]>(
    ['promotions-data', partnersData],
    () => getPromotionsByPartnerIds(partnersData?.map((partner) => partner.id) || []),
    {
      initialData: [],
      enabled: (partnersData?.length || 0) > 0,
      keepPreviousData: true,
    }
  )
  const loading = isFetchingPartners || isFtechingPartnersTotal || isFetchingPromotions

  // reset pagination effect, whenever a filter gets changed => first page should be selected
  React.useEffect(() => {
    if (!isMount)
      setParams((prevParams) => ({
        ...prevParams,
        offset: 0,
      }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.selectedSector, params.selectedCategory, params.location, params.searchText])

  return {
    total: totalData || 0,
    params,
    setParams: (newParams: ApiParams) => {
      const updatedParams = { ...params, ...newParams }
      setParams(updatedParams)
      setFilters({
        selectedCategory: updatedParams.selectedCategory,
        selectedSector: updatedParams.selectedSector,
        searchText: updatedParams.searchText,
        offset: updatedParams.offset.toString(),
      })
    },
    loading,
    partnersWithPromotions: reshapePartnersWithDiscountsData(
      defaultParams,
      params,
      partnersData || [],
      promotionsData || []
    ),
  }
}
