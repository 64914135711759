import { Box, Button, Grid, SxProps, Typography, useMediaQuery, useTheme } from '@mui/material'
import supportIcon from '../../icons/svg/supportIcon.svg'
import offersIcon from '../../icons/svg/offersIcon.svg'
import infoIcon from '../../icons/svg/infoIcon.svg'
import { Link } from 'react-router-dom'
import { useAppContextStore } from '../../store'
import { useTranslation } from 'react-i18next'
import { breakPoints } from '../../constants'

interface OffersProps {
  sx?: SxProps
}

export const Offers = ({ sx }: OffersProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const explanatoryTexts = useAppContextStore((state) => state.explanatoryTexts)
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))

  const items = [
    {
      title: explanatoryTexts['gov-support-title'],
      description: explanatoryTexts['gov-support-subtitle'],
      icon: supportIcon,
      to: '/gov-support',
    },
    {
      title: explanatoryTexts['company-offers-title'],
      description: explanatoryTexts['company-offers-subtitle'],
      icon: offersIcon,
      to: '/company-offers',
    },
    {
      title: explanatoryTexts['useful-tips-title'],
      description: explanatoryTexts['useful-tips-subtitle'],
      icon: infoIcon,
      to: '/useful-tips',
    },
  ]

  return (
    <Grid container sx={sx}>
      {items.map((item, index) => (
        <Grid key={index} item xs={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="center"
            sx={{ height: '100%' }}
          >
            <img src={item.icon} width="100px" height="auto" alt="" />
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-between"
              sx={{ height: '100%' }}
            >
              <Typography variant={isTablet ? 'body1' : 'h6'}>{item.title}</Typography>
              <Typography
                sx={{ [theme.breakpoints.down(breakPoints.tablet)]: { display: 'none' } }}
                variant="subtitle2"
              >
                {item.description}
              </Typography>
              <Button component={Link} to={item.to} variant="outlined" sx={{ mt: 2 }}>
                {t('open')}
              </Button>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}
