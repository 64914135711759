import { Box, useMediaQuery, useTheme } from '@mui/material'
import { breakPoints, VPS_AUTH_CALLBACK_PATH } from '../../constants'
import { LogoIconWhiteWithText } from '../../icons'
import { Wrap } from '../Wrap'
import { RouteBar } from '../RouteBar'
import { SocialBar } from '../SocialBar'
import { Link, useLocation } from 'react-router-dom'

export const Header = () => {
  const theme = useTheme()
  const location = useLocation()
  const isMedium = useMediaQuery(theme.breakpoints.down(breakPoints.laptop))

  return (
    <header className={'ornament-pattern-background-red ' + (location.pathname === VPS_AUTH_CALLBACK_PATH ? 'hide-children' : '')}>
      <Wrap>
        {isMedium ? (
          <Box display="flex" alignItems="center">
            <Link to="/">
              <LogoIconWhiteWithText />
            </Link>
            <RouteBar />
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="flex-end">
            <SocialBar />
            <Box display="flex" width="100%">
              <Link to="/">
                <LogoIconWhiteWithText />
              </Link>
              <RouteBar />
            </Box>
          </Box>
        )}
      </Wrap>
    </header>
  )
}
