import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

export const getCurrentCardByInfo = async () => {
  const cardsResponse = await axios.get(`${BASE_PATH_PORTAL}/data/apliecību_saraksts`)
  if (cardsResponse.status === 200 && cardsResponse?.data?.[0]) {
    const currentCard = cardsResponse.data[0]
    const currentCardInfoResponse = await axios.get(`${BASE_PATH_PORTAL}/data/apliecība/${currentCard.id}`)
    if (currentCardInfoResponse.status === 200) {
      return currentCardInfoResponse.data
    }
    throw currentCardInfoResponse.data
  }
  throw new Error('Sertifikāts nav atrasts')
}
