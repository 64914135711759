import { Box, useMediaQuery, useTheme } from '@mui/material'
import { breakPoints, routes } from '../../constants'
import cardIcon from '../../icons/png/cards.png'
import { MainLogo } from '../MainLogo'
import { SifLogo } from '../SifLogo'
import { SocialBar } from '../SocialBar'
import styles from './Header.module.css'
import { Header } from './Header'
import { Wrap } from '../Wrap'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export const HeaderHome = () => {
  const theme = useTheme()
  const { t } = useTranslation()

  const isMedium = useMediaQuery(theme.breakpoints.down(breakPoints.laptop))

  return (
    <>
      {isMedium ? (
        <Header />
      ) : (
        <Wrap>
          <Box component="header" sx={{ display: 'flex', position: 'relative', mb: '6rem' }}>
            <MainLogo />
            <Box sx={{ display: 'flex', ml: 'auto', color: '#FFF' }}>
              <SifLogo />
              <Box sx={{ ml: 2 }}>
                <SocialBar />
                <Box display="flex" alignItems="center" mt={2}>
                  {routes.map((route, index) => (
                    <Box key={index} ml={2} mr={2}>
                      <Link
                        to={route.link}
                        style={{ fontWeight: 500, maxWidth: '105px', minWidth: 'unset' }}
                        className="Header-Footer-Nav-Link"
                      >
                        {t(route.title)}
                      </Link>
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
            <Box className={styles.circle}>
              <Box component="img" className={styles.cards} src={cardIcon} />
            </Box>
          </Box>
        </Wrap>
      )}
    </>
  )
}
