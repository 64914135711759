import { Box, Button, styled, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Partner as PartnerData } from '../../interfaces/partner/Partner'
import { StyledRating } from './Rating'
import noLogo from './no-logo.jpeg'
import { BASE_PATH_DISTRO } from '../../constants'

const StyledContent = styled(Box)(({ theme }) => ({
  marginTop: 8,
  height: 160,
  overflow: 'hidden',
  position: 'relative',
  ':before': {
    content: '""',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    background: `linear-gradient(transparent 90px, ${theme.palette.common.white})`,
  },
}))

export const Partner: React.FC<{ partnerData: PartnerData }> = ({ partnerData }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <Box
      sx={{
        height: 280,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box>
        <Box
          component="img"
          sx={{ height: 46, maxWidth: '100%' }}
          src={
            partnerData.logotips?.id !== (null || undefined)
              ? `${BASE_PATH_DISTRO}/api/v1/download/${partnerData.logotips.id}/${partnerData.logotips.sha256}`
              : noLogo
          }
          alt="company-logo"
        />
        <StyledContent>
          <Typography fontSize={18} fontWeight="bold">
            {partnerData.nosaukums}
          </Typography>
          <Box display="flex" alignItems="center">
            <StyledRating value={partnerData.vērtējums} readOnly />
            <Typography
              fontSize={18}
              fontWeight="700"
              sx={{ marginLeft: '6px', marginTop: '2px' }}
              color="primary"
            >
              {partnerData.vērtējumu_skaits}
            </Typography>
          </Box>
          {partnerData.promotions.map((promotion) => {
            return (
              <Typography mt={0.5} key={promotion.id} sx={{ wordBreak: 'break-word' }}>
                <Typography component="span" fontWeight="bold" display="inline">
                  {promotion.skaits + ' ' + promotion.mērvienība_nosaukums}
                </Typography>{' '}
                {promotion.apraksts}
              </Typography>
            )
          })}
        </StyledContent>
      </Box>
      <Button
        variant="outlined"
        fullWidth
        sx={{ py: '10px' }}
        onClick={() => navigate(`/discounts/${partnerData.id}`)}
      >
        {t('read-more')}
      </Button>
    </Box>
  )
}
