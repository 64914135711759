import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  Radio,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { applyForPlasticCards } from '../../api/certificate/applyForPlasticCards'
import { getCertificateList } from '../../api/certificate/getCertificateList'
import { getChildCertificateList } from '../../api/certificate/getChildCertificateList'
import { updateCardDeliveryMethod } from '../../api/certificate/updateCardDeliveryMethod'
import { FullPageLoader, SubMenu } from '../../components'
import AddressDropDownList from '../../components/AddressAutoComplete/AddressDropDownList'
import { CardStatus } from '../../enums/CardStatus'
import { Card } from '../../interfaces/certificate/Card'
import { CardChild } from '../../interfaces/certificate/CardChild'
import { PlasticCardOrderRequest } from '../../interfaces/certificate/PlasticCardOrderRequest'
import { DeliveryOption } from '../../interfaces/common/DeliveryOption'
import { LegalAddress } from '../../interfaces/common/LegalAddress'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { getChildAge } from '../../utils/calculateChildAge'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

export const InquiryPlasticCards = () => {
  const navigate = useNavigate()
  let { id } = useParams()
  const theme = useTheme()
  const { t } = useTranslation()

  const { showSnackBar } = useSnackBar()

  const [checkedAll, setCheckedAll] = useState(false)
  const [loading, setLoading] = useState(true)
  const [checkedState, setCheckedState] = useState<boolean[]>([])
  const [cardsList, setCardsList] = useState<{ id: number; label: string }[] | null>(null)
  const [deliveryAddress, setDeliveryAddress] = useState<LegalAddress>()
  const [deliveryOption, setDeliveryOption] = useState<DeliveryOption>(DeliveryOption.Delivery)
  const [addressError, setAddressError] = useState('')
  const [cardsError, setCardsError] = useState('')

  useEffect(() => {
    Promise.all([getCertificateList(), getChildCertificateList()]).then(
      ([parentCard, childrenCards]: [Card[], CardChild[]]) => {
        if (!parentCard) {
          throw new Error('No data received about parent')
        }

        if (!childrenCards) {
          throw new Error('No data received about children')
        }

        const cards = [
          ...parentCard.map((parentCard) => ({
            id: parentCard.id,
            label: `${parentCard.vārds} ${parentCard.uzvārds} (vecāks)`,
            birthDate: parentCard.dzimšanas_datums || '',
            wasNotRequested: parentCard.statuss_kods === CardStatus.PL_NEP,
          })),
          ...childrenCards.map((childCard) => ({
            id: childCard.id,
            label: `${childCard.vārds} ${childCard.uzvārds} (bērns)`,
            birthDate: childCard.dzimšanas_datums || '',
            wasNotRequested: childCard.statuss_kods === CardStatus.PL_NEP,
          })),
        ]

        const unrequestedPlasticCards = cards.filter(
          (card) => card.wasNotRequested && getChildAge(card.birthDate) >= 6
        )

        setCardsList(unrequestedPlasticCards)
        setCheckedState(new Array(cards.length).fill(false))
        setLoading(false)
      }
    )
  }, [])

  const onSelectAll = () => {
    setCheckedAll((checked) => !checked)
    setCardsError('')

    if (cardsList) {
      setCheckedState(new Array(cardsList.length).fill(false))
    }
  }

  const onDeliveryOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDeliveryOption(event.target.value as DeliveryOption)
    setAddressError('')
  }

  const onChange = (position: number) => {
    setCheckedState(checkedState.map((item, index) => (index === position ? !item : item)))
    setCardsError('')
  }

  const onAddressChange = (event: SyntheticEvent<Element, Event>, newValue: LegalAddress) => {
    if (newValue) {
      setDeliveryAddress(newValue)
      setAddressError('')
      setDeliveryOption(DeliveryOption.Delivery)
    }
  }

  const onDone = () => {
    if (deliveryOption === DeliveryOption.Delivery && !deliveryAddress) {
      setAddressError('Piegādes adrese nav norādīta')
      return
    }

    if (deliveryOption === DeliveryOption.Delivery && !deliveryAddress?.pareiza_adrese) {
      setAddressError('Nepieciešams norādīt pilnu piegādes adresi')
      return
    }

    let plasticCards: PlasticCardOrderRequest[] = []

    if (cardsList) {
      if (checkedAll) {
        plasticCards = cardsList.map((card) => ({ id: card.id }))
      } else {
        plasticCards = cardsList
          .filter((card, index) => checkedState[index])
          .map((card) => ({ id: card.id }))
      }
    }

    if (plasticCards.length === 0) {
      setCardsError('Nav izvēlēta neviena karte')
      return
    }

    updateCardDeliveryMethod({
      adrese: deliveryAddress || null,
      izsniegt_birojā: deliveryOption === DeliveryOption.Pickup,
      id: parseInt(id!, 10),
    })
      .then(() =>
        Promise.all(plasticCards.map((card) => applyForPlasticCards(card)))
          .then(() => {
            showSnackBar({ severity: 'success', text: 'Izmaiņas saglabātas!' })
            navigate('/inquiry-result')
          })
          .catch((error) =>
            showSnackBar({
              severity: 'error',
              text: handleErrorMessages(error),
            })
          )
          .finally(() => setLoading(false))
      )
      .catch((error) =>
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      )
      .finally(() => setLoading(false))
  }

  return loading ? (
    <FullPageLoader />
  ) : (
    <Box className="body-container">
      <SubMenu />
      <Typography variant="h1" sx={{ fontSize: '1.4rem', textAlign: 'center' }}>
        {t('inquiry-contact-info.plastic-application')}
      </Typography>
      {cardsList && cardsList.length > 0 ? (
        <>
          <List sx={{ mt: 4 }} disablePadding>
            <ListItem>
              <FormControlLabel
                control={<Checkbox checked={checkedAll} onChange={onSelectAll} />}
                label="Visiem"
              />
            </ListItem>
            {cardsList.map((card, index) => (
              <ListItem key={index}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkedState[index] || checkedAll}
                      disabled={checkedAll}
                      onChange={() => onChange(index)}
                    />
                  }
                  label={card.label}
                />
              </ListItem>
            ))}
          </List>
          {cardsError && (
            <FormHelperText sx={{ color: theme.palette.error.main }}>{cardsError}</FormHelperText>
          )}
          <Typography sx={{ mt: 4 }}>
            {t('inquiry-contact-info.plastic-application-receive')}:
          </Typography>
          <RadioGroup onChange={onDeliveryOptionChange} value={deliveryOption} sx={{ mt: 2 }}>
            <FormControlLabel
              sx={{
                '.MuiFormControlLabel-label': {
                  width: '100%',
                },
              }}
              value={DeliveryOption.Delivery}
              control={<Radio sx={{ marginTop: '22px' }} />}
              label={
                <>
                  {t('inquiry-contact-info.plastic-application-receive-address')}
                  <AddressDropDownList
                    value={deliveryAddress}
                    error={Boolean(addressError)}
                    onValueChange={(event, newValue) => onAddressChange(event, newValue)}
                  />
                </>
              }
            />
            {addressError && (
              <FormHelperText sx={{ color: theme.palette.error.main }}>
                {addressError}
              </FormHelperText>
            )}
            <FormControlLabel
              sx={{ mt: 2 }}
              value={DeliveryOption.Pickup}
              control={<Radio />}
              label="Sabiedrības integrācijas fonda birojā, kurš atrodas Raiņa bulvārī 15, Rīgā"
            />
          </RadioGroup>
          <Box sx={{ mt: 4 }}>
            <Button
              variant="text"
              sx={{
                textTransform: 'unset',
                padding: 0,
              }}
              onClick={() => navigate(-1)}
            >
              <u>{t('back')}</u>
            </Button>
            <Button variant="outlined" sx={{ marginLeft: '12px' }} onClick={onDone}>
              {t('save-continue')}
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ textAlign: 'center' }}>
          <Typography mt={4} component="h3">
            {t('inquiry-contact-info.plastic-application-unavailable')}
          </Typography>
          <Box mt={4}>
            <Button variant="outlined" onClick={() => navigate(-1)}>
              {t('back')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  )
}
