import React from 'react'
import {
  Box,
  Divider,
  FormControl,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DelayedLoader } from '../../components'
import { Partner } from './Partner'
import { usePartnerData } from './usePartnerData'
import SearchIcon from '@mui/icons-material/Search'
import CategoriesMenu from './DiscountCategoriesMenu'
import Pagination from './Pagination'
import SectorAutocomplete from '../../components/SectorAutocomplete/SectorAutocomplete'
import { breakPoints } from '../../constants'

export const DiscountCatalog = () => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))
  const { t } = useTranslation()
  const { partnersWithPromotions, loading, params, setParams, total } = usePartnerData()

  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [params.offset, params.selectedCategory])

  return (
    <>
      <Typography display="flex" variant="h1" sx={{ mb: 4 }}>
        {t('menu.discounts')}
        {loading && (
          <Box ml={1}>
            <DelayedLoader loaderSize={26} />
          </Box>
        )}
      </Typography>
      <Box display="grid" gap={isTablet ? 1 : 3} gridTemplateColumns={isTablet ? '1fr' : '3fr 1fr'}>
        <Box>
          <Box
            display="grid"
            gap={isTablet ? 2 : 4}
            gridTemplateColumns={isTablet ? '1fr' : '1fr 1fr'}
          >
            <FormControl fullWidth>
              <Typography variant="h3">{t('search')}</Typography>
              <br />
              <TextField
                placeholder={t('what-do-you-want-to-find')}
                type="text"
                variant="outlined"
                value={params.searchText}
                onChange={({ target: { value } }) =>
                  setParams({
                    searchText: value,
                  })
                }
                fullWidth
                InputProps={{
                  endAdornment: (
                    <>
                      <Divider sx={{ my: 1.5, mr: 1 }} orientation="vertical" flexItem />
                      <SearchIcon sx={{ mx: 1 }} />
                    </>
                  ),
                }}
              />
            </FormControl>
            <FormControl fullWidth>
              <Typography variant="h3">{t('industries')}</Typography>
              <br />
              <SectorAutocomplete
                value={params.selectedSector}
                onChange={(_, value) =>
                  setParams({
                    selectedSector: value?.kods,
                  })
                }
              />
            </FormControl>
          </Box>
          {partnersWithPromotions.length === 0 && !loading && (
            <Box
              mt="100px"
              alignItems="flex-start"
              justifyContent="center"
              display="flex"
              width={1}
            >
              {t('no-records-found')}
            </Box>
          )}
          <Box
            mt={3}
            display="grid"
            gap={isTablet ? 2 : 4}
            gridTemplateColumns={isTablet ? '1fr' : '1fr 1fr 1fr'}
          >
            {partnersWithPromotions.map((e: any, i) => (
              <Box height="fit-content" key={e.id} mt={1} maxWidth={1}>
                <Partner partnerData={e} />
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <CategoriesMenu
            onItemClick={(value) => {
              setParams({
                // toggle
                selectedCategory: params.selectedCategory === value ? null : value,
              })
            }}
            value={params.selectedCategory}
          />
        </Box>
      </Box>
      {total > 0 && (
        <Box mt={6} width={1} display="flex" justifyContent="center">
          <Pagination
            offset={params.offset}
            total={total}
            limit={params.limit}
            onChange={(pageNumber) =>
              setParams({
                offset: (pageNumber - 1) * params.limit,
              })
            }
          />
        </Box>
      )}
    </>
  )
}
