import axios from 'axios'
import { getBaseUrl } from '../../utils'

export const getNewsItem = (id: string, lng: string) => {
  const baseUrl = getBaseUrl(lng)

  const promiseHomePage = axios.get(`${baseUrl}/api/v1/news?publish_place=home_page&id=${id}`)

  const promiseHomePageApp = axios.get(
    `${baseUrl}/api/v1/news?publish_place=home_page_mobile_app&id=${id}`
  )

  return Promise.all([promiseHomePage, promiseHomePageApp]).then(
    (response) => response.filter((r) => r.data && r.data.length)[0].data[0]
  )
}
