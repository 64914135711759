import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getCompanyOffersList } from '../../api'
import { GenericItemList, LoadMoreButton } from '../../components'
import { ENTRIES_PER_PAGE } from '../../constants'
import { useDocumentTitle } from '../../hooks'
import { NewsItem } from '../../interfaces'
import { useAppContextStore } from '../../store'

export const CompanyOffersList = () => {
  const { i18n } = useTranslation()
  const explanatoryTexts = useAppContextStore((state) => state.explanatoryTexts)

  useDocumentTitle(explanatoryTexts['company-offers-title'])

  const [data, setData] = useState<NewsItem[]>()
  const [hasMore, setHasMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const { isLoading } = useQuery<NewsItem[]>(
    ['company-offers-list', currentPage],
    () => getCompanyOffersList(i18n.language, currentPage),
    {
      onSuccess: (response) => {
        setHasMore(response.length === ENTRIES_PER_PAGE)

        const nextList = (data || []).concat(response)

        setData(nextList)
      },
    }
  )

  const onLoadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  return (
    <GenericItemList
      label={explanatoryTexts['company-offers-title']}
      slug="/company-offers/"
      isLoading={isLoading}
      data={data}
    >
      <LoadMoreButton hasMore={hasMore} isLoading={isLoading} onLoadMore={onLoadMore} />
    </GenericItemList>
  )
}
