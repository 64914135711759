import {
  Box,
  styled,
  Typography,
  SvgIcon as MuiSvgIcon,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getDiscountCategories } from '../../api/discount-categories/getDiscountCategories'
import { breakPoints } from '../../constants'
import { DiscountTypes } from '../../interfaces/discounts'
import DiscountCategoriesTablet from './DiscountCategoriesTablet'
import { getIconByDiscountCategoryCode } from './helpers'

const MenuItem = styled(Box, { shouldForwardProp: (prop) => prop !== 'selected' })<{
  selected: boolean
}>(({ theme, selected }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  minHeight: 60,
  padding: '0 12px',
  color: selected ? theme.palette.common.white : theme.palette.primary.main,
  backgroundColor: selected ? theme.palette.primary.main : 'none',
  marginTop: 4,
  borderRadius: 4,
  cursor: 'pointer',
  userSelect: 'none',
})) as unknown as typeof React.Component<{
  children: React.ReactNode
  selected: boolean
  onClick: () => void
}>

const SvgIcon = styled(MuiSvgIcon)(({ theme }) => ({
  width: 50,
  height: 50,
  marginRight: 6,
})) as unknown as typeof MuiSvgIcon

interface Props {
  onItemClick: (value: string) => void
  value?: string
}

export default function DiscountCategoriesMenu({ value, onItemClick }: Props) {
  const { t } = useTranslation()
  const theme = useTheme()
  const [discountCategories, setDiscountCategories] = useState<DiscountTypes[]>([])
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))

  useQuery(['discount-categories-data'], () => getDiscountCategories(), {
    initialData: [],
    onSuccess: (response) => {
      setDiscountCategories(response)
    },
  })

  return isTablet ? (
    <DiscountCategoriesTablet
      value={value}
      onItemClick={onItemClick}
      discountCategories={discountCategories}
    />
  ) : (
    <>
      <Typography variant="h3" mb="20px">
        {t('categories')}
      </Typography>
      {discountCategories.map((cat, index) => {
        const selected = value === cat.kods
        const Icon = getIconByDiscountCategoryCode(cat.kods)
        return (
          <MenuItem key={index} selected={selected} onClick={() => onItemClick(cat.kods)}>
            <SvgIcon>
              <Icon />
            </SvgIcon>
            <Typography color={selected ? 'white' : 'black'} fontWeight="400" fontSize={16}>
              {t(cat.nosaukums)}
            </Typography>
          </MenuItem>
        )
      })}
    </>
  )
}
