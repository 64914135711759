import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { Box, Divider, LinearProgress, Typography } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { useUserDataStore } from '../../store'
import React, { useState } from 'react'
import { getUser } from '../../api/user/getUser'
import { User } from '../../interfaces'
import { updateUser } from '../../api/user/updateUser'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { useTranslation } from 'react-i18next'
import { SubMenu } from '../../components'

export const Settings = () => {
  const { showSnackBar } = useSnackBar()
  const { currentUser } = useUserDataStore()
  const { t } = useTranslation()

  const isLoggedIn = Boolean(currentUser)

  const [user, setUser] = useState<User | null>(null)

  const { isLoading: isLoadingUser, refetch: refetchUserData } = useQuery(
    'get-user',
    () => getUser({ id: currentUser?.id }),
    {
      enabled: isLoggedIn,
      onSuccess: (response) => {
        setUser(response)
      },
    }
  )

  const updateUserMutation = useMutation((data: any) => updateUser(data), {
    onSuccess: (userResponse: User) => {
      if (userResponse) {
        refetchUserData()
      }
    },
    onError: (error: any) => {
      showSnackBar({
        severity: 'error',
        text: handleErrorMessages(error),
      })
    },
  })

  const isUpdating = updateUserMutation.isLoading

  return (
    <Box>
      <SubMenu />
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('profile.settings')}
      </Typography>
      <Divider />

      {(isLoadingUser || isUpdating) && <LinearProgress />}

      <Box>
        <Box sx={{ padding: '20px 0px 5px', fontWeight: 600 }}>{t('profile.message')}</Box>
        <Box>
          <FormControlLabel
            sx={{
              paddingTop: 2,
            }}
            control={
              <Checkbox
                disabled={isUpdating}
                checked={Boolean(user?.piekrīt_mārketingam)}
                onChange={(e) =>
                  updateUserMutation.mutate({
                    user: currentUser,
                    phone: user?.telefoni,
                    email: user?.epasti,
                    eaddress: user?.eadrese,
                    piekrīt_aptaujām: user?.piekrīt_aptaujām,
                    piekrīt_mārketingam: e.target.checked,
                  })
                }
              />
            }
            label={t('settings.marketing-agreement')}
          />
        </Box>
        <Box>
          <FormControlLabel
            sx={{
              paddingTop: 2,
            }}
            control={
              <Checkbox
                disabled={isUpdating}
                checked={Boolean(user?.piekrīt_aptaujām)}
                onChange={(e) =>
                  updateUserMutation.mutate({
                    user: currentUser,
                    phone: user?.telefoni,
                    email: user?.epasti,
                    eaddress: user?.eadrese,
                    piekrīt_aptaujām: e.target.checked,
                    piekrīt_mārketingam: user?.piekrīt_mārketingam,
                  })
                }
              />
            }
            label={t('settings.survey-agreement')}
          />
        </Box>
      </Box>
    </Box>
  )
}
