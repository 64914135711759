import axios from 'axios'
import { getBaseUrl, getPageParam } from '../../utils'

export const getNewsList = (lng: string, page: number) => {
  const baseUrl = getBaseUrl(lng)
  const pageParam = getPageParam(page)

  const promiseHomePage = axios.get(`${baseUrl}/api/v1/news?publish_place=home_page${pageParam}`)

  const promiseHomePageApp = axios.get(
    `${baseUrl}/api/v1/news?publish_place=home_page_mobile_app${pageParam}`
  )

  return Promise.all([promiseHomePage, promiseHomePageApp]).then((response) => [
    ...response[0].data,
    ...response[1].data,
  ])
}
