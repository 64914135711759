import 'leaflet/dist/leaflet.css'
import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Link } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import Leaflet from 'leaflet'
import { MapContainer, Marker, TileLayer } from 'react-leaflet'
import { LocationResponse } from '../../interfaces/partner'
import { parseGeom } from './helpers'
import { LATITUDE_TUPLE_NUMBER, LONGTITUDE_TUPLE_NUMBER } from '../../constants'

const marker = new Leaflet.Icon({
  iconUrl: require('./assets/marker.png'),
  iconSize: [40, 30],
})

export default function CollapsibleLocation(location: LocationResponse) {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const parsedGeom = parseGeom(location.adrese.geom)
  if (!parsedGeom?.coordinates) return null
  return (
    <Accordion
      // To load tiles only when a location is expanded
      TransitionProps={{ unmountOnExit: true }}
      onChange={() => setIsExpanded(!isExpanded)}
      expanded={isExpanded}
      sx={{
        boxShadow: 'none',
      }}
      disableGutters
    >
      <AccordionSummary sx={{ p: 0 }}>
        {isExpanded ? (
          <KeyboardArrowUpIcon color="primary" />
        ) : (
          <KeyboardArrowDownIcon color="primary" />
        )}
        <Link
          sx={{
            textDecoration: 'none',
          }}
          component="button"
          variant="body2"
        >
          {location.adrese?.adrese}
        </Link>
      </AccordionSummary>
      <AccordionDetails sx={{ boxShadow: 'none', p: 0 }}>
        <Box height={300} width={1}>
          <MapContainer
            center={[
              parsedGeom.coordinates[LATITUDE_TUPLE_NUMBER],
              parsedGeom.coordinates[LONGTITUDE_TUPLE_NUMBER],
            ]}
            zoom={16}
            scrollWheelZoom={false}
          >
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <Marker
              position={[
                parsedGeom.coordinates[LATITUDE_TUPLE_NUMBER],
                parsedGeom.coordinates[LONGTITUDE_TUPLE_NUMBER],
              ]}
              icon={marker}
            />
          </MapContainer>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}
