import { Divider } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getNewsList } from '../../api'
import { GenericItemList, LoadMoreButton, Offers } from '../../components'
import { ENTRIES_PER_PAGE } from '../../constants'
import { useDocumentTitle } from '../../hooks'
import { NewsItem } from '../../interfaces'

export const NewsList = () => {
  const { i18n, t } = useTranslation()

  useDocumentTitle(t('document-title.news-list'))

  const [data, setData] = useState<NewsItem[]>()
  const [hasMore, setHasMore] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)

  const { isLoading } = useQuery<NewsItem[]>(
    ['news-list', currentPage],
    () => getNewsList(i18n.language, currentPage),
    {
      onSuccess: (response) => {
        setHasMore(response.length === ENTRIES_PER_PAGE)

        const nextList = (data || []).concat(response)

        setData(nextList)
      },
    }
  )

  const onLoadMore = () => {
    setCurrentPage(currentPage + 1)
  }

  return (
    <GenericItemList
      label={t('document-title.news-list')}
      slug="/news/"
      isLoading={isLoading}
      data={data}
    >
      <LoadMoreButton hasMore={hasMore} isLoading={isLoading} onLoadMore={onLoadMore} />
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Offers />
    </GenericItemList>
  )
}
