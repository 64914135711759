import { useState } from 'react'
import { Box, Button, Divider, Menu, Typography, useMediaQuery, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import { breakPoints, privateRoutes } from '../../constants'
import { useNotificationStore, useUserDataStore } from '../../store'
import { Person } from '@mui/icons-material'

const sx = {
  unreadNotifications: {
    width: '20px',
    height: '20px',
    marginLeft: '5px',
    borderRadius: '50%',
    fontSize: '14px',
    color: '#fff',
    textAlign: 'center',
    background: '#0bc000',
  },
}

export const SubMenu = () => {
  const { t } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const { currentUser } = useUserDataStore()
  const { notifications } = useNotificationStore()
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return isTablet ? (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        sx={{ cursor: 'pointer' }}
        onClick={handleClick}
      >
        <MenuIcon color="primary" fontSize="large" />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-sub-menu"
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        sx={{
          '.MuiMenu-list': {
            minWidth: '300px',
          },
        }}
      >
        {currentUser && (
          <Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                sx={{
                  m: 0,
                  p: 0,
                  '&.MuiButtonBase-root:hover': {
                    bgcolor: 'transparent',
                  },
                }}
                onClick={() => navigate('/profile')}
              >
                <Box>
                  <Typography
                    color={theme.palette.primary.main}
                    style={{ textTransform: 'uppercase', marginTop: '1px', marginLeft: '4px' }}
                  >
                    {currentUser.vārds}
                  </Typography>
                  <Typography
                    color={theme.palette.primary.main}
                    style={{ textTransform: 'uppercase', marginTop: '1px', marginLeft: '4px' }}
                  >
                    {currentUser.uzvārds}
                  </Typography>
                </Box>
              </Button>
            </Box>
            <Divider
              sx={{ borderColor: 'primary.main', borderWidth: '1px', margin: '14px 0 6px' }}
            />
          </Box>
        )}
        {privateRoutes
          .filter((route) => (route.needAuth ? !!currentUser : true))
          .map((route, routeIndex) => (
            <Box
              key={routeIndex}
              display="flex"
              alignItems="center"
              justifyContent="center"
              margin="16px 0"
            >
              <Link to={route.link}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body1" textAlign="center" sx={{ color: 'primary.main' }}>
                    {t(route.title)}
                  </Typography>
                  {route.title === 'menu.notices' && (
                    <Box sx={sx.unreadNotifications}>
                      {notifications.filter((not) => !not.read).length}
                    </Box>
                  )}
                </Box>
              </Link>
            </Box>
          ))}
        <Link to={'/logout'}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography
              color={theme.palette.primary.main}
              style={{ textTransform: 'uppercase', marginRight: '4px', marginTop: '1px' }}
            >
              {t('exit')}
            </Typography>
            <ExitToAppIcon style={{ color: theme.palette.primary.main }} />
          </Box>
        </Link>
      </Menu>
    </Box>
  ) : (
    <Box sx={{ mb: 4, display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ display: 'flex' }}>
        {privateRoutes
          .filter((route) => (route.needAuth ? !!currentUser : true))
          .map((route, routeIndex) => (
            <Link
              key={routeIndex}
              to={route.link}
              style={{
                display: 'flex',
                color: theme.palette.primary.main,
                fontWeight: 500,
                textTransform: 'uppercase',
                marginLeft: routeIndex > 0 ? '32px' : '0px',
              }}
            >
              {t(route.title)}{' '}
              {route.title === 'menu.notices' ? (
                <Box sx={sx.unreadNotifications}>
                  {notifications.filter((not) => !not.read).length}
                </Box>
              ) : (
                ''
              )}
            </Link>
          ))}
      </Box>
      {currentUser ? (
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', marginRight: '34px' }}>
            <Button
              sx={{
                m: 0,
                p: 0,
                '&.MuiButtonBase-root:hover': {
                  bgcolor: 'transparent',
                },
              }}
              onClick={() => navigate('/profile')}
            >
              <Person sx={{ color: theme.palette.primary.main }} />
              <Typography
                color={theme.palette.primary.main}
                style={{ textTransform: 'uppercase', marginTop: '1px', marginLeft: '4px' }}
              >
                {currentUser.vārds} {currentUser.uzvārds}
              </Typography>
            </Button>
          </Box>
          <Box>
            <Link to={'/logout'}>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  color={theme.palette.primary.main}
                  style={{ textTransform: 'uppercase', marginRight: '4px', marginTop: '1px' }}
                >
                  {t('exit')}
                </Typography>
                <ExitToAppIcon style={{ color: theme.palette.primary.main }} />
              </Box>
            </Link>
          </Box>
        </Box>
      ) : null}
    </Box>
  )
}
