import { ReactComponent as Aid } from './assets/aid.svg'
import { ReactComponent as News } from './assets/news.svg'
import { ReactComponent as Gas } from './assets/gas.svg'
import { ReactComponent as Stationery } from './assets/stationery.svg'
import { ReactComponent as Recreation } from './assets/recreation.svg'
import { ReactComponent as Education } from './assets/education.svg'
import { ReactComponent as Institutions } from './assets/institutions.svg'
import { ReactComponent as Catering } from './assets/catering.svg'
import { ReactComponent as Health } from './assets/health.svg'
import { ReactComponent as Trade } from './assets/trade.svg'
import { ReactComponent as Services } from './assets/services.svg'
import { ReactComponent as Offers } from './assets/lt-est-offers.svg'

// Temp solution, icons should be handled in the BE
export const DISCOUNT_CATEGORY_ICON_MAP = {
  '001': News,
  '002': Gas,
  '005': Stationery,
  '007': Services,
  '008': Health,
  '009': Trade,
  '010': Institutions,
  '011': Education,
  '012': Recreation,
  '013': Catering,
  '014': Aid,
  '020': Offers,
} as const
