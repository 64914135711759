import { ApiParams } from '../../interfaces/common/ApiParams'
import axios from 'axios'
import { BASE_PATH_DISTRO } from '../../constants'

export const getDiscountCategories = (params: ApiParams = {}) => {
  return axios
    .get(`${BASE_PATH_DISTRO}/data/klasifikatora_vērtību_saraksts`, {
      params: {
        klasifikatora_kods: 'PAKALPOJUMU_GRUPA',
        ...params,
      },
    })
    .then((response) => response.data)
}
