import { Divider } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LoadMoreButton, Offers } from '../../components'
import { PromotionItemList } from '../../components/Promotions/PromotionItemList/PromotionItemList'
import { useDocumentTitle } from '../../hooks'
import usePromotionData from './usePromotionData'

export const Promotions = () => {
  const { t } = useTranslation()
  const limit = 20
  useDocumentTitle(t('menu.promo'))
  const { data, isFetching, setOffset, hasMore } = usePromotionData(limit)

  return (
    <PromotionItemList
      label={t('menu.promo')}
      slug="/promotions/"
      isLoading={isFetching}
      data={data}
    >
      <LoadMoreButton
        hasMore={hasMore}
        isLoading={isFetching}
        onLoadMore={() => setOffset((offset) => offset + limit)}
      />
      <Divider sx={{ mt: 4, mb: 4 }} />
      <Offers />
    </PromotionItemList>
  )
}
