import { Box, Typography, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import styles from './Discount.module.css'

export const DiscountCatalogue = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Box className={styles.DiscountContainer}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        textAlign="center"
        justifyContent="center"
        padding="0px 20px"
        color="white"
        height="100%"
      >
        <Typography variant="h2">%</Typography>
        <Typography variant="h4">{t('public-section.discount-menu')}</Typography>
        <Typography variant="body1">{t('public-section.discount-description')}</Typography>
        <Button
          variant="outlined"
          className="inverted"
          sx={{ mt: 2 }}
          onClick={() => navigate('/discounts')}
        >
          {t('view')}
        </Button>
      </Box>
    </Box>
  )
}
