import React from 'react'
import { useEmailVerificationAlertState } from '../../store'
import { ErrorDialog } from '../ErrorDialog/ErrorDialog'

export function EmailConfirmationAlert() {
  const { isOpen, setIsOpen } = useEmailVerificationAlertState()

  return (
    <ErrorDialog
      open={isOpen}
      handleClose={() => setIsOpen(false)}
      text="Lūdzu, apstipriniet savu e-pastu. Uz norādīto e-pasta adresi ir nosūtīts apstiprinājuma e-pasts."
    />
  )
}
