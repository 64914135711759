import { Pagination as MuiPagination, useMediaQuery, useTheme } from '@mui/material'
import { breakPoints } from '../../constants'
import { ApiParams } from '../../interfaces'

interface Props extends Required<ApiParams> {
  total?: number
  onChange: (pageNumber: number) => void
}
export default function Pagination({ offset = 0, total = 0, limit = 9, onChange }: Props) {
  const theme = useTheme()
  const pagesTotal = Math.ceil(total / limit)
  const pageNumber = offset / limit + 1
  const isTablet = useMediaQuery(theme.breakpoints.down(breakPoints.tablet))

  return (
    <MuiPagination
      color="primary"
      page={pageNumber}
      count={pagesTotal}
      showFirstButton
      showLastButton
      onChange={(_, newPageNumber) => {
        onChange(newPageNumber)
      }}
      {...(isTablet && { size: 'small' })}
    />
  )
}
