import { Box, Button, Grid, Link, List, ListItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import QrCode2Icon from '@mui/icons-material/QrCode2'
import { getChildCertificateList } from '../../api/certificate/getChildCertificateList'
import { FullPageLoader, SubMenu } from '../../components'
import { Card } from '../../interfaces/certificate/Card'
import { Children } from '../../interfaces'
import { DocumentStatus } from '../../enums/DocumentStatus'

interface RouterState {
  eligibleChildren: Children[]
  allChildren: Children[]
}

export const InquiryChildrenCard = () => {
  const navigate = useNavigate()
  const location = useLocation()
  let { id } = useParams()
  const { t } = useTranslation()

  const locationState = location.state as RouterState

  const childHasDocumentStatusNew = locationState.allChildren.some((child) =>
    child.pārbaudes.some((pārbaude) =>
      pārbaude.dokumenti?.some((dokuments) => dokuments.statuss_kods === DocumentStatus.JAUNS)
    )
  )

  const [childrenCards, setChildrenCards] = useState<Card[] | null>(null)
  const [childrenWithoutCards, setChildrenWithoutCards] = useState<Children[]>([])

  const { isLoading } = useQuery<Card[]>(
    'get-certificate-list',
    async () => {
      return await getChildCertificateList()
    },
    {
      onSuccess: (cards) => {
        setChildrenCards(cards)
        if (locationState.eligibleChildren)
          setChildrenWithoutCards(
            locationState.eligibleChildren.filter(
              (child) =>
                !cards.some(
                  (_childWithCard) => child.personas_kods === _childWithCard.personas_kods
                )
            )
          )
      },
    }
  )

  const onDone = () => {
    navigate('/inquiry-result')
  }

  const onPlasticCards = () => {
    navigate(`/inquiry-plastic-cards/${id}`)
  }

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box className="body-container">
      <SubMenu />
      <Box mt={2} display="flex" alignItems="center">
        <QrCode2Icon
          sx={{
            fontSize: 60,
          }}
        />
        <Typography variant="h1" sx={{ ml: 2, fontSize: '1.4rem' }}>
          {t('inquiry-contact-info.digital-application')}
        </Typography>
      </Box>
      {childrenCards && childrenCards.length > 0 && (
        <>
          <Typography sx={{ mt: 4, fontWeight: 'bold' }}>
            {t('inquiry-contact-info.digital-application-codes')}
          </Typography>
          <List disablePadding sx={{ mt: 2 }}>
            {childrenCards.map((card) => (
              <ListItem key={card.id} disableGutters>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    {card.vārds} {card.uzvārds}
                  </Grid>
                  <Grid item xs={4} sx={{ textAlign: 'right' }}>
                    {card.aktivizācijas_kods}
                  </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        </>
      )}

      {childrenWithoutCards.length > 0 && (
        <Typography mt={3} fontWeight="bold">
          {t('inquiry-children-cards.children-under-6-hint')}
        </Typography>
      )}
      {childrenWithoutCards.map((child) => (
        <Box mt={2} key={child.persona_id}>
          {child.vārds} {child.uzvārds}
        </Box>
      ))}

      <Button sx={{ mt: 4 }} variant="outlined" onClick={onDone}>
        {t('continue')}
      </Button>

      {!childHasDocumentStatusNew && (
        <>
          <Typography mt={4}>
            {t('inquiry-children-cards.child-order-plastic-card-hint')}
          </Typography>
          <Link onClick={onPlasticCards}>{t('plastic-card')}</Link>
        </>
      )}
    </Box>
  )
}
