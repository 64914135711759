import { SVGProps } from 'react'

export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      // @ts-ignore
      enableBackground: 'new 0 0 24 24',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M18 2h-3c-2.8 0-5 2.2-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7c0-.6.4-1 1-1h3V2z"
      style={{
        fill: '#a20c33',
      }}
    />
  </svg>
)
