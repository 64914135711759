import { Box, Button, styled } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { breakPoints } from '../../constants'
import { DraugiemIcon, FacebookIcon, PrintIcon, TwitterIcon } from '../../icons'

const SocialWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridAutoFlow: 'column',
  borderTop: `1px solid ${theme.palette.primary.main}`,
  marginTop: '22px',
  width: 'fit-content',

  [theme.breakpoints.down(breakPoints.mobile)]: {
    gridAutoFlow: 'unset',
    gridTemplateColumns: '1fr 1fr',
    width: '100%',
  },
})) as unknown as typeof Box

export const SocialShare = () => {
  const { t } = useTranslation()

  const onPrint = () => {
    window.print()
  }

  const onTwitterShare = () => {
    window.open(
      'https://twitter.com/intent/tweet?text=' +
        encodeURIComponent(document.title) +
        encodeURIComponent(' ') +
        encodeURIComponent(window.location.href),
      '_blank'
    )
  }

  const onFbShare = () => {
    window.open(
      'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href),
      '_blank'
    )
  }

  const onDraugiemShare = () => {
    window.open(
      'https://www.draugiem.lv/say/ext/add.php?' +
        'title=' +
        encodeURIComponent(document.title) +
        '&url=' +
        encodeURIComponent(window.location.href),
      '_blank'
    )
  }

  return (
    <SocialWrapper>
      <Button onClick={onPrint}>
        <PrintIcon width="16px" />
        <Box component="span">{t('print')}</Box>
      </Button>
      <Button onClick={onTwitterShare}>
        <TwitterIcon width="16px" />
        <Box component="span">Twitter</Box>
      </Button>
      <Button onClick={onFbShare}>
        <FacebookIcon width="16px" />
        <Box component="span">Facebook</Box>
      </Button>
      <Button onClick={onDraugiemShare}>
        <DraugiemIcon width="16px" />
        <Box component="span">Draugiem</Box>
      </Button>
    </SocialWrapper>
  )
}
