import React from 'react'
import { useQuery } from 'react-query'
import { getPartner } from '../../api/partners/getPartner'
import { getPromotions } from '../../api/promotions'
import { ENTRIES_PER_PAGE } from '../../constants'
import { PromotionItem } from '../../interfaces/promotions'

export default function usePromotionData(limit: number) {
  const [offset, setOffset] = React.useState(0)
  const [hasMore, setHasMore] = React.useState(false)
  const [data, setData] = React.useState<PromotionItem[]>([])

  const { isFetching } = useQuery(['promotions-data'], () => getPromotions(limit, offset), {
    initialData: [],
    onSuccess: (response) => {
      if (Array.isArray(response)) {
        Promise.all(
          response.map((promotion) => getPartner(Number(promotion.sadarbības_partneris_id)))
        ).then((resp) => {
          const activePartnerIds = resp.filter((partner) => partner).map((partner) => partner.id)
          const activePromotions = response.filter((promotion) =>
            activePartnerIds.includes(promotion.sadarbības_partneris_id)
          )

          setData((prevData) => [...prevData, ...activePromotions])
        })
      }
      setHasMore(response.length === ENTRIES_PER_PAGE)
    },
  })

  return { data, isFetching, setOffset, hasMore }
}
