import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getPage } from '../../api'
import { GenericPage } from '../../components'
import { Page } from '../../enums'
import { GenericPageItem } from '../../interfaces'

export const Terms = () => {
  const { i18n } = useTranslation()

  const { data, isLoading } = useQuery<GenericPageItem>(
    'terms',
    () => getPage(Page.Terms, i18n.language),
    {}
  )

  return <GenericPage page={data} isLoading={isLoading} />
}
