import { Grid, Typography, Card, CardMedia, CardContent, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { BASE_PATH_CMS } from '../../constants'
import { NewsItem } from '../../interfaces'
import { getLocalizedDate } from '../../utils'
import { FullPageLoader } from '../FullPageLoader'

interface GenericItemListProps {
  label: string
  isLoading: boolean
  slug: string
  data?: NewsItem[]
  children?: JSX.Element | JSX.Element[]
}

export const GenericItemList = ({
  label,
  isLoading,
  slug,
  data,
  children,
}: GenericItemListProps) => {
  return (
    <>
      <Typography variant="h1" sx={{ mb: 4 }}>
        {label}
      </Typography>
      {isLoading && <CircularProgress />}
      {data ? (
        <>
          <Grid container spacing={4} position="relative" height="100%">
            {data?.map((item, index) => (
              <Grid item key={index} lg={index < 3 ? 4 : 3} md={4} sm={6} xs={12}>
                <Link to={slug + item.id}>
                  <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <CardMedia
                      component="img"
                      sx={{ boxShadow: 'inset 10px 10px 50px #eee' }}
                      image={BASE_PATH_CMS + item.field_titulbilde}
                      height={index < 3 ? '300px' : '200px'}
                    />
                    <CardContent sx={{ backgroundColor: 'transparent' }}>
                      <Typography gutterBottom variant="subtitle2">
                        {getLocalizedDate(item.last_published_at)}
                      </Typography>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          '-webkit-line-clamp': '3',
                          lineClamp: '3',
                          '-webkit-box-orient': 'vertical',
                        }}
                        variant="h2"
                      >
                        {item.title}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
          {children}
        </>
      ) : (
        <FullPageLoader />
      )}
    </>
  )
}
