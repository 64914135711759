import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

interface Data {
  piekrīt_veselības_datu_apstrādei: boolean
}

export const addInquiry = async ({ piekrīt_veselības_datu_apstrādei }: Data) => {
  const response = await axios.post(`${BASE_PATH_PORTAL}/data/izveidot_iesniegumu`, {
    piekrīt_veselības_datu_apstrādei: piekrīt_veselības_datu_apstrādei || false,
  })
  return response.data
}
