import { Box, Link as MuiLink } from '@mui/material'
import { Link } from 'react-router-dom'
import { LogoIcon } from '../../icons'
import { columnizeArray } from '../../utils'
import styles from './Footer.module.css'
import { LoginButton } from '../LoginButton'
import { useAppContextStore } from '../../store'
import { useTranslation } from 'react-i18next'
import { SocialMedia } from '../SocialMedia'
import { routes } from '../../constants'

export const FooterNavigation = () => {
  const { t } = useTranslation()
  const explanatoryTexts = useAppContextStore((state) => state.explanatoryTexts)

  const routeList = columnizeArray(routes, 3)

  return (
    <Box className={styles.footerContainer}>
      <Box display="flex" alignItems="center">
        <LogoIcon className={styles.logo} />

        <LoginButton inverted sx={{ ml: 2 }} toInquiry />
        <Box sx={{ ml: 2, display: 'flex', flexDirection: 'column' }}>
          {explanatoryTexts['e-mail'] && (
            <MuiLink sx={{ color: '#FFF' }} href={'mailto:' + explanatoryTexts['e-mail']}>
              {explanatoryTexts['e-mail']}
            </MuiLink>
          )}
          {explanatoryTexts['phone'] && (
            <MuiLink sx={{ color: '#FFF' }} href={'tel:' + explanatoryTexts['phone']}>
              {explanatoryTexts['phone']}
            </MuiLink>
          )}
        </Box>
      </Box>
      <Box display="flex">
        <Box display="flex">
          {routeList.map((column, index) => (
            <Box key={index} display="flex" flexDirection="column" alignItems="center" mr={4}>
              <Box display="flex" flexDirection="column">
                {column.map((route, routeIndex) => (
                  <Link
                    key={routeIndex}
                    to={route.link}
                    className={`Header-Footer-Nav-Link ${styles.link}`}
                  >
                    {t(route.title)}
                  </Link>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <SocialMedia />
      </Box>
    </Box>
  )
}
