import { Box, Button, Typography, useMediaQuery, useTheme } from '@mui/material'
import styles from './News.module.css'
import { useNavigate } from 'react-router-dom'
import { breakPoints, BASE_PATH_CMS } from '../../../constants'
import { useQuery } from 'react-query'
import { NewsItem } from '../../../interfaces'
import { useTranslation } from 'react-i18next'
import { DelayedLoader } from '../../../components'
import { getTop4NewsList } from '../../../api'

export const News = () => {
  const { i18n } = useTranslation()
  const theme = useTheme()
  const navigate = useNavigate()
  const { isFetching, data } = useQuery<NewsItem[]>(
    ['top-4-news-list'],
    () => getTop4NewsList(i18n.language),
    {
      initialData: [],
    }
  )
  const isLaptop = useMediaQuery(theme.breakpoints.down(breakPoints.laptop))

  const { t } = useTranslation()
  const openNews = () => {
    // TODO: create a constant for storing routes
    navigate('/news')
  }

  return (
    <Box mt={isLaptop ? 0 : 6}>
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('menu.news')}
      </Typography>
      {isFetching && (
        <Box ml={1}>
          <DelayedLoader loaderSize={26} />
        </Box>
      )}
      <Box className={styles.gridContainer}>
        {data?.map((item, index) => (
          <Box key={index}>
            <Box className={styles.inner} onClick={() => navigate(`/news/${item.id}`)}>
              <img
                className={styles.gridItemImg}
                src={BASE_PATH_CMS + item.field_titulbilde}
                alt="top news"
              />
              <Typography className={styles.title}>{item.title}</Typography>
            </Box>
          </Box>
        ))}
      </Box>
      {!isFetching && (
        <Button sx={{ mt: 2 }} onClick={openNews} variant="outlined">
          {t('menu.all-news')}
        </Button>
      )}
    </Box>
  )
}
