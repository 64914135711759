import { SVGProps } from 'react'

export const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    style={{
      // @ts-ignore
      enableBackground: 'new 0 0 24 24',
    }}
    xmlSpace="preserve"
    {...props}
  >
    <path
      d="M16.5 3c-1 0-2.1.4-2.9 1.1C12.5 5 12 6.2 12 7.5v1h-.3C8.3 8.5 5 6.8 3 4c0 0-4 9 5 13-1.9 1.3-4.2 2-6.5 2H1c2.4 1.3 4.9 1.9 7.3 1.9C15 20.9 21 16 21 7.5c0-.3 0-.6-.1-.8 1-1 1.7-2.3 2.1-3.7-1 .7-2 1.2-3.1 1.5-.9-1-2.2-1.5-3.4-1.5zM23 3z"
      style={{
        fill: '#a20c33',
      }}
    />
  </svg>
)
