import axios from 'axios'
import { BASE_PATH_DISTRO } from '../../constants'

export const getPromotions = (limit: number, offset: number) => {
  return axios
    .get(`${BASE_PATH_DISTRO}/data/sadarbības_partnera_akciju_saraksts`, {
      params: {
        limit: limit,
        offset: offset,
      },
    })
    .then((response) => response.data)
}
