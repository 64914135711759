import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { activateEmailWithCode } from '../../api/activation/activation'

export default function useActivation() {
  const { otp } = useParams()
  const mutation = useMutation(() => activateEmailWithCode(otp as string))

  return {
    activate: mutation.mutate,
    isLoading: mutation.isLoading,
    error: mutation.error,
    response: mutation.data,
  }
}
