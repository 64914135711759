import { RequestErrors } from '../enums/RequestErrors'

function firstLetterUpperCase(string: string) {
  return string[0].toUpperCase() + string.slice(1).toLowerCase()
}

export function handleErrorMessages(error: any, hideErrorLabel = false) {
  let processedErrorMessage = 'Kļūda'

  if (typeof error === 'string') processedErrorMessage = error

  if (error instanceof Error) processedErrorMessage = error.message

  if (error) {
    const errorMessage = Array.isArray(error?.response?.data)
      ? error.response.data[0].kļūdas[0]
      : error?.response?.data || processedErrorMessage

    const errorCode = error?.response?.status

    if (errorMessage) {
      const normalizedErrorMessage = firstLetterUpperCase(errorMessage)

      if (normalizedErrorMessage && normalizedErrorMessage in RequestErrors) {
        processedErrorMessage =
          (!hideErrorLabel ? 'Kļūda: ' : '') +
          RequestErrors[normalizedErrorMessage as keyof typeof RequestErrors]
      } else {
        processedErrorMessage = (!hideErrorLabel ? 'Kļūda: ' : '') + normalizedErrorMessage
      }
    } else {
      processedErrorMessage = (!hideErrorLabel ? 'Kļūda: ' : '') + errorCode
    }
  }

  return processedErrorMessage
}
