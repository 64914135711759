import React from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  iconColor?: string
  open: boolean
  text: string
  handleClose: () => void
}

export const ErrorDialog = ({ iconColor = '#689F44', open, text = '', handleClose }: Props) => {
  return (
    <Dialog
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
            borderRadius: 2,
          },
        },
      }}
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          padding: '0px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            backgroundColor: '#F4F6F0',
            width: '100%',
            justifyContent: 'center',
            padding: '20px 0px',
          }}
        >
          <InfoOutlinedIcon sx={{ width: 'auto', height: '70px', color: iconColor }} />
        </Box>
        <Box
          sx={{
            padding: '22px',
            fontSize: '1.2rem',
            textAlign: 'center',
            fontWeight: '500',
          }}
        >
          {text}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button fullWidth variant="outlined" onClick={handleClose}>
          Aizvērt
        </Button>
      </DialogActions>
    </Dialog>
  )
}
