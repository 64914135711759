import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Card, CardMedia, Grid } from '@mui/material'
import Carousel from 'react-material-ui-carousel'
import styles from './Discount.module.css'
import { NewsItem } from '../../../interfaces'
import { getBannerImageList } from '../../../api'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { BASE_PATH_CMS } from '../../../constants'

const Banner = (props: { item: NewsItem }) => {
  const navigate = useNavigate()
  const item = props.item

  return (
    <Card raised className={styles.Banner} onClick={() => navigate(`/news/${item.id}`)}>
      <Grid container spacing={0} className={styles.BannerGrid}>
        <Grid item xs={12}>
          <CardMedia
            className={styles.Media}
            image={BASE_PATH_CMS + item.field_titulbilde}
            title={item.title}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export const DiscountImageSlider = () => {
  const { i18n } = useTranslation()
  const { isFetching, data } = useQuery<NewsItem[]>(
    ['banner-image-list'],
    () => getBannerImageList(i18n.language),
    {
      initialData: [],
    }
  )

  if (isFetching || !data?.length) return null

  return (
    <Carousel
      autoPlay={true}
      indicators={true}
      navButtonsAlwaysVisible={true}
      swipe={true}
      cycleNavigation={false}
    >
      {data?.map((item, index) => {
        return <Banner item={item} key={index} />
      })}
    </Carousel>
  )
}
