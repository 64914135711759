import React from 'react'
import { Box, Divider, Grid, Typography, useTheme } from '@mui/material'
import { FullPageLoader } from '../../components'
import { Card } from '../../components/Profile/Card'
import { CardBack } from '../../components/Profile/CardBack'
import { Card as CardType } from '../../interfaces/certificate/Card'
import { CardChild as ChildCardType } from '../../interfaces/certificate/CardChild'
import { useQuery } from 'react-query'
import { getCertificateList } from '../../api/certificate/getCertificateList'
import { CardFullscreenDialog } from '../../components/Profile/CardFullscreenDialog'
import ReactCardFlip from 'react-card-flip'
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut'
import QrCodeIcon from '@mui/icons-material/QrCode'
import { getChildCertificateList } from '../../api/certificate/getChildCertificateList'

export const MyCard = () => {
  const theme = useTheme()

  const [certificate, setCertificate] = React.useState<CardType | null>(null)
  const [childrenCertificate, setChildrenCertificate] = React.useState<ChildCardType[] | null>(null)
  const [isFlipped, setIsFlipped] = React.useState(false)
  const [openCardFullscreen, setOpenCardFullscreen] = React.useState(false)

  const { isLoading: isLoadingCertificateList } = useQuery(
    'get-certificate-list',
    async () => {
      return await getCertificateList()
    },
    {
      onSuccess: (response) => {
        if (response && response.length > 0) {
          setCertificate(response[0])
        }
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const { isLoading: isLoadingChildrenCertificateList } = useQuery(
    'get-children-certificate-list',
    async () => {
      return await getChildCertificateList()
    },
    {
      onSuccess: (response) => {
        if (response && response.length > 0) {
          setChildrenCertificate(response)
        }
      },
      onError: (error) => {
        console.log(error)
      },
    }
  )

  const themeColor = certificate ? (certificate.id ? 'red' : 'green') : 'red'
  const themeColorCode =
    themeColor === 'red' ? theme.palette.primary.main : theme.palette.secondary.main
  const showCard = Boolean(certificate) && !isLoadingCertificateList

  const handleClickOpen = () => {
    setIsFlipped(true)
    setOpenCardFullscreen(true)
  }

  const handleClose = () => {
    setOpenCardFullscreen(false)
  }

  const handleCardFlip = () => {
    setIsFlipped(!isFlipped)
  }

  const cardButtons = [
    {
      text: 'Otra puse',
      icon: <SwitchAccessShortcutIcon sx={{ fill: themeColorCode }} />,
      onClick: handleCardFlip,
    },
    {
      text: 'QR kods',
      icon: <QrCodeIcon sx={{ fill: themeColorCode }} />,
      onClick: handleClickOpen,
    },
  ]

  function generateCardButtons() {
    return (
      <Grid container justifyContent="center" alignItems="center" mt={2}>
        {cardButtons.map((item, index) => (
          <Grid
            item
            xs={3}
            display="flex"
            sx={{
              height: '100%',
              borderStyle: index !== cardButtons.length - 1 ? 'solid' : 'none',
              borderColor: 'rgba(0, 0, 0, 0.12)',
              borderWidth: '0 1px 0 0',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                cursor: 'pointer',
              }}
              onClick={() => item.onClick()}
            >
              {item.icon}
              <Typography sx={{ height: '50px', width: '60px' }}>{item.text}</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <Box>
      <CardFullscreenDialog
        themeColor={themeColor}
        open={openCardFullscreen}
        card={certificate}
        children={childrenCertificate}
        disabled={!showCard}
        isFlipped={isFlipped}
        handleClose={handleClose}
      />

      {isLoadingCertificateList ? (
        <FullPageLoader />
      ) : (
        <Box>
          <Typography variant="h1" sx={{ mb: 4 }}>
            Mana apliecība
          </Typography>

          <Divider />

          {certificate ? (
            <Box mt={4}>
              {!isLoadingCertificateList && !isLoadingChildrenCertificateList && (
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      height: '200px',
                      width: '320px',
                    }}
                  >
                    <ReactCardFlip
                      isFlipped={isFlipped}
                      flipDirection="horizontal"
                      containerStyle={{ height: '100%' }}
                    >
                      <Card card={certificate} themeColor={themeColor} disabled={!showCard} />
                      <CardBack
                        card={certificate}
                        themeColor={themeColor}
                        children={childrenCertificate}
                      />
                    </ReactCardFlip>

                    {showCard && generateCardButtons()}
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            'Nav apliecības'
          )}
        </Box>
      )}
    </Box>
  )
}
