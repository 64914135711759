import { DISCOUNT_CATEGORY_ICON_MAP } from './constants'
import ImageNotSupportedIcon from '@mui/icons-material/ImageNotSupported'
import { ApiParams, Geometry } from '../../interfaces/common'
import { shallowCompareObjects } from '../../utils/shallowCompareObjects'
import { Partner } from '../../interfaces/partner/Partner'
import { Promotion } from '../../interfaces/promotions/Promotion'

export function getIconByDiscountCategoryCode(discountCategoryCode: string) {
  return (
    // @ts-ignore
    DISCOUNT_CATEGORY_ICON_MAP[discountCategoryCode] || ImageNotSupportedIcon
  )
}

export function reshapePartnersWithDiscountsData(
  defaultParams: ApiParams,
  params: ApiParams,
  partners: Partner[],
  promotions: Promotion[]
) {
  const areDefaultFilters = shallowCompareObjects(defaultParams, params)

  const filteredPartners = areDefaultFilters
    ? partners.filter(
        (partner) => !partner.pakalpojumu_grupa.some((group) => group.grupa_kods === '020')
      )
    : partners

  return filteredPartners.map((partner) => {
    return {
      ...partner,
      promotions: promotions.filter(
        (promotion) => promotion.sadarbības_partneris_id === partner.id
      ),
    }
  })
}

// Temporary solution, should be removed after requiring users to enter urls with http/https protocol in the partner form.
export function addHttpToUrl(url: string) {
  if (url.startsWith('http://') || url.startsWith('https://')) return url
  return 'https://' + url
}

export function parseGeom(geom: string): Geometry | null {
  try {
    const parsedGeom: Geometry = JSON.parse(geom)
    if (parsedGeom?.type) return parsedGeom
    return null
  } catch {
    return null
  }
}
