import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Notice as NoticeProps } from '../../interfaces/notice'
import { format, parseISO } from 'date-fns'
import { DEFAULT_DATE_FORMAT } from '../../constants/constants'
import { SubMenu } from '../../components'
import { useNotificationStore, useUserDataStore } from '../../store'
import { useTranslation } from 'react-i18next'

const sx = {
  notice: {
    cursor: 'pointer',
  },
  unseenNotice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px',
    backgroundColor: 'rgba(246, 171, 10, 0.1)',
    borderRadius: 5,
    borderLeft: '8px solid #dab15f',
    padding: '30px',
    color: '#6c4006',
  },
  seenNotice: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0px',
    backgroundColor: '#f7f7f7',
    borderRadius: 5,
    padding: '30px',
    color: 'black',
  },
  bold: {
    fontWeight: 600,
  },
  light: {
    color: '#b5b5b5',
  },
}

export const Notice = () => {
  const navigate = useNavigate()
  const { notifications, setNotifications } = useNotificationStore()
  const { currentUser } = useUserDataStore()

  const { t } = useTranslation()

  const handleNoticeClick = (selectedNotification: NoticeProps) => {
    setNotifications(
      notifications.map((notification) => {
        if (notification.id === selectedNotification.id) {
          return {
            ...selectedNotification,
            read: true,
          }
        } else {
          return notification
        }
      })
    )

    navigate(`/notices/${selectedNotification.id}`, {
      state: {
        notice: selectedNotification,
      },
    })
  }

  return (
    <Box>
      <SubMenu />
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('notifications.title')}
      </Typography>
      <Divider />

      {currentUser ? (
        notifications.map((notice, index) => {
          const { nosaukums, laiks, read } = notice

          return (
            <Box
              key={index}
              sx={[read ? sx.seenNotice : sx.unseenNotice, sx.notice]}
              onClick={() => handleNoticeClick(notice)}
            >
              <Box>
                <Box sx={!read ? sx.bold : {}}>{nosaukums}</Box>

                <br />

                <Box sx={read ? sx.light : {}}>
                  {t('received')}: {format(parseISO(laiks), DEFAULT_DATE_FORMAT)}
                </Box>
              </Box>
              <Typography sx={{ cursor: 'pointer' }}>Atvērt</Typography>
            </Box>
          )
        })
      ) : (
        <Box mt={2} sx={{ textAlign: 'center' }}>
          <Typography variant="h3">{t('notifications.unauthorized-error')}</Typography>
        </Box>
      )}
    </Box>
  )
}
