import axios from 'axios'
import { NewsItem } from '../../interfaces'
import { getBaseUrl } from '../../utils'

export const getBannerImageList = async (lng: string) => {
  const baseUrl = getBaseUrl(lng)

  const promiseHomePage = axios.get(
    `${baseUrl}/api/v1/news?publish_place=home_page&baner_parameter=middle_banner`
  )
  const promiseHomePageApp = axios.get(
    `${baseUrl}/api/v1/news?publish_place=home_page_mobile_app&baner_parameter=middle_banner`
  )

  const response = await Promise.all([promiseHomePage, promiseHomePageApp]).then((response) => [
    ...response[0].data,
    ...response[1].data,
  ])

  const bannerList: NewsItem[] = response

  const orderedBannerImagesByLongTermThenPublishDate = bannerList.sort(
    (bannerItem1, bannerItem2) => {
      return (
        Number(bannerItem2.publishing_longterm === 'true') -
          Number(bannerItem1.publishing_longterm === 'true') ||
        new Date(bannerItem2.last_published_at).getTime() -
          new Date(bannerItem1.last_published_at).getTime()
      )
    }
  )

  return orderedBannerImagesByLongTermThenPublishDate
}
