import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Box,
  Button,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'

import { setPartnerComplaint } from '../../api/partners/setPartnerComplaint'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

interface Props {
  onClose: () => void
  partnerName: string
  partnerId: number
}

const ComplaintModal: React.FC<Props> = ({ onClose, partnerName, partnerId }) => {
  const { t } = useTranslation()
  const { showSnackBar } = useSnackBar()
  const [description, setDescription] = useState('')

  const setPartnerRaitingMutation = useMutation(
    () => setPartnerComplaint({ sadarbības_partneris_id: partnerId, apraksts: description }),
    {
      onSuccess: () => {
        showSnackBar()
        onClose()
      },
      onError: (error: any) => {
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent sx={{ paddingBottom: '8px' }}>
        <Box display="flex" flexDirection="column">
          <Typography display="inline" textAlign="center" variant="h2">
            {partnerName}
          </Typography>
          <Box textAlign="center" marginTop="14px">
            <TextField
              placeholder={t('complaint-placeholder')}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              fullWidth
              rows={4}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <Button variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button variant="contained" onClick={() => setPartnerRaitingMutation.mutate()}>
          {t('submit')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default ComplaintModal
