import { Link } from '@mui/material'
import { FileEntry } from '../../interfaces/file/FileEntry'
import { BASE_PATH_PORTAL } from '../../constants'

export const FileLink = ({ file }: { file: FileEntry }) => {
  return (
    <Link
      href={`${window.location.origin}${BASE_PATH_PORTAL}/api/v1/download/${file.faila_id}/${file.sha256
        }`}
      {...(file.content_type === 'application/pdf'
        ? { target: '_blank', rel: 'noreferrer' }
        : { download: file.nosaukums })}
    >
      {file.nosaukums}
    </Link>
  )
}
