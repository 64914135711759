import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getCommonTexts, getSocialMedia } from '../../api'
import { FullPageLoader } from '../../components'
import { ExplanatoryText, SocialMediaEntry } from '../../interfaces'
import { useAppContextStore } from '../../store/appContext'

export interface InitProps {
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>> | null
}

export const Init = ({ children }: InitProps) => {
  const { i18n } = useTranslation()
  const { explanatoryTexts, socialMedia, setExplanatoryTexts, setSocialMedia } =
    useAppContextStore()

  useQuery<ExplanatoryText[]>('common', () => getCommonTexts(i18n.language), {
    onSuccess: (response) => {
      const texts: { [key: string]: string } = { done: '1' }

      for (const text of response) {
        texts[text.classifier] = text.body
      }

      setExplanatoryTexts(texts)
    },
  })

  useQuery<SocialMediaEntry[]>('social-media', () => getSocialMedia(i18n.language), {
    onSuccess: (response) => setSocialMedia(response),
  })

  return <>{explanatoryTexts['done'] && socialMedia ? children : <FullPageLoader />}</>
}
