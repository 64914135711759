import { attributesToProps, DOMNode, Element } from 'html-react-parser'
import { BASE_PATH_CMS } from '../constants'

export const htmlParseOptions = {
  replace: (domNode: DOMNode) => {
    if (domNode.type === 'tag' && domNode instanceof Element && domNode.name === 'img') {
      const props = attributesToProps(domNode.attribs)

      /*
       * adds BASE_PATH_CMS to images that are served from Drupal
       * those that are uploaded elsewhere or embedded as data:image ar rendered as is
       */
      if (props.src.startsWith('/sites/default/files')) {
        props.src = BASE_PATH_CMS + props.src
      }

      // eslint-disable-next-line jsx-a11y/alt-text
      return <img {...props} />
    }

    return domNode
  },
}
