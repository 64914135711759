import axios from 'axios'
import { BASE_PATH_DISTRO } from '../../constants'
import { ApiParams } from '../../interfaces'

export const getPartnersList = (params: ApiParams) => {
  return axios
    .get(`${BASE_PATH_DISTRO}/data/sadarbības_partneru_saraksts`, {
      params,
    })
    .then((response) => response.data)
}

export const getPartnersTotal = (params: ApiParams) => {
  return axios
    .get(`${BASE_PATH_DISTRO}/data/count/sadarbības_partneru_saraksts`, {
      params,
    })
    .then((response) => response.data)
}
