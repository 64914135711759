import { useLocation } from 'react-router-dom'
import { PromotionItem } from '../../interfaces/promotions'
import React from 'react'
import { PromotionItemEntry } from '../../components/Promotions/PromotionItemEntry'

export const PromotionEntry = () => {
  const location = useLocation()
  const state = location.state as PromotionItem

  return <PromotionItemEntry isLoading={false} data={state} />
}
