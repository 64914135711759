import { Box } from '@mui/material'
import { LoginButton } from '../LoginButton'
import { LogoIconWithText } from '../../icons'
import { Link } from 'react-router-dom'

export const MainLogo = () => (
  <Box display="flex" flexDirection="column">
    <Link to="/">
      <LogoIconWithText />
    </Link>
    <LoginButton sx={{ fontSize: 18, mt: 6, minWidth: 180, minHeight: 48 }} toInquiry />
  </Box>
)
