import { Box, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'

interface LoadMoreButtonProps {
  hasMore: boolean
  isLoading: boolean
  onLoadMore: () => void
}

export const LoadMoreButton = ({ hasMore, isLoading, onLoadMore }: LoadMoreButtonProps) => {
  const { t } = useTranslation()

  return (
    <>
      {hasMore ? (
        <Box sx={{ textAlign: 'center', mt: 4 }}>
          <Button onClick={onLoadMore} variant="outlined" disabled={isLoading}>
            {t('load-more')}
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </>
  )
}
