import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { getNewsItem } from '../../api'
import { NewsItem } from '../../interfaces'
import { useTranslation } from 'react-i18next'
import { GenericItemEntry } from '../../components'

export const NewsEntry = () => {
  const { id } = useParams()
  const { i18n } = useTranslation()

  const { data, isLoading } = useQuery<NewsItem>(
    ['news-entry', id],
    () => getNewsItem(id as string, i18n.language),
    {
      enabled: !!id,
    }
  )

  return <GenericItemEntry isLoading={isLoading} data={data} />
}
