import create from 'zustand'
import { persist } from 'zustand/middleware'
import { CurrentUser, SocialMediaEntry } from '../interfaces'
import { Notice } from '../interfaces/notice'
import { LocalStorageKeys } from './localStorageKeys'

interface AppContextStore {
  explanatoryTexts: { [key: string]: string }
  socialMedia: SocialMediaEntry[] | undefined
  partnerList: { [key: string]: string | number }[] | undefined
  setExplanatoryTexts: (nextExplanatoryTexts: { [key: string]: string }) => void
  setSocialMedia: (nextSocialMedia: SocialMediaEntry[]) => void
  setPartnerList: (nextPartnerList: { [key: string]: string | number }[]) => void
}

interface UserDataStore {
  currentUser: CurrentUser | undefined
  setCurrentUser: (user: CurrentUser | undefined) => void
  clearUserDataStorage: () => void
}

interface NotificationStore {
  notifications: Notice[]
  setNotifications: (nextNotifications: Notice[]) => void
}

export const useAppContextStore = create<AppContextStore>((set) => ({
  explanatoryTexts: {},
  socialMedia: undefined,
  partnerList: undefined,
  setExplanatoryTexts: (nextExplanatoryTexts: { [key: string]: string }) => {
    set(() => ({ explanatoryTexts: nextExplanatoryTexts }))
  },
  setSocialMedia: (nextSocialMedia: SocialMediaEntry[]) => {
    set(() => ({ socialMedia: nextSocialMedia }))
  },
  setPartnerList: (nextPartnerList: { [key: string]: string | number }[]) => {
    set(() => ({ partnerList: nextPartnerList }))
  },
}))

export const useNotificationStore = create<NotificationStore>()(
  persist((set) => ({
    notifications: [],
    setNotifications: (nextNotifications: Notice[]) => {
      set(() => ({ notifications: nextNotifications }))
    },
  }))
)

export const useUserDataStore = create<UserDataStore>()(
  persist(
    (set) => ({
      currentUser: undefined,
      setCurrentUser: (nextUser: CurrentUser | undefined) => {
        set(() => ({ currentUser: nextUser }))
      },
      clearUserDataStorage: () => {
        localStorage.removeItem(LocalStorageKeys.UserData)
      },
    }),
    {
      name: LocalStorageKeys.UserData,
    }
  )
)
