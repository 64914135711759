import axios from 'axios'
import { Page } from '../../enums'
import { getBaseUrl } from '../../utils'

export const getPage = (page: Page, lng: string) => {
  const baseUrl = getBaseUrl(lng)

  return axios
    .get(`${baseUrl}/api/v1/blocks?id=${page}`)
    .then((response) => (response.data ? response.data[0] : undefined))
}
