import React from 'react'
import { Box, styled, Typography, SvgIcon as MuiSvgIcon, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import { getIconByDiscountCategoryCode } from './helpers'
import { DiscountTypes } from '../../interfaces/discounts'

const MenuItem = styled(Box)<{ selected: boolean }>(({ theme, selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minHeight: 60,
  padding: '8px 6px',
  color: selected ? theme.palette.common.white : theme.palette.primary.main,
  backgroundColor: selected ? theme.palette.primary.main : 'none',
  marginTop: 4,
  borderRadius: 4,
  cursor: 'pointer',
  userSelect: 'none',
}))

const SvgIcon = styled(MuiSvgIcon)(({ theme }) => ({
  width: 50,
  height: 50,
  marginRight: 6,
}))

interface Props {
  onItemClick: (value: string) => void
  value?: string
  discountCategories: DiscountTypes[]
}

const MENU_WIDTH = '150px'

const FixedMenu = styled('div')<{ isOpen: boolean; hasReachedBottom: boolean }>(
  ({ isOpen, hasReachedBottom, theme }) => ({
    position: 'fixed',
    top: 0,
    right: 0,
    display: isOpen ? 'block' : 'none',
    height: `100%`,
    width: MENU_WIDTH,
    backgroundColor: theme.palette.common.white,
    zIndex: 2,
    overflow: 'auto',
    borderLeft: `1px solid ${theme.palette.primary.main}`,
    padding: '0 6px 20px 6px',
    ':before': {
      content: '""',
      display: hasReachedBottom ? 'none' : 'block',
      pointerEvents: 'none',
      width: MENU_WIDTH,
      height: '100%',
      position: 'fixed',
      right: 0,
      top: 0,
      background: `linear-gradient(transparent 400px, ${theme.palette.common.white})`,
    },
  })
)
const FixedButton = styled(Button)<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  position: 'fixed',
  top: '50%',
  width: 146,
  right: isOpen ? 95 : -55,
  backgroundColor: isOpen ? theme.palette.common.white : theme.palette.primary.main,
  color: isOpen ? theme.palette.common.black : theme.palette.common.white,
  zIndex: 3,
  borderRadius: '4px 4px 0 0',
  border: `1px solid ${theme.palette.primary.main}`,
  borderBottomColor: theme.palette.common.white,
  transform: 'rotate(-90deg)',
  '&:focus, &:hover': {
    zIndex: 3,
    backgroundColor: isOpen ? theme.palette.common.white : theme.palette.primary.main,
  },
}))

const FixedScrollButton = styled(IconButton)<{ hasReachedBottom: boolean }>(
  ({ hasReachedBottom }) => ({
    display: hasReachedBottom ? 'none' : 'block',
    position: 'fixed',
    bottom: 20,
    right: 50,
    zIndex: 2,
  })
)

export default function DiscountCategoriesTablet({
  value,
  onItemClick,
  discountCategories,
}: Props) {
  const fixedMenuRef = React.useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = React.useState(false)
  const [hasReachedBottom, setHasReachedBottom] = React.useState(false)

  return (
    <>
      <FixedButton
        disableRipple
        startIcon={!isOpen && <ArrowUpwardIcon sx={{ color: '#fff' }} />}
        endIcon={isOpen && <CloseIcon color="primary" />}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        {isOpen ? 'Aizvērt' : 'Kategorijas'}
      </FixedButton>
      <FixedMenu
        hasReachedBottom={hasReachedBottom}
        isOpen={isOpen}
        ref={fixedMenuRef}
        onScroll={(e) => {
          const bottomReached =
            e.currentTarget.scrollHeight - e.currentTarget.clientHeight <=
            e.currentTarget.scrollTop + 50
          setHasReachedBottom(bottomReached)
        }}
      >
        {discountCategories.map((cat, index) => {
          const selected = value === cat.kods
          const Icon = getIconByDiscountCategoryCode(cat.kods)
          return (
            <MenuItem key={index} selected={selected} onClick={() => onItemClick(cat.kods)}>
              <SvgIcon>
                <Icon />
              </SvgIcon>
              <Typography
                color={selected ? 'white' : 'black'}
                fontWeight="400"
                textAlign="center"
                fontSize={14}
              >
                {cat.nosaukums}
              </Typography>
            </MenuItem>
          )
        })}
        <FixedScrollButton
          onClick={() => {
            fixedMenuRef.current?.scrollTo({
              behavior: 'smooth',
              top: fixedMenuRef.current.offsetTop + fixedMenuRef.current.offsetHeight,
            })
          }}
          hasReachedBottom={hasReachedBottom}
        >
          <ArrowDownwardIcon fontSize="large" color="primary" />
        </FixedScrollButton>
      </FixedMenu>
    </>
  )
}
