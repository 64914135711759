import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

export const getComplaints = async (sadarbības_partneris_id: number) => {
  const response = await axios.get(
    `${BASE_PATH_PORTAL}/data/sadarbības_partnera_sūdzību_saraksts?sadarbības_partneris_id=${sadarbības_partneris_id}`
  )

  return response.data
}
