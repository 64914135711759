import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

export const sendActivationCodeToMyEmail = async (id: number) => {
  const response = await axios.post(`${BASE_PATH_PORTAL}/data/nosūtīt_epasta_aktivizācijas_kodu`, {
    id,
  })

  if (response.status === 200) {
    return response.data
  }

  throw response.data
}

export const sendActivationCodeToPhone = async ({ id, phone }: { id: number; phone: string }) => {
  const response = await axios.put(`${BASE_PATH_PORTAL}/data/nosūtīt_aktivizācijas_kodu/${id}`, {
    id,
    telefons: phone,
  })
  return response.data
}

export const sendActivationCodeToMyPhone = async (id: number) => {
  const response = await axios.put(`${BASE_PATH_PORTAL}/data/nosūtīt_telefona_aktivizācijas_kodu/${id}`, {
    id,
  })
  return response.data
}

export const activateEmailWithCode = async (otp: string) => {
  const response = await axios.post(`${BASE_PATH_PORTAL}/api/v1/aktivizēt_epastu_ar_kodu`, {
    otp,
  })

  if (response.status === 200) {
    return response.data
  }

  throw response.data
}

export const activatePhoneWithCode = async (otp: string) => {
  const response = await axios.post(`${BASE_PATH_PORTAL}/data/aktivizēt_telefonu_ar_kodu`, {
    otp,
  })
  if (response.status === 200) {
    return response.data
  }

  throw response.data
}
