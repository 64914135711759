import React from 'react'
import { Autocomplete, Divider, TextField, styled } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getSectors } from '../../api/sectors/getSectors'
import { Sector } from '../../interfaces'

// TODO: improve typing
interface Props {
  value: string
  onChange: (event: React.SyntheticEvent<Element, Event>, value: any) => void
  disabled?: boolean
  size?: 'medium' | 'small'
  variant?: string
}

const StyledAutocomplete = styled(Autocomplete)({
  '.MuiOutlinedInput-root .MuiAutocomplete-endAdornment': {
    right: 16,
  },
})
export default function SectorAutocomplete({
  disabled,
  value,
  onChange,
  variant = 'standard',
  size = 'medium',
  ...props
}: Props) {
  const { i18n, t } = useTranslation()
  const { data, isLoading } = useQuery<Sector[]>('sectors-data', () => getSectors(), {
    initialData: [],
  })

  const selectedSector = React.useMemo(() => {
    return data?.find((sector) => sector.kods === value) || null
  }, [data, value])

  return (
    <StyledAutocomplete
      popupIcon={<KeyboardArrowDownIcon />}
      size={size}
      options={data || []}
      value={selectedSector}
      onChange={onChange}
      // @ts-ignore
      getOptionLabel={(option: { nosaukums: string; nosaukums_eng: string }) =>
        i18n.language === 'lv' ? option.nosaukums : option.nosaukums_eng
      }
      loading={isLoading}
      renderInput={(params) => (
        <TextField
          placeholder={t('specify-industry')}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                <Divider sx={{ my: 0.5, mr: 1.5 }} orientation="vertical" flexItem />
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          inputProps={{
            ...params.inputProps,
            style: {
              padding: '0px !important',
            },
          }}
        />
      )}
      noOptionsText="Nav atbilstošu ierakstu"
      {...props}
    />
  )
}
