import { Dialog, DialogContent, Typography, Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import CloseIcon from '@mui/icons-material/Close'

import { getComplaints } from '../../api/partners/getComplaints'
import { ComplaintsReplies } from '../../interfaces'

interface Props {
  onClose: () => void
  partnerId: number
  partnerName: string
}

const sx = {
  closeIcon: {
    cursor: 'pointer',
  },
  closeIconBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  complaintTitle: {
    marginRight: '6px',
    '&::after': {
      content: '":"',
    },
  },
  replyTitle: {
    marginRight: '6px',
    '&::after': {
      content: '":"',
    },
  },
  messageBubble: {
    maxWidth: '50%',
    overflowWrap: 'break-word',
    padding: '8px',
    borderRadius: '5px',
  },
  complaintBubble: {
    backgroundColor: '#bcf5bc',
  },
  replyBubble: {
    backgroundColor: '#ededed',
  },
}

const ComplaintResponseModal: React.FC<Props> = ({ onClose, partnerId, partnerName }) => {
  const { t } = useTranslation()

  const { data: complaintsData } = useQuery<ComplaintsReplies[]>('complaints-and-responses', () =>
    getComplaints(partnerId)
  )

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogContent>
        <Box sx={sx.closeIconBox}>
          <CloseIcon sx={sx.closeIcon} onClick={onClose} />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center">
          <Typography display="inline" textAlign="center" variant="h2">
            {partnerName}
          </Typography>
        </Box>
        {complaintsData && complaintsData.length > 0 ? (
          complaintsData.map(({ atbilde, apraksts, id }) => (
            <Box key={id}>
              <Box display="flex" flexDirection="row-reverse" marginTop="22px">
                <Box sx={[sx.messageBubble, sx.complaintBubble]}>{apraksts}</Box>
                <Box sx={sx.complaintTitle} display="flex" alignItems="flex-start">
                  {t('complaints.complaint')}
                </Box>
              </Box>

              <Box display="flex" justifyContent="flex-start" marginTop="18px">
                <Box sx={sx.replyTitle} display="flex" alignItems="flex-start">
                  {t('complaints.reply')}
                </Box>
                {atbilde ? (
                  <Box sx={[sx.messageBubble, sx.replyBubble]}>{atbilde}</Box>
                ) : (
                  <Box sx={[sx.messageBubble, { fontStyle: 'italic', padding: 0 }]}>
                    {t('complaints.no-reply')}
                  </Box>
                )}
              </Box>
            </Box>
          ))
        ) : (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            marginTop="22px"
            minHeight="140px"
          >
            <Typography>{t('complaints.no-complaints')}</Typography>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  )
}

export default ComplaintResponseModal
