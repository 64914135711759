import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

export const login = async (personCode: string) => {
  const response = await axios.post(
    `${BASE_PATH_PORTAL}/api/v1/test_login`,
    {
      pk: personCode,
    },
    {
      withCredentials: true,
    }
  )

  return response.data
}
