import { Box } from '@mui/material'
import React from 'react'

export const CardDisabled = () => {
  return (
    <Box className="cardDisabled" sx={{ width: '100%', height: '100%' }}>
      <Box className="cardDiagonalText">Apliecība nav aktīva</Box>
    </Box>
  )
}
