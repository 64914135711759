import { Box, Divider, useMediaQuery, useTheme } from '@mui/material'
import { breakPoints, VPS_AUTH_CALLBACK_PATH } from '../../constants'
import { Wrap } from '../Wrap'
import { FooterCopyright } from './FooterCopyright'
import { FooterNavigation } from './FooterNavigation'
import { useLocation } from 'react-router-dom'

export const Footer = () => {
  const theme = useTheme()
  const location = useLocation()
  const isLaptop = useMediaQuery(theme.breakpoints.up(breakPoints.laptop))

  return (
    <footer className={"ornament-pattern-background-red " + (location.pathname === VPS_AUTH_CALLBACK_PATH ? 'hide-children' : '')} style={{ marginTop: 'auto' }}>
      <Wrap>
        {isLaptop && (
          <>
            <FooterNavigation />
            <Box display="flex" justifyContent="center">
              <Divider
                variant="middle"
                style={{ background: 'white', opacity: '0.4', width: '100%' }}
              />
            </Box>
            <FooterCopyright />
          </>
        )}
      </Wrap>
    </footer>
  )
}
