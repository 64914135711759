import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'
import { CurrentUser } from '../../interfaces'

export const getUser = async ({ id }: { id?: number }) => {
  const response = await axios.get(`${BASE_PATH_PORTAL}/data/persona/${id}`)

  return response.data
}

export const getCurrentSession = async (): Promise<CurrentUser> => {
  const response = await axios.get(`${BASE_PATH_PORTAL}/api/v1/current_user`)

  return response.data
}