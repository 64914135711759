import React from 'react'
import { Box, List } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import godaGimeneLogo from '../../icons/png/GodaGimeneNoBorderLogo.png'
import QRCode from 'react-qr-code'
import { Card as CardType } from '../../interfaces/certificate/Card'
import { CardChild as ChildCardType } from '../../interfaces/certificate/CardChild'
import styles from './Card.module.css'

interface Props {
  card: CardType | null
  children: ChildCardType[] | null
  themeColor: string
  openCardFullscreen?: boolean
  showCloseIcon?: boolean
  handleClose?: () => void
}

export const CardBack = ({
  card,
  children,
  themeColor,
  openCardFullscreen,
  showCloseIcon,
  handleClose,
}: Props) => {
  const [qrCodeContainerheight, setQrCodeContainerHeight] = React.useState(0)

  const ref = React.useRef<any>(null)

  React.useEffect(() => {
    if (ref.current) {
      setQrCodeContainerHeight(ref.current.clientHeight)
    }
  }, [])

  return (
    <Box className={styles.cardContainer}>
      <Box
        className={`ornament-pattern-light-${themeColor}`}
        style={{
          padding: !showCloseIcon ? '5px 0px' : '10px 10px 10px 0px',
          color: 'white',
          height: '100%',
          width: '100%',
          borderRadius: '6px',
          display: 'flex',
        }}
      >
        {showCloseIcon && (
          <CloseIcon sx={{ width: 'auto', height: '50px' }} onClick={handleClose} />
        )}

        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              fontSize: openCardFullscreen ? '12px' : '8px',
              padding: '8px',
            }}
          >
            Apliecība derīga kopā ar personu apliecinošu dokumentu
          </Box>

          <Box sx={{ backgroundColor: 'black', height: openCardFullscreen ? '25%' : '20%' }} />

          <Box sx={{ backgroundColor: 'white', height: openCardFullscreen ? '60%' : '50%' }}>
            <Box sx={{ display: 'flex', height: '100%', color: 'black' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexGrow: 1,
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <img src={godaGimeneLogo} height="40%" alt="Sabiedrības integrācijas fonds" />
                <img src={godaGimeneLogo} height="40%" alt="Latvijas Goda ģimene" />
              </Box>

              <Box
                ref={ref}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexGrow: 1,
                  margin: '10px 0px',
                }}
              >
                <QRCode value={card ? card.hash : ''} size={qrCodeContainerheight} />
              </Box>

              {children && (
                <Box sx={{ flexGrow: 1 }}>
                  <Box
                    sx={{
                      fontSize: openCardFullscreen ? '12px' : '10px',
                      color: 'gray',
                      lineHeight: '2',
                    }}
                  >
                    Mana ģimene:
                  </Box>
                  <List dense disablePadding>
                    {children.map((child, index) => (
                      <Box key={index} sx={{ fontSize: openCardFullscreen ? '14px' : '12px' }}>
                        {child.vārds} {child.uzvārds}
                      </Box>
                    ))}
                  </List>
                </Box>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              fontSize: openCardFullscreen ? '12px' : '8px',
              padding: '8px',
            }}
          >
            Apliecības izdevējs: Sabiedrības integrācijas fonds. e-pasts: info@godagimene.lv,
            www.godagimene.lv
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
