import axios from 'axios'
import { BASE_PATH_DISTRO } from '../../constants'
import { CertificationVerificationForm } from '../../pages/CertificateVerification/types'

export const internalCertificateVertification = async (userData: CertificationVerificationForm) => {
  return (
    await axios
      .get(`${BASE_PATH_DISTRO}/data/iegultās_kontroles_serviss`, {
        params: {
          vārds: userData.name,
          uzvārds: userData.surname,
          numurs: userData.certificateNumber,
          personas_koda_otrā_daļa: userData.personalCodeSecondPart,
        },
      })
      .then((response) => response.data)
  )?.[0]
}
