import { Discounts } from './Discounts'
import { News } from './News'
import { Offers } from '../../components'
import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from '../../hooks'

export const Home = () => {
  const { t } = useTranslation()

  useDocumentTitle(t('document-title.default'))

  return (
    <>
      <News />
      <Discounts />
      <Offers sx={{ mt: 8 }} />
    </>
  )
}
