import { useRoutes } from 'react-router-dom'
import { ThemeProvider } from './providers'
import { routesConfig } from './routesConfig'
import { QueryClient, QueryClientProvider } from 'react-query'
import {
  FullPageLoader,
  ScrollToTopRestoration,
  EmailConfirmationAlert,
  PhoneActivationDialog,
} from './components'
import { Init } from './containers'
import React, { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { useDocumentTitle } from './hooks'
import SnackBarProvider from './providers/SnackBarProvider'
import { NotificationsPoll } from './components/NotificationsPoll/NotificationsPoll'
import { useInitInterceptors } from './api/useInterceptors'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: 0,
      retry: 0,
    },
  },
})

export const App = () => {
  const { t } = useTranslation()
  const routes = useRoutes(routesConfig)

  useInitInterceptors()
  useDocumentTitle(t('document-title.default'))

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider>
        <SnackBarProvider>
          <Suspense fallback={<FullPageLoader />}>
            <EmailConfirmationAlert />
            <PhoneActivationDialog />
            <Init>{routes}</Init>
          </Suspense>
          <NotificationsPoll />
        </SnackBarProvider>
      </ThemeProvider>
      <ScrollToTopRestoration />
    </QueryClientProvider>
  )
}
