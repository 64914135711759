import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

interface ComplaintRequest {
  sadarbības_partneris_id: number
  apraksts: string
}

export const setPartnerComplaint = async (data: ComplaintRequest) => {
  const response = await axios.post(`${BASE_PATH_PORTAL}/data/sadarbības_partnera_sūdzības`, data)
  return response.data
}
