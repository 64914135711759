export enum CardStatus {
  // Nodota drukāšanai
  PL_NDR = 'PL_NDR',
  // Nav pieprasīta
  PL_NEP = 'PL_NEP',
  // Atgriezta
  PL_ATG = 'PL_ATG',
  // Izdrukāta
  PL_IDR = 'PL_IDR',
  // Izsniegta
  PL_IZS = 'PL_IZS',
  // Nosūtīta
  PL_NS = 'PL_NS',
  // Pieprasīta
  PL_PIEP = 'PL_PIEP',
}
