import { Grid, Typography, Card, CardMedia, CardContent, CircularProgress } from '@mui/material'
import { Link } from 'react-router-dom'
import { BASE_PATH_DISTRO } from '../../../constants'
import { PromotionItem } from '../../../interfaces/promotions'
import { getLocalizedDate } from '../../../utils'
import { FullPageLoader } from '../../FullPageLoader'
import noLogo from './no-logo.jpeg'

interface GenericItemListProps {
  label: string
  isLoading: boolean
  slug: string
  data?: PromotionItem[]
  children?: JSX.Element | JSX.Element[]
}

export const PromotionItemList = ({
  label,
  isLoading,
  slug,
  data,
  children,
}: GenericItemListProps) => {
  return (
    <>
      <Typography variant="h1" sx={{ mb: 4 }}>
        {label}
      </Typography>
      {isLoading && <CircularProgress />}
      {data ? (
        <>
          <Grid container spacing={4} position="relative" height="100%">
            {data?.map((item, index) => (
              <Grid item key={index} lg={index < 3 ? 4 : 3} md={4} sm={6} xs={12}>
                <Link to={slug + item.id} state={data[0]}>
                  <Card sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <CardMedia
                      component="img"
                      sx={{ boxShadow: 'inset 10px 10px 50px #eee' }}
                      image={
                        item.pielikums?.id !== (null || undefined)
                          ? `${BASE_PATH_DISTRO}/api/v1/download/${item.pielikums.id}/${item.pielikums.sha256}`
                          : noLogo
                      }
                      height={index < 3 ? '300px' : '200px'}
                    />
                    <CardContent sx={{ backgroundColor: 'transparent' }}>
                      <Typography gutterBottom variant="subtitle2">
                        {getLocalizedDate(item.aktīva_no)}
                      </Typography>
                      <Typography
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          display: '-webkit-box',
                          '-webkit-line-clamp': '3',
                          lineClamp: '3',
                          '-webkit-box-orient': 'vertical',
                        }}
                        variant="h2"
                      >
                        {item.nosaukums}
                      </Typography>
                    </CardContent>
                  </Card>
                </Link>
              </Grid>
            ))}
          </Grid>
          {children}
        </>
      ) : (
        <FullPageLoader />
      )}
    </>
  )
}
