import { Box, Button, Divider, FormHelperText, useTheme } from '@mui/material'
import React, { useMemo } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { format } from 'date-fns'
import { Children } from '../../interfaces/user/Children'
import { FileListItem } from '../../File/FileListItem'
import { InquiryRegistryCheck } from '../../interfaces/inquiry/InquiryRegistryCheck'
import {
  DEFAULT_API_DATE_FORMAT,
  DEFAULT_DOCUMENT_STATUS,
  FILE_SIZE_LIMIT_MB_MESSAGE,
  REQUIRED_FILE_ERROR_MESSAGE,
  VDEĀVK_DOCUMENT_TYPE,
  VDEĀVK_TYPE,
  VIIS_DOCUMENT_TYPE,
  VIIS_TYPE,
} from '../../constants'
import { FileUploadButton } from '../../components/File/FileUploadButton'
import { FullPageLoader } from '../../components/FullPageLoader'
import { uploadFiles } from '../../utils/uploadFiles'
import { changeRegisterCheckDocument } from '../../api/file/changeRegisterCheckDocument'
import { RegisterCheckDocument } from '../../interfaces/file/RegisterCheckDocument'
import { useMutation } from 'react-query'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { SubMenu } from '../../components'
import { InquiryEntry } from '../../interfaces/inquiry/InquiryEntry'
import { useTranslation } from 'react-i18next'
import { FileEntry } from '../../interfaces/file/FileEntry'

interface Props {
  iesniegums: InquiryEntry
  person: Children
}

export const InquiryChildInfo = () => {
  const navigate = useNavigate()
  const { showSnackBar } = useSnackBar()
  const theme = useTheme()
  const { t } = useTranslation()

  const { state } = useLocation()
  const { person, iesniegums } = state as Props
  const { vārds, uzvārds, pārbaudes } = person

  const [filesVdeavk, setFilesVdeavk] = React.useState<FileListItem[]>([])
  const [filesViis, setFilesViis] = React.useState<FileListItem[]>([])
  const [uploadedFilesVdeavk, setUploadedFilesVdeavk] = React.useState<FileEntry[]>([])
  const [uploadedFilesViis, setUploadedFilesViis] = React.useState<FileEntry[]>([])
  const [isLoading, setIsLoading] = React.useState(false)
  const [inquiryErrors, setInquiryErrors] = React.useState<{
    [key: string]: string
  }>({})

  const code: { [key: string]: string } = {
    VDEĀVK: t('inquiry-contact-info.vdevak'),
    VIIS: t('inquiry-contact-info.viis'),
  }

  const vdeavkRegistryCheck = pārbaudes.filter(
    (reg: InquiryRegistryCheck) => reg.tips_kods === VDEĀVK_TYPE && !reg.atbilst
  )
  const viisRegistryCheck = pārbaudes.filter(
    (reg: InquiryRegistryCheck) => reg.tips_kods === VIIS_TYPE && !reg.atbilst
  )

  const vdeavkEntry = vdeavkRegistryCheck[0]
  const viisEntry = viisRegistryCheck[0]

  const agreedToHealthDataProcessing = useMemo(() => {
    return JSON.parse(localStorage.getItem('healthDataProcessingTerms') || 'false')
  }, [])

  const validateForm = () => {
    const errors: { [key: string]: string } = {}

    const bothFilesEmpty = filesViis.length === 0 && filesVdeavk.length === 0

    if (vdeavkEntry && agreedToHealthDataProcessing && bothFilesEmpty) {
      errors['filesEmpty'] = REQUIRED_FILE_ERROR_MESSAGE
    }

    if (viisEntry && bothFilesEmpty) {
      errors['filesEmpty'] = REQUIRED_FILE_ERROR_MESSAGE
    }

    setInquiryErrors({
      ...inquiryErrors,
      ...errors,
    })

    return Object.keys(errors).length === 0
  }

  const uploadDocument = () => {
    const { id: personas_pārbaude_id_vdeavk } = vdeavkEntry || {}
    const { id: personas_pārbaude_id_viis } = viisEntry || {}

    if (vdeavkRegistryCheck.length || viisRegistryCheck.length) {
      const isValid = validateForm()

      if (!isValid) return

      setIsLoading(true)

      Promise.all([
        uploadedFilesVdeavk.length > 0
          ? changeDocument.mutate({
              faili: uploadedFilesVdeavk,
              personas_pārbaude_id: personas_pārbaude_id_vdeavk,
              iesniegums_id: iesniegums.id,
              tips_kods: VDEĀVK_DOCUMENT_TYPE,
              iesniegšanas_datums: format(new Date() as unknown as Date, DEFAULT_API_DATE_FORMAT),
              derīguma_termiņš: null,
              statuss_kods: DEFAULT_DOCUMENT_STATUS,
              komentārs: '',
            })
          : Promise.resolve(),
        uploadedFilesViis.length > 0
          ? changeDocument.mutate({
              faili: uploadedFilesViis,
              personas_pārbaude_id: personas_pārbaude_id_viis,
              iesniegums_id: iesniegums.id,
              tips_kods: VIIS_DOCUMENT_TYPE,
              iesniegšanas_datums: format(new Date() as unknown as Date, DEFAULT_API_DATE_FORMAT),
              derīguma_termiņš: null,
              statuss_kods: DEFAULT_DOCUMENT_STATUS,
              komentārs: '',
            })
          : Promise.resolve(),
      ])
        .then(() => {
          setIsLoading(false)
          showSnackBar()
          navigate(-1)
        })
        .catch((error) => {
          setIsLoading(false)
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        })
    }
  }

  const changeDocument = useMutation(
    (document: RegisterCheckDocument) => changeRegisterCheckDocument(document),
    {
      onError: (error: any) => {
        setIsLoading(false)
        showSnackBar({
          severity: 'error',
          text: handleErrorMessages(error),
        })
      },
    }
  )

  const onVdeavkChange = (files: FileListItem[]) => {
    setFilesVdeavk(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFilesVdeavk(() => [
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of files) {
          if (fileEntry.state === 'keep') {
            setUploadedFilesVdeavk((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).faila_id,
              },
            ])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setFilesVdeavk([])
        setUploadedFilesVdeavk([])
      })
      .finally(() => {
        setIsLoading(false)
        setInquiryErrors({
          ...inquiryErrors,
          filesEmpty: '',
        })
      })
  }

  const onViisChange = (files: FileListItem[]) => {
    setFilesViis(files)
    setIsLoading(true)

    uploadFiles(files)
      .then(async (uploadResponse: Response[]) => {
        for (const resp of uploadResponse) {
          const json = await resp.json()

          setUploadedFilesViis(() => [
            {
              faila_id: json.id,
            },
          ])
        }

        for (const fileEntry of files) {
          if (fileEntry.state === 'keep') {
            setUploadedFilesViis((prevFormState) => [
              ...prevFormState,
              {
                faila_id: (fileEntry.file as FileEntry).faila_id,
              },
            ])
          }
        }
      })
      .catch((error) => {
        if (error.response.status === 413 || error.response.status === 0) {
          showSnackBar({
            severity: 'error',
            text: FILE_SIZE_LIMIT_MB_MESSAGE,
          })
        } else {
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        }
        setFilesViis([])
        setUploadedFilesViis([])
      })
      .finally(() => {
        setIsLoading(false)
        setInquiryErrors({
          ...inquiryErrors,
          filesEmpty: '',
        })
      })
  }

  React.useEffect(() => {
    if (pārbaudes) {
      const viisType = pārbaudes.find((check) => check.tips_kods === VIIS_TYPE)
      const vdeavkType = pārbaudes.find((check) => check.tips_kods === VDEĀVK_TYPE)

      const docsViis =
        viisType?.dokumenti?.flatMap((doc) =>
          doc.faili.map(
            (file) =>
              ({
                file,
                state: 'keep',
                status: doc.statuss_kods,
                comment: doc.komentārs,
                upload_time: doc.pievienošanas_laiks,
              } as FileListItem)
          )
        ) || []

      const docsVdeavk =
        vdeavkType?.dokumenti?.flatMap((doc) =>
          doc.faili.map(
            (file) =>
              ({
                file,
                state: 'keep',
                status: doc.statuss_kods,
                comment: doc.komentārs,
                upload_time: doc.pievienošanas_laiks,
              } as FileListItem)
          )
        ) || []

      setFilesViis(docsViis)
      setFilesVdeavk(docsVdeavk)
    }
  }, [pārbaudes])

  return isLoading ? (
    <FullPageLoader />
  ) : (
    <Box className="body-container">
      <SubMenu />
      <h2>
        {t('child')}: {vārds} {uzvārds}
      </h2>
      {vdeavkEntry && agreedToHealthDataProcessing && (
        <>
          <h4>{code.VDEĀVK}</h4>
          <Divider />
          <h4>{t('files.added-files')}: </h4>
          <FileUploadButton useLink onFileChange={onVdeavkChange} files={filesVdeavk} />
        </>
      )}
      {viisEntry && (
        <>
          <h4>{code.VIIS}</h4>
          <Divider />
          <h4>{t('files.added-files')}: </h4>
          <FileUploadButton useLink onFileChange={onViisChange} files={filesViis} />
        </>
      )}
      {inquiryErrors.filesEmpty && (
        <Box sx={{ margin: '20px 0px' }}>
          <FormHelperText sx={{ color: theme.palette.error.main }}>
            {inquiryErrors.filesEmpty}
          </FormHelperText>
        </Box>
      )}
      <Box sx={{ margin: '20px 0px' }}>
        <Button
          variant="text"
          sx={{
            textTransform: 'unset',
            padding: 0,
          }}
          onClick={() => navigate(-1)}
        >
          <u>{t('back')}</u>
        </Button>
        <Button variant="outlined" sx={{ marginLeft: '12px' }} onClick={uploadDocument}>
          {t('save-continue')}
        </Button>
      </Box>
    </Box>
  )
}
