import React from 'react'
import { Snackbar, Alert, SnackbarProps, AlertColor } from '@mui/material'

interface Props {
  severity?: AlertColor
  text?: string
}

interface CustomSnackbarProps extends SnackbarProps, Props {}

const SnackBarContext = React.createContext({
  showSnackBar: (props?: Props) => {},
})

export const useSnackBar = () => {
  const context = React.useContext(SnackBarContext)
  if (!context) {
    throw new Error('useSnackBar must be used within a SnackBarProvider')
  }
  return context
}

const SnackBarProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [open, setOpen] = React.useState(false)
  const [severity, setSeverity] = React.useState<AlertColor>('success')
  const [text, setText] = React.useState('')
  const openSnackBar = ({ severity = 'success', text = '' }: Props = {}) => {
    setSeverity(severity)
    setText(text)
    setOpen(true)
  }
  return (
    <SnackBarContext.Provider
      value={{
        showSnackBar: openSnackBar,
      }}
    >
      <SnackBar text={text} severity={severity} open={open} onClose={() => setOpen(false)} />
      {children}
    </SnackBarContext.Provider>
  )
}

export default SnackBarProvider

const SnackBar = ({ severity = 'success', text, ...props }: CustomSnackbarProps) => {
  const _text = text || (severity === 'success' ? 'Saglabāts!' : 'kļūda!')
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={5000}
      {...props}
    >
      <Alert severity={severity} elevation={6} variant="filled">
        <div
          style={{
            fontSize: 14,
          }}
        >
          {_text}
        </div>
      </Alert>
    </Snackbar>
  )
}
