import create from 'zustand'

interface PhoneVerificationDialogState {
  isOpen: boolean
  phoneId: number
  setPhoneId: (phoneId: number) => void
  userRefetchCallback: () => void
  setIsOpen: (isOpen: boolean) => void
  setUserRefetchCallback: (callback: () => void) => void
}
export const usePhoneVerificationDialogState = create<PhoneVerificationDialogState>((set) => ({
  isOpen: false,
  phoneId: 0,
  setPhoneId: (newId: number) => {
    set(() => ({ phoneId: newId }))
  },
  userRefetchCallback: () => null,
  setIsOpen: (nextIsOpen: boolean) => {
    set(() => ({ isOpen: nextIsOpen }))
  },
  setUserRefetchCallback: (callback: () => void) => {
    set(() => ({ userRefetchCallback: callback }))
  },
}))
