import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@mui/material'
import React, { FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'
import { getCertificateList } from '../../api/certificate/getCertificateList'
import { updateChildrenOnCard } from '../../api/certificate/updateChildrenOnCard'
import { SubMenu } from '../../components'
import { ErrorDialog } from '../../components/ErrorDialog/ErrorDialog'
import {
  INVALID_MAX_DISPLAYABLE_CHILDREN_COUNT,
  INVALID_MIN_DISPLAYABLE_CHILDREN_COUNT,
  MAX_DISPLAYABLE_CHILDREN_COUNT,
  MIN_DISPLAYABLE_CHILDREN_COUNT,
  REQUIRED_CHILDREN_ERROR_MESSAGE,
} from '../../constants'
import { Children } from '../../interfaces'
import { Card } from '../../interfaces/certificate/Card'
import { useSnackBar } from '../../providers/SnackBarProvider'
import { handleErrorMessages } from '../../utils/handleErrorMessages'

interface RouterState {
  eligibleChildren: Children[]
  allChildren: Children[]
}

export const InquiryChildrenOnCard = () => {
  const { showSnackBar } = useSnackBar()
  const location = useLocation()
  const navigate = useNavigate()
  const theme = useTheme()
  const { id } = useParams()
  const { t } = useTranslation()

  const state = location.state as RouterState
  const hasState = state && state.eligibleChildren

  let request: { persona_id: number }[] = []

  const [checkedAll, setCheckedAll] = useState(false)
  const [checkedState, setCheckedState] = useState(
    new Array(hasState ? state.eligibleChildren.length : 0).fill(false)
  )
  const [formErrors, setFormErrors] = React.useState<{ [key: string]: string }>({
    selectedChildren: '',
    children: '',
  })
  const [loading, setLoading] = useState(false)
  const [isOpenErrorDialog, setIsOpenErrorDialog] = React.useState(false)

  if (hasState) {
    const onSubmit = async (event: FormEvent) => {
      event.preventDefault()

      if (checkedAll) {
        request = state.eligibleChildren.map((child) => ({ persona_id: child.persona_id }))
      } else {
        request = state.eligibleChildren
          .filter((child, index) => checkedState[index])
          .map((child) => ({ persona_id: child.persona_id }))
      }

      if (!isFormValid()) return

      setLoading(true)

      await getCertificateList()
        .then((parentCard: Card[]) => {
          updateChildrenOnCard({
            id: parentCard[0].id,
            bērni: request,
          })
            .then(() => {
              showSnackBar({
                severity: 'success',
                text: 'Izmaiņas saglabātas!',
              })
              navigate(`/inquiry-children-cards/${id}`, {
                state: {
                  eligibleChildren: state.eligibleChildren,
                  allChildren: state.allChildren,
                },
              })
            })
            .catch((error) =>
              showSnackBar({
                severity: 'error',
                text: handleErrorMessages(error),
              })
            )
            .finally(() => setLoading(false))
        })
        .catch((error) =>
          showSnackBar({
            severity: 'error',
            text: handleErrorMessages(error),
          })
        )
        .finally(() => setLoading(false))
    }

    const isFormValid = () => {
      const _errors: { [key: string]: string } = {}

      if (request.length && request.length < MIN_DISPLAYABLE_CHILDREN_COUNT) {
        _errors['children'] = INVALID_MIN_DISPLAYABLE_CHILDREN_COUNT
      }

      if (request.length > MAX_DISPLAYABLE_CHILDREN_COUNT) {
        _errors['children'] = INVALID_MAX_DISPLAYABLE_CHILDREN_COUNT
      }

      if (!checkedAll && !checkedState.includes(true))
        _errors['selectedChildren'] = REQUIRED_CHILDREN_ERROR_MESSAGE

      setFormErrors(_errors)

      return Object.keys(_errors).length === 0
    }

    const onSelectAll = () => {
      setCheckedAll((checked) => !checked)
      setCheckedState(new Array(state.eligibleChildren.length).fill(false))
      setFormErrors({})
    }

    const onChange = (position: number) => {
      setCheckedState(checkedState.map((item, index) => (index === position ? !item : item)))
      setFormErrors({})
    }

    return (
      <Box onSubmit={onSubmit} component="form" className="body-container">
        <SubMenu />
        {formErrors && formErrors.children && (
          <ErrorDialog
            handleClose={() => setIsOpenErrorDialog(false)}
            open={isOpenErrorDialog}
            text={formErrors.children}
          />
        )}
        <Typography variant="h1" sx={{ mt: 2, fontSize: '1.4rem', textAlign: 'center' }}>
          {t('inquiry-contact-info.displayed-child-data')}
        </Typography>
        <List sx={{ mt: 2 }} disablePadding>
          <ListItem>
            <FormControlLabel
              control={<Checkbox checked={checkedAll} disabled={loading} onChange={onSelectAll} />}
              label="Visus"
            />
          </ListItem>
          {state.eligibleChildren.map((child, index) => (
            <ListItem key={index}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkedState[index] || checkedAll}
                    disabled={checkedAll || loading}
                    onChange={() => onChange(index)}
                  />
                }
                label={`${child.vārds} ${child.uzvārds}`}
              />
            </ListItem>
          ))}
        </List>
        {formErrors && formErrors.children && (
          <FormHelperText sx={{ mt: 1, ml: 2, color: theme.palette.error.main }}>
            {formErrors.children}
          </FormHelperText>
        )}
        {formErrors.selectedChildren && (
          <FormHelperText sx={{ mt: 1, ml: 2, color: theme.palette.error.main }}>
            {formErrors.selectedChildren}
          </FormHelperText>
        )}
        <Button variant="outlined" disabled={loading} sx={{ mt: 4, mb: 2 }} type="submit">
          {t('save-continue')}
        </Button>
      </Box>
    )
  }

  return <Navigate to="/" />
}
