import React, { useRef } from 'react'
import { getInquiry } from '../api/inquiry/getInquiry'
import { InquiryEntry } from '../interfaces/inquiry/InquiryEntry'

export default function useDocumentProcessing(id: string) {
  const [data, setData] = React.useState<InquiryEntry | null>(null)
  const [isLoading, setIsLoading] = React.useState(true)
  const intervalID = useRef<NodeJS.Timeout | undefined>(undefined)

  const intervalTime = 3000

  React.useEffect(() => {
    if (id) {
      intervalID.current = setInterval(() => {
        if (!data) {
          getInquiry({ id: id }).then((response) => {
            if (!response.ir_apstrādē) {
              setData(response)
              setIsLoading(false)
            }
          })
        }
      }, intervalTime)
    } else {
      setIsLoading(false)
    }

    return () => clearInterval(intervalID.current)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, id])

  return { data, isLoading }
}
