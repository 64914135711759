interface routeProps {
  title: string
  link: string
}

export const columnizeArray = (items: routeProps[], rows: number) => {
  const toColumns = (table: routeProps[][], item: routeProps, iteration: number) => {
    let row = iteration % rows

    table[row] = table[row] || []
    table[row].push(item)

    return table
  }

  return items.reduce(toColumns, [])
}
