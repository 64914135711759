import { Box, Link } from '@mui/material'
import { LanguageSwitch } from '../LanguageSwitch'
import { useAppContextStore } from '../../store/appContext'
import { SocialMedia } from '../SocialMedia'

export const SocialBar = () => {
  const explanatoryTexts = useAppContextStore((state) => state.explanatoryTexts)

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
      <Box sx={{ ml: 1, mr: 1 }}>
        {explanatoryTexts['e-mail'] && (
          <Link sx={{ color: '#FFF' }} href={'mailto:' + explanatoryTexts['e-mail']}>
            {explanatoryTexts['e-mail']}
          </Link>
        )}
        {explanatoryTexts['e-mail'] && explanatoryTexts['phone'] && ' / '}
        {explanatoryTexts['phone'] && (
          <Link sx={{ color: '#FFF' }} href={'tel:' + explanatoryTexts['phone']}>
            {explanatoryTexts['phone']}
          </Link>
        )}
      </Box>
      <SocialMedia />
      <Box ml={1} mr={1}>
        <LanguageSwitch />
      </Box>
    </Box>
  )
}
