import { Button, Box } from '@mui/material'
import logo from '../../icons/png/latvija-lv-logo.png'
import { BASE_PATH_PORTAL } from '../../constants'

const sx = {
  label: {
    marginTop: 4,
    textAlign: 'center',
    color: '#616161',
  },
  btn: {
    marginTop: 1,
    width: '100%',
    minHeight: '45px',
    maxWidth: 340,
  },
  img: {
    marginTop: '3px',
    width: '78px',
  },
}

export default function VpsButton() {
  const vpsLogin = () => {
    window.location.replace(`${window.location.origin}${BASE_PATH_PORTAL}/api/v1/login_start`)
  }

  return (
    <>
      <Box sx={sx.label}>Pieslēgties ar</Box>
      <Button onClick={vpsLogin} variant="outlined" color="primary" sx={sx.btn}>
        <img src={logo} style={sx.img} alt="Pieslēgties ar Latvija.lv" />
      </Button>
    </>
  )
}
