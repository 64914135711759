import { Box, Typography } from '@mui/material'
import styles from './Footer.module.css'
import sifLogo from '../../icons/png/logoSifVertical.png'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useAppContextStore } from '../../store'

export const FooterCopyright = () => {
  const { t } = useTranslation()
  const explanatoryTexts = useAppContextStore((state) => state.explanatoryTexts)

  const currentYear = format(new Date(), 'yyyy')

  return (
    <Box className={styles.footerContainer}>
      <Box display="flex" alignItems="center">
        <img src={sifLogo} width="90px" height="auto" alt={t('a11y.alt.sif-logo')} />
        {explanatoryTexts['implementer'] && (
          <Box sx={{ ml: 2, maxWidth: '25rem' }}>
            <Typography>{explanatoryTexts['implementer']}</Typography>
          </Box>
        )}
      </Box>
      <Box textAlign="right">
        {explanatoryTexts['copyright'] && (
          <Typography>
            {explanatoryTexts['copyright']} {currentYear}
          </Typography>
        )}
        <Typography>
          {t('version')}: {process.env.REACT_APP_VERSION}
        </Typography>
      </Box>
    </Box>
  )
}
