import { Box, SxProps } from '@mui/material'
import { ReactNode } from 'react'
import styles from './Wrap.module.css'

interface WrapProps {
  children: ReactNode
  sx?: SxProps
}

export const Wrap = ({ children, sx }: WrapProps) => {
  return (
    <Box className={styles.container} sx={sx}>
      {children}
    </Box>
  )
}
