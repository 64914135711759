import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import { StorageKey } from './enums'
import { LANGUAGES } from './constants'

const lng = localStorage.getItem(StorageKey.Language) || 'lv'

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng,
    fallbackLng: LANGUAGES[0],
    supportedLngs: LANGUAGES,
    interpolation: {
      escapeValue: false,
    },
  })

export { i18n }
