import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import sifLogo from '../../icons/png/logoSifHorizontal.png'
import { useAppContextStore } from '../../store'

export const SifLogo = () => {
  const { t } = useTranslation()
  const explanatoryTexts = useAppContextStore((state) => state.explanatoryTexts)

  return (
    <Box sx={{ mt: '6px', display: 'flex', flexDirection: 'column' }}>
      <Typography sx={{ fontSize: '0.9rem' }}>{explanatoryTexts['program']}</Typography>
      <img src={sifLogo} width="180px" height="auto" alt={t('a11y.alt.sif-logo')} />
    </Box>
  )
}
