import ArticleIcon from '@mui/icons-material/Article'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import React from 'react'
import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { SubMenu } from '../../components'
import { useTranslation } from 'react-i18next'

export const TermsOfUse = () => {
  const theme = useTheme()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const text = {
    fontWeight: 600,
  }

  const menuItems = [
    {
      text: t('terms.general-terms-title'),
      icon: <ArticleIcon />,
      path: '/general-data-processing-terms',
    },
    {
      text: t('terms.child-terms-title'),
      icon: <ArticleIcon />,
      path: '/health-data-processing-terms',
    },
  ]

  function generateList() {
    return (
      <List>
        {menuItems.map((item, index) => (
          <ListItem disablePadding key={index}>
            <ListItemButton
              onClick={() => navigate(item.path, { state: { showBackButton: true } })}
            >
              <ListItemIcon sx={{ color: theme.palette.primary.main }}>{item.icon}</ListItemIcon>
              <ListItemText primary={item.text} primaryTypographyProps={{ style: text }} />
              <ListItemSecondaryAction>
                <IconButton edge="end" disabled>
                  <ArrowForwardIosIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    )
  }

  return (
    <Box>
      <SubMenu />
      <Typography variant="h1" sx={{ mb: 4 }}>
        {t('menu.terms-of-use')}
      </Typography>
      <Divider />
      {generateList()}
    </Box>
  )
}
