import React from 'react'
import { Box, Dialog, Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { Card } from './Card'
import { CardBack } from './CardBack'
import { Card as CardType } from '../../interfaces/certificate/Card'
import { CardChild as ChildCardType } from '../../interfaces/certificate/CardChild'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  themeColor: string
  open: boolean
  isFlipped: boolean
  card: CardType | null
  children: ChildCardType[] | null
  disabled: boolean
  handleClose: () => void
}

export const CardFullscreenDialog = ({
  themeColor,
  open,
  isFlipped,
  card,
  children,
  disabled,
  handleClose,
}: Props) => {
  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <Box
        sx={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            height: '50%',
            width: '70%',
          }}
        >
          {isFlipped ? (
            <CardBack
              card={card}
              themeColor={themeColor}
              children={children}
              openCardFullscreen={open}
              handleClose={handleClose}
              showCloseIcon={true}
            />
          ) : (
            <Card
              card={card}
              themeColor={themeColor}
              disabled={disabled}
              openCardFullscreen={open}
              handleClose={handleClose}
              showCloseIcon={true}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  )
}
