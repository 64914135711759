import axios from 'axios'
import { BASE_PATH_DISTRO } from '../../constants'

export const getPromotionsByPartnerIds = async (partnerIds: Array<number>) => {
  const promotions = Promise.all(
    partnerIds.map((partnerId) =>
      axios
        .get(`${BASE_PATH_DISTRO}/data/sadarbības_partneru_atlaižu_saraksts`, {
          params: {
            sadarbības_partneris_id: partnerId,
          },
        })
        .then((response) => response.data)
    )
  )
  return (await promotions.then((data) => data)).flat()
}
