import axios from 'axios'
import { BASE_PATH_PORTAL } from '../../constants'

interface Data {
  id: number
}

export const applyForPlasticCards = async (data: Data) => {
  const response = await axios.put(`${BASE_PATH_PORTAL}/data/pieteikties_plastikāta_kartei/${data.id}`, {
    id: data.id,
  })
  return response.data
}
