import { Box } from '@mui/material'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { logout } from '../../api/login/logout'
import { FullPageLoader } from '../../components'
import { usePhoneVerificationDialogState, useUserDataStore } from '../../store'

export const Logout = () => {
  const navigate = useNavigate()
  const { setCurrentUser, clearUserDataStorage } = useUserDataStore()
  const { setIsOpen: setIsPhoneDialogOpen } = usePhoneVerificationDialogState()

  useQuery(
    'logout',
    async () => {
      return await logout()
    },
    {
      onSuccess: (response) => {
        if (response) {
          setCurrentUser(undefined)
          clearUserDataStorage()
        }
      },
      onSettled: () => {
        navigate('/', { replace: true })
        setIsPhoneDialogOpen(false)
      },
    }
  )

  return (
    <Box>
      <FullPageLoader />
    </Box>
  )
}
