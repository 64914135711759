import { Box } from '@mui/material'
import { DelayedLoader } from '../DelayedLoader'

export const FullPageLoader = () => {
  return (
    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <DelayedLoader />
    </Box>
  )
}
