import React from 'react'
import { Box } from '@mui/material'
import { format } from 'date-fns'
import { Card as CardType } from '../../interfaces/certificate/Card'
import CloseIcon from '@mui/icons-material/Close'
import { CardDisabled } from './CardDisabled'
import LogoMetalIcon from '../../icons/LogoMetalIcon'
import styles from './Card.module.css'

interface Props {
  card: CardType | null
  disabled: boolean
  themeColor: string
  openCardFullscreen?: boolean
  showCloseIcon?: boolean
  handleClose?: () => void
}

export const Card = ({
  disabled,
  card,
  themeColor,
  openCardFullscreen = false,
  showCloseIcon = false,
  handleClose,
}: Props) => {
  const { id, derīguma_termiņš, numurs, dzimšanas_datums, uzvārds, vārds } = card || {}

  return (
    <Box className={styles.cardContainer}>
      {disabled && <CardDisabled />}
      <Box
        className={`ornament-pattern-light-${themeColor}`}
        sx={{
          padding: !showCloseIcon ? '5px 10px' : '10px 10px 10px 0px',
          color: 'white',
          height: '100%',
          width: '100%',
          borderRadius: '6px',
          display: 'flex',
        }}
      >
        {showCloseIcon && (
          <CloseIcon sx={{ width: 'auto', height: '50px' }} onClick={handleClose} />
        )}

        <Box className={styles.cardRowContainer}>
          <Box className={styles.cardHeader}>
            <Box
              sx={{
                fontSize: openCardFullscreen ? '3rem' : '1rem',
                fontWeight: '700',
              }}
            >
              Goda ģimene
            </Box>
            <LogoMetalIcon
              style={{
                width: openCardFullscreen ? '8rem' : '5rem',
                height: 'auto',
              }}
            />
          </Box>
          <Box>
            <Box
              sx={{
                marginTop: openCardFullscreen ? '1.5rem' : '0.5rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box className={styles.textColumn}>
                <Box sx={{ fontSize: openCardFullscreen ? '1.3rem' : '0.5rem' }}>
                  Apliecības numurs:
                </Box>
                <Box
                  sx={{
                    fontSize: openCardFullscreen ? '1.675rem' : '0.875rem',
                    fontWeight: 700,
                  }}
                >
                  {numurs}
                </Box>
              </Box>

              {!id && (
                <Box className={styles.textColumn}>
                  <Box sx={{ fontSize: openCardFullscreen ? '1.3rem' : '0.5rem' }}>
                    Dzimšanas datums:
                  </Box>
                  <Box
                    sx={{
                      fontSize: openCardFullscreen ? '1.675rem' : '0.875rem',
                      fontWeight: 700,
                    }}
                  >
                    {dzimšanas_datums}
                  </Box>
                </Box>
              )}
              <Box className={styles.textColumn}>
                <Box sx={{ fontSize: openCardFullscreen ? '1.3rem' : '0.5rem' }}>
                  Derīguma termiņš:
                </Box>
                <Box
                  sx={{
                    fontSize: openCardFullscreen ? '1.675rem' : '0.875rem',
                    fontWeight: 700,
                  }}
                >
                  {derīguma_termiņš ? format(new Date(derīguma_termiņš), 'dd/MM/yyyy') : ''}
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                marginTop: openCardFullscreen ? '1.5rem' : '0.5rem',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box className={styles.textColumn}>
                <Box sx={{ fontSize: openCardFullscreen ? '1.3rem' : '0.5rem' }}>
                  Apliecības īpašnieks:
                </Box>
                <Box
                  sx={{
                    fontSize: openCardFullscreen ? '1.675rem' : '0.875rem',
                    fontWeight: 700,
                  }}
                >
                  {vārds} {uzvārds}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
