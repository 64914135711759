import React from 'react'
import { Box, Button, Link, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { getCurrentCardByInfo } from '../../api/certificate/getCurrentCardInfo'
import { FullPageLoader, SubMenu } from '../../components'
import { ErrorDialog } from '../../components/ErrorDialog/ErrorDialog'
import { CardStatus } from '../../enums/CardStatus'
import { Card } from '../../interfaces/certificate/Card'
import { handleErrorMessages } from '../../utils/handleErrorMessages'
import { useTranslation } from 'react-i18next'
import { t } from 'i18next'

export const InquiryResult = () => {
  const [isOpenErrorDialog, setIsOpenErrorDialog] = React.useState(false)
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    isLoading,
    data: currentCardInfo,
    error,
  } = useQuery<Card>('current-card-info', getCurrentCardByInfo, {
    onError: () => setIsOpenErrorDialog(true),
  })
  const hasNotRequestedPhysicalCard = currentCardInfo?.statuss_kods === CardStatus.PL_NEP

  if (isLoading) return <FullPageLoader />
  return (
    <Box className="body-container">
      <>
        <SubMenu />
        {error && (
          <ErrorDialog
            handleClose={() => setIsOpenErrorDialog(false)}
            open={isOpenErrorDialog}
            text={handleErrorMessages(error)}
          />
        )}

        <Typography variant="h2" fontWeight="500" mt={4}>
          {getGreetingText(hasNotRequestedPhysicalCard)}
        </Typography>
        <Typography mt={6} fontSize="1.3rem" fontWeight="500">
          {t('inquiry-contact-info.discount-catalouge')}{' '}
          <Link onClick={() => navigate('/discounts')} href="#">
            {t('inquiry-contact-info.here')}
          </Link>
        </Typography>
        <Button variant="outlined" onClick={() => navigate('/')} sx={{ mt: 4 }}>
          {t('back-to-home')}
        </Button>
      </>
    </Box>
  )
}

const getGreetingText = (hasNotRequestedPhysicalCard: boolean) =>
  hasNotRequestedPhysicalCard
    ? t('inquiry-contact-info.digital-application-approved')
    : t('inquiry-contact-info.plastic-application-approved')
