import { BASE_PATH_DISTRO } from '../../../constants'
import { FullPageLoader } from '../../FullPageLoader'
import parse from 'html-react-parser'
import { Typography, Box } from '@mui/material'
import { getLocalizedDate, htmlParseOptions } from '../../../utils'
import { useDocumentTitle } from '../../../hooks'
import { SocialShare } from '../../SocialShare'
import { PromotionItem } from '../../../interfaces/promotions'

interface GenericItemEntryProps {
  isLoading: boolean
  data?: PromotionItem
}

export const PromotionItemEntry = ({ isLoading, data }: GenericItemEntryProps) => {
  useDocumentTitle(data?.nosaukums)

  return (
    <>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <>
          {data && (
            <>
              <Typography variant="h1">{data.nosaukums}</Typography>
              <Typography gutterBottom variant="subtitle2">
                {getLocalizedDate(data.aktīva_līdz)}
              </Typography>
              <Box
                component="img"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '320px',
                  mt: 2,
                  boxShadow: 'inset 10px 10px 50px #eee',
                }}
                src={`${BASE_PATH_DISTRO}/api/v1/download/${data.pielikums.id}/${data.pielikums.sha256}`}
              />
              <Box sx={{ mt: 2 }}>{parse(data.apraksts, htmlParseOptions)}</Box>
              <SocialShare />
            </>
          )}
        </>
      )}
    </>
  )
}
